import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SchoolserviceService } from '../schoolservice.service';
import { DatePipe } from '@angular/common';
import { NavbarService } from '../default/navbar/navbar.service';

@Component({
  selector: 'app-boardlogin',
  templateUrl: './boardlogin.component.html',
  styleUrls: ['./boardlogin.component.scss'],
  providers: [DatePipe]
})
export class BoardloginComponent implements OnInit {

  showDiv = {
    previous : true,
    current : false,
     next : false,
     sscrslt: false,
     test:false
  }
  loginform: FormGroup;


  captcha: any;
  alphaNums = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
   emptyArr:any = [];
   refreshArr :any = [];
  loginuser: any;
  isloggin: any;
  stud_id: any;
  username: any;
  std_enroll_id: any;
  enrolldata: any;
  application_status: any;
  loginschool: any;
  schoolid: any;
  scode: any;
  enrollmnt:any;
  enrollment: any;
  standard: any;
  alldates: any;
  allmessages: any;
  messages: any[]=[];
  schoolname: any;
  role_type: any;
  alldates9: any;
  alldates11school: any;
  alldates9school: any;
  alldates11: any;
  submitted = false;
  constructor(public formbuilder:FormBuilder,public router:Router, public service:SchoolserviceService,public datepipe: DatePipe,public nav:NavbarService) { 
    this.loginform = formbuilder.group({
      username :['',Validators.required],
      password:['',Validators.required],
      captcha_text:['',Validators.required]
    });
  }

  ngOnInit(): void {

    this.nav.show();
    this.nav.dhide();
    this.nav.adhide();
    this.nav.asdhide();
    this.nav.aduserhide();
    this.nav.studhide();
    this.nav.XIstudhide();
    // This loop generates a random string of 7 characters using alphaNums
    // Further this string is displayed as a CAPTCHA
    for (let i = 1; i <= 6; i++) {
    this.emptyArr.push(this.alphaNums[Math.floor(Math.random() * this.alphaNums.length)]);
    }
     this.captcha = this.emptyArr.join('');

     console.log(this.captcha);

     this.service.post('/School/viewlastdateforAll',{}).subscribe((res:any)=>{
      this.alldates = res['Data'];
      //  this.alldates11 = this.alldates[0].lastdate;
      this.alldates9 = this.datepipe.transform(this.alldates[2].lastdate, 'dd/MM/yyyy');
      this.alldates11school = this.datepipe.transform(this.alldates[0].lastdate, 'dd/MM/yyyy');
      this.alldates9school = this.datepipe.transform(this.alldates[1].lastdate, 'dd/MM/yyyy');
      this.alldates11 = this.datepipe.transform(this.alldates[3].lastdate, 'dd/MM/yyyy');
      // console.log(this.dateall)
     });

     this.getmessages();

    
  }
  get l() { return this.loginform.controls; }
  // This event listener is stimulated whenever the user press the "Refresh" button
  refresh()
  {
    // form.captcha_text = "";
    // let refreshArr = [];
    for (let j = 1; j <= 7; j++) {
      
    this.refreshArr.push(this.alphaNums[Math.floor(Math.random() * this.alphaNums.length)]);
    // this.emptyArr.splice(0,this.emptyArr.length);
    
    }

    this.captcha = this.refreshArr.join('');
    this.refreshArr.splice(0,this.refreshArr.length)
    // console.log(this.captcha);


  }

    // for dynamic messages
    getmessages()
    {
  
      this.service.post('/School/viewnotifications',{}).subscribe((res:any)=>{
        this.allmessages = res.Data;
        this.messages =[];
        for (let i = 0; i < this.allmessages.length; i++) {
          
          this.messages.push(this.allmessages[i].message)
          
        }
        console.log(this.messages);
      })
    }

    onBoardLogin(form: { username: any; password: any; captcha_text:any; }){
      this.submitted = true;
      let login_para ={
        "username":form.username,
        "password":form.password
       }
  
       if (form.captcha_text == this.captcha)
       {
        // alert('sucess ')
        let seq = this.service.post('/BoardApi/boardLogin',login_para);
        seq.subscribe((res:any) =>{
          // console.log(res);
          // const abc = ['usename', 'password', 'date'];
         
          // this.stud_id = this.loginuser[0].studentid;

          if(res['status'] == 'Success')
          {
            this.loginuser = res['Data'];
              this.role_type = this.loginuser[0].role_type;
              // this.schoolid = res['Status'][0].schoolid;
              // const user = this.loginuser[0];
              this.username = this.loginuser[0].username;
              this.isloggin = this.loginuser[0].status;
              localStorage.setItem('username',this.username);
              localStorage.setItem('role_type',this.role_type);
            if(this.role_type == "boardadmin" || this.role_type == "boarduser")
            { 
              // alert('Logged In Successfully');
              this.router.onSameUrlNavigation = 'reload'; 
              this.router.navigate(['/boarddashboard']);
            }
           
          }
          else if(res['status'] == 'Failed')
          {
            alert('Entered Board User/Admin is not Valid');
          }
          else
          {
            alert('Wrong Password...! Please Contact Admin For Reset Password');
          }
            
         
  
     
       
       
       
          // localStorage.setItem('password',this.password);
          // localStorage.setItem('user_id',this.user_id);
          // this.router.navigate(['/landing'])
          // .then(() => {
          //   window.location.reload();
          // });
        },err=>{
          alert("Invalid Username/Password!");
             
        })
  
        } 
        else {
          
          alert('check captcha');
  
        }
  
      //  console.log(login_para);
  
      //  alert('You Have Change Your Password');
      //  this.router.navigate(['/changepassword']);
    }
  

}
