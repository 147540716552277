
<div class="container-fluied">
  
    <div class="clsUpdateStatus row mt3 ">  
        <div class="col-sm-10 optionWrap1">
          <div class="clsStatus1">
            <label class="form-check-label">
              <input type="radio" (change)="updateList($event)" class="form-check-input" name="allStatus" value="StudentStatus"> Student Status &nbsp;&nbsp;&nbsp;&nbsp;
            </label>     
          </div>
          <div class="clsStatus2">
            <label class="form-check-label">
              <input type="radio" (change)="updateList($event)" class="form-check-input" name="allStatus" value="RegistrationStatus"> Registration Status
            </label>
          </div>
          <!-- <div class="clsStatus3">
            <label class="form-check-label">
              <input type="radio" (change)="updateList($event)" class="form-check-input" name="allStatus" value="PaymentStatus"> Payment Status
            </label>
          </div> -->
          <div class="clsStatus4">
            <select class="custom-select mr-sm-2" id="statusChoice" (change)="enableBtnUpdate($event)">
              <option *ngFor="let item of dropdownList" [value]="item">{{item}}</option>
            </select>
          </div>
           <div class=" clsBtnUpdate" > 
             <button type="button" class="btn btn-primary btn-sm" (click)="onSubmit()" [disabled]="btnUpdate">Update</button>
          </div>
        </div>       
      <div class="col-sm-2 optionWrap2">
        <button type="button" class="btn btn-primary btn-sm" (click)="exportexcel()"><i class="bi bi-cloud-arrow-down-fill"></i>  Export to Excel</button>
      </div>
    </div>
  </div>
  <div class="container-fluied tableBody">
    <div class="row">
      <div class="col-sm-12 tablescroll" >
  
        <p-table #dt [value]="students"  [(selection)]="selectedCandidates" selectionMode="multiple" dataKey="studentid" id="excel-table" styleClass="p-datatable-customers" [rowHover]="true"
        [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50,100,1000,{ showAll: 'All' }]" [loading]="loading"
        [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [filterDelay]="0" [globalFilterFields]="['rollno','division','stname','gender','studentstatus','submission_status','pmt_status','pmt_ID']">
       
       <ng-template pTemplate="caption">
          <div class="table-header">
            Update Candidate Status 
              <span class="p-input-icon-left">
                  <i class="pi pi-search"></i>
                  <input pInputText type="text" (input)="dt.filterGlobal(getEventValue($event), 'contains')" placeholder="Search" />
              </span>
          </div>
      </ng-template>
      <ng-template pTemplate="header">
          <tr class="headerRow">
            
            <th style="width: 3rem"></th>
            <!-- <th id="noExl" style="width: 80px;">Action </th> -->
            <!-- <th pSortableColumn="studentid" style="width: 165px;">Student Id <p-sortIcon field="studentid"></p-sortIcon></th> -->
            <th pSortableColumn="rollno" style="width: 95px;">Roll no <p-sortIcon field="rollno"></p-sortIcon></th>
            <th class="smallCol" pSortableColumn="division" style="width: 165px;">Division <p-sortIcon field="division"></p-sortIcon></th>
            <th pSortableColumn="stname" style="width: 200px;">Student Name <p-sortIcon field="stname"></p-sortIcon></th>
            <th class="smallCol" pSortableColumn="gender" style="width: 165px;">Gender<p-sortIcon field="gender"></p-sortIcon></th>
            <th pSortableColumn="StudentStatus" style="width: 165px;">Student Regist <p-sortIcon field="studentstatus"></p-sortIcon></th>
            <th pSortableColumn="submission_status" style="width: 165px;">Regist Status <p-sortIcon field="submission_status"></p-sortIcon></th>
            <th pSortableColumn="pmt_status" style="width: 125px;">Pmt_status <p-sortIcon field="pmt_status"></p-sortIcon></th>
            <th class="smallCol2" pSortableColumn="pmt_ID" style="width: 165px;">Pmt ID <p-sortIcon field="pmt_ID"></p-sortIcon></th>
  
            <!-- <th style="width: 8rem"></th> -->
        </tr>
          <tr>
            <th>
                <p-tableHeaderCheckbox (click)="allselectRow()"></p-tableHeaderCheckbox>
            </th>
            <!-- <th></th> -->
            <th>
              <span class="p-input-icon-left"><i class="pi pi-search"></i>
              <input pInputText type="text" (input)="dt.filter(getidEventValue($event), 'rollno', 'startsWith')" placeholder="Roll No" class="p-column-filter">
              </span>   
            </th>
            
            <!-- <th>
                <input pInputText type="text" (input)="dt.filter(getmiddleNameEventValue($event), 'secondname', 'startsWith')" placeholder="Middle Name" class="p-column-filter">
            </th> -->
            <th>
              <span class="p-input-icon-left"><i class="pi pi-search"></i>
              <input pInputText type="text" (input)="dt.filter(getdivisionEventValue($event), 'division', 'startsWith')" placeholder="Division" class="p-column-filter">
              </span>
            </th>
            <th>
              <span class="p-input-icon-left"><i class="pi pi-search"></i>
              <input pInputText type="text" (input)="dt.filter(getnameEventValue($event), 'stname', 'startsWith')" placeholder="Student Name" class="p-column-filter">
              </span>
            </th>
             
            <th>
              <span class="p-input-icon-left"><i class="pi pi-search"></i>
              <input pInputText type="text" (input)="dt.filter(getgenderEventValue($event), 'gender', 'startsWith')" placeholder="Gender" class="p-column-filter">
              </span>
          </th>
            <th>
              <span class="p-input-icon-left"><i class="pi pi-search"></i>
              <input pInputText type="text" (input)="dt.filter(getlastNameEventValue($event), 'studentstatus', 'startsWith')" placeholder="student status" class="p-column-filter">
              </span>
            </th>
            <th>
              <span class="p-input-icon-left"><i class="pi pi-search"></i>
                <input pInputText type="text" (input)="dt.filter(getresigstatusEventValue($event), 'submission_status', 'startsWith')" placeholder="Registration Status" class="p-column-filter">
               </span> 
            </th>
            <th>
              <span class="p-input-icon-left"><i class="pi pi-search"></i>
              <input pInputText type="text" (input)="dt.filter(getpaymentstatusEventValue($event), 'pmt_status', 'startsWith')" placeholder="Payment Status" class="p-column-filter">
              </span>
          </th>
          <th>
            <span class="p-input-icon-left"><i class="pi pi-search"></i>
            <input pInputText type="text" (input)="dt.filter(getpaymentIDEventValue($event), 'pmt_ID', 'startsWith')" placeholder="Payment ID" class="p-column-filter">
            </span>
        </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-students >
        <tr class="p-selectable-row" >
            <td>
                <p-tableCheckbox [value]="students"   (click)="selectRow()"></p-tableCheckbox>
            </td>
            <!-- <td>
              <a aria-current="page" [routerLink]="['/edit-candidate',students.studentid]"><button class="btn btn-outline-primary btn-sm btnEdit"><i class="bi bi-pencil-square tblbtn"></i></button></a>
               
            </td> -->
            <!-- <td>
              <span class="p-column-title">Student_ID</span>
              {{students.studentid}}
          </td> -->
            <td>
                <!-- <span class="p-column-title">Roll No</span> -->
                {{students.rollno}}
            </td>
            <td>
              <!-- <span class="p-column-title">Division</span> -->
              {{students.division}}
              
          </td>
          <td>
                <!-- <span class="p-column-title">Student Name</span> -->
                {{students.stname}}
          </td>
          <td>
            <!-- <span class="p-column-title">Gender</span> -->
            {{students.gender}}
           
        </td>
            <!-- <td>
                <span class="p-column-title">Mother Name</span>
                {{students.mtname}}
    
            </td> -->
            <td>
              <!-- <span class="p-column-title">Father Name</span> -->
              {{students.studentstatus}}
    
          </td>
          
          <td>
            <!-- <span class="p-column-title">submission_status</span> -->
            {{students.submission_status}}
            
        </td>
          <td>
            <!-- <span class="p-column-title">Payment Status</span> -->
            {{students.pmt_status}}
        </td>
        <td>
          <!-- <span class="p-column-title">Payment ID</span> -->
          {{students.pmt_ID}}
        </td>
        
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="9">No Students found.</td>
        </tr>
    </ng-template>
     
  
        </p-table>
     </div>
   
      
    </div>
  </div>
  