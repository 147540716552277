import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { NavbarService } from '../default/navbar/navbar.service';
import { DataTableDirective } from 'angular-datatables';
import { Table } from 'primeng/table';
import { PrimeNGConfig } from 'primeng/api';

import { SchoolserviceService } from '../schoolservice.service';
import * as XLSX from 'xlsx';
import { Router } from '@angular/router';
import { Candidate } from '../candidates-details/candidate';

@Component({
  selector: 'app-school-allyrcandidate',
  templateUrl: './school-allyrcandidate.component.html',
  styleUrls: ['./school-allyrcandidate.component.scss']
})
export class SchoolAllyrcandidateComponent implements OnInit {
// for table data
students!: Candidate[];

selectedCandidates!: Candidate[];
schoolwisestudents: any;
loading: boolean = true;
// students:any;
@ViewChild('dt')
table!: Table;

fileName= 'ExcelSheet.xlsx';
 schoolid: any;
 studentid: any;
 yearvalue:any;
 getallyear:any
//select deselect on checkbox
//json data and functions for datatable

title = 'Year Wise Candidate Details';
  standard: any;

constructor(public service:SchoolserviceService,public nav:NavbarService,public router:Router, private primengConfig: PrimeNGConfig) { }


ngOnInit() {
 this.schoolid = localStorage.getItem('schoolid');
 this.nav.hide();
 this.nav.dhide();
 this.nav.adhide();
 this.nav.asdshow();
 this.nav.studhide();
 this.nav.XIstudhide();
 this.nav.aduserhide();
 // primng table

//   this.service.getCustomersLarge().then((candidates:any) => {
//     this.candidates = candidates;
//     this.loading = false;
// });
this.getallcandidates();

 this.primengConfig.ripple = true;
this.getallacdmicyear();
this.standard = localStorage.getItem('Standard');
}

selectyearwise(e:any)
{
  this.yearvalue = e.target.value;
  // //console.log(this.yearvalue);
}
getEventValue($event:any) :string {
 return $event.target.value;
}

getidEventValue($event:any) :string {
 return $event.target.value;
}
getstreamEventValue($event:any) :string {
  return $event.target.value;
}
getenrollmentyearEventValue($event:any) :string {
  return $event.target.value;
}
getcourseEventValue($event:any) :string {
  return $event.target.value;
}
getusernameEventValue($event:any) :string {
 return $event.target.value;
}

getnameEventValue($event:any) :string {
 return $event.target.value;
}
getmiddleNameEventValue($event:any) :string {
 return $event.target.value;
}

getlastNameEventValue($event:any) :string {
 return $event.target.value;
}

getdivisionEventValue($event:any) :string {
 return $event.target.value;
}
getgenderEventValue($event:any) :string {
 return $event.target.value;
}
getresigstatusEventValue($event:any) :string {
 return $event.target.value;
}

getpaymentstatusEventValue($event:any) :string {
 return $event.target.value;
}
getpaymentIDEventValue($event:any) :string {
 return $event.target.value;
}

getallcandidates()
{
 let schoolinfo :{schoolid:any}
 ={
   schoolid:this.schoolid
 }

 this.service.post('/School/viewAllYearstudents',schoolinfo).subscribe((candidates:any) => {
   this.students = candidates.Data;
   
   this.loading = false;

   //console.log(this.students);
});



}

getallacdmicyear()
{
  let schoolinfo :{schoolid:any}
 ={
   schoolid:this.schoolid
 }
  this.service.post('/School/getAllacademicYears',schoolinfo).subscribe((res:any)=>{
    this.getallyear = res.Data;
    //console.log(this.getallyear);
  })
}

onsearchyearwise()
{
  let schoolinfo :{schoolid:any,year:any}
 ={
   schoolid:this.schoolid,
   year:this.yearvalue
 }
//console.log(schoolinfo);
 this.service.post('/School/viewAllStudentsYearwise',schoolinfo).subscribe((candidates:any) => {
  this.students = candidates.Data;
  
  this.loading = false;

  //console.log(this.students);
 });
}




// Export to excel file

exportexcel():void 
{
  /* table id is passed over here */   
  let element = document.getElementById('excel-table'); 

 
  const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

  /* generate workbook and add the worksheet */
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  /* save to file */
  XLSX.writeFile(wb, this.fileName);

 //  let stud = {
 //    "status" :"Payment Settled"
   
 //  }
 //  //console.log(stud);
  
 //  this.service.updateamstatus(stud).subscribe((res:any)=>{
 //   //console.log(res);
 //   this.getstatusdata = res;
 //   this.loading = false;
 //   if(res){
 //     let Message = res['Message'];
 //     // alert(Message);
 //     // Swal.fire("claim Submitted Successfully")
 //     Swal.fire(Message);
 //    this.getreports();
 //    location.reload();

 //    // this.modalservice.close();
 //   //  this._Router.navigate(['/new-claim']);
 //   }
 //   else{
 //     let Message = res['Msg'];
 //     Swal.fire(Message)
 //    // this.modalservice.close();
 //   }
 // });
 
}

}