import { Component, OnInit, ViewChild, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { NavbarService } from '../default/navbar/navbar.service';
import { DataTableDirective } from 'angular-datatables';
import { Table } from 'primeng/table';
import { PrimeNGConfig } from 'primeng/api';
import { Candidate_payment } from './candidates-payment';
import { SchoolserviceService } from '../schoolservice.service';
import { Candidate } from '../candidates-details/candidate';
import { PaymentLandingComponent } from '../payment-landing/payment-landing.component';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-candidates-payment',
  templateUrl: './candidates-payment.component.html',
  styleUrls: ['./candidates-payment.component.scss']
})
export class CandidatesPaymentComponent implements OnInit {

  @ViewChild('dt')
  table!: Table;
  // for table data
  students!: Candidate[];

  selectedCandidates!: Candidate[];
// candidatesPay!: Candidate_payment[];
// selectedCandidates!: Candidate_payment[];
getreportdata: any[]=[]; 
loading: boolean = true;
totalAmountCalculated:Number=0;
checkarray:any;
schoolid: any;
selectedstudents: any[]=[];

showDiv = {
  previous : false,
  current : true,
  next : false
}

getdata!: any[];
rzp1:any;
order_id: any;
payment_id: any;
result: any;
signature: any;
amount: any;


//select deselect on checkbox

//json data and functions for datatable

title = 'Candidate Payment Details';
  studentlength: any;
  scode: any;
  schoolname: any;

  

constructor(public service:SchoolserviceService,public nav:NavbarService,private primengConfig: PrimeNGConfig) { }


ngOnInit() {
  this.schoolid = localStorage.getItem('schoolid');
  this.scode = localStorage.getItem('Standard');
  this.schoolname =localStorage.getItem('schoolname');
  //console.log(this.schoolname);

this.nav.hide();
this.nav.dhide();
this.nav.adhide();
this.nav.asdshow();
this.nav.studhide();
this.nav.XIstudhide();
this.nav.aduserhide();

// primng table

// this.service.getcandidatepaymentLarge().then((candidatesPay:any) => {
//   this.candidatesPay = candidatesPay;
//   this.loading = false;
// });
this.getallcandidates();
this.getamount();
this.primengConfig.ripple = true;

}

getEventValue($event:any) :string {
return $event.target.value;
}

getidEventValue($event:any) :string {
return $event.target.value;
}

getnameEventValue($event:any) :string {
return $event.target.value;
}

getdivisionClassEventValue($event:any) :string {
return $event.target.value;
}
getresigstatusEventValue($event:any) :string {
return $event.target.value;
}
getpaymentstatusEventValue($event:any) :string {
return $event.target.value;
}
getpaymentAmountValue($event:any) :string {
return $event.target.value;
}

getpaymentidValue($event:any) :string {
  return $event.target.value;
  }

getallcandidates()
{
  let schoolinfo :{schoolid:any}
  ={
    schoolid:this.schoolid
  }

  this.service.post('/School/viewAllStudentsforPmt',schoolinfo).subscribe((candidates:any) => {
    this.students = candidates.Data;
    // this.students.forEach(item => {
    //   item.checked = item.check === '0' ? true : false;
    // })
    this.loading = false;

    // //console.log(this.students);
});

}

allselectRow()
{

    //console.log(this.selectedCandidates);
    this.selectedstudents = [];
    for(var i=0;i<this.selectedCandidates.length;i++){
      //this.totalAmountCalculated=this.totalAmountCalculated+this.selectedCandidates[i].payment_amt;
         this.selectedCandidates[i].studentid;
        this.selectedstudents.push(this.selectedCandidates[i].studentid);
        // //console.log(this.checkarray[i]);
        // //console.log(this.selectedCandidates[i].studentid);  
    
    }
  
      // //console.log(this.abc);

      let stand :{ standard:any}={
        standard:this.scode
      }
      //console.log(stand);
   
      this.service.post('/School/getFeeAmount',stand).subscribe((amt:any)=>{
   
       this.amount = amt['Data'][0].amount;
       //console.log(this.amount);
       this.studentlength =  this.selectedstudents.length;
        //console.log(this.studentlength);
      this.totalAmountCalculated = this.amount * this.studentlength;
    
      //console.log(this.totalAmountCalculated);
   
   
      });
}
selectRow() {
  //console.log(this.selectedCandidates);
  this.selectedstudents = [];
  for(var i=0;i<this.selectedCandidates.length;i++){
    //this.totalAmountCalculated=this.totalAmountCalculated+this.selectedCandidates[i].payment_amt;
       this.selectedCandidates[i].studentid;
      this.selectedstudents.push(this.selectedCandidates[i].studentid);
      // //console.log(this.checkarray[i]);
      // //console.log(this.selectedCandidates[i].studentid);  
  
  }

  let stand :{ standard:any}={
    standard:this.scode
  }
  //console.log(stand);

  this.service.post('/School/getFeeAmount',stand).subscribe((amt:any)=>{

   this.amount = amt['Data'][0].amount;
   //console.log(this.amount);
   this.studentlength =  this.selectedstudents.length;
    //console.log(this.studentlength);
  this.totalAmountCalculated = this.amount * this.studentlength;

  //console.log(this.totalAmountCalculated);


  });

}

proceed()
{
  let stand :{ standard:any}={
    standard:this.scode
  }
  //console.log(stand);

  this.service.post('/School/getFeeAmount',stand).subscribe((amt:any)=>{

   this.amount = amt['Data'][0].amount;
   //console.log(this.amount);
   this.studentlength =  this.selectedstudents.length;
    // //console.log(this.studentlength);
  this.totalAmountCalculated = this.amount * this.studentlength;
  // //console.log(this.totalAmountCalculated);
  if(this.studentlength == '0' || this.totalAmountCalculated == 0)
  {
      alert('Please Select Amount Or Students');
  }
  else
  {
    this.showDiv.previous = !this.showDiv.previous;
  this.showDiv.current = false;
  }

  });
  
}

// getAmount

getamount()
{
   let stand :{ standard:any}={
     standard:this.scode
   }
   //console.log(stand);

   this.service.post('/School/getFeeAmount',stand).subscribe((amt:any)=>{

    this.amount = amt['Data'][0].amount;
    //console.log(this.amount);
    this.studentlength =  this.selectedstudents.length;
     //console.log(this.studentlength);
   this.totalAmountCalculated = this.amount * this.studentlength;
 
   //console.log(this.totalAmountCalculated);


   });

}


// for payment

checkTicked:boolean=true;
funChecked(e:any){
  if(e.target.checked){
    this.checkTicked=false;
  }
  else{
    this.checkTicked=true;
  }
}

// ICICI Payment Getway

paywithicici(){
  let para ={
    "amount": this.totalAmountCalculated,
    "transcationid":"recp"+Math.floor(Math.random() * (999999 - 100000)) + 100000+"_"+this.schoolid
   }
     let seq = this.service.post('/Icicipayment/getPaymentUrl',para);
     seq.subscribe((res:any)=>{
     this.result = res['url'];
      alert(this.result);
      window.open(this.result);
     },err=>{
      alert("Error");
   
    })
}


Pay(){

  // this.studentid = "";
  // this.amount = 100;
  let genpara ={
   "reciept": this.order_id = "recp"+Math.floor(Math.random() * (999999 - 100000)) + 100000+"_"+this.schoolid,
   "amount":this.totalAmountCalculated+"00"
  }
  let seq = this.service.post('/School/generateOrderid',genpara);
  seq.subscribe((res:any)=>{
      this.result = res['Data'];
      alert(this.result);
      //console.log(this.order_id);
      const options:any  = {
        "key": "rzp_test_oDw757e5auoleh", 
        "amount": this.totalAmountCalculated, 
        "currency": "INR",
        "name": "GBSHSE",
        "description": "Test Transaction",
        "image": "https://example.com/your_logo",
        "order_id":  this.result, 
        "theme": {
            "color": "blue"
        }
    };
    options.handler = ((response: any, error: any) => {
      options.response = response.razorpay_payment_id;
      // //console.log(response);
      // //console.log(options.response);
      this.payment_id = response.razorpay_payment_id;
      
          if( this.payment_id){
            let params={
              "orderid" : response.razorpay_order_id,
              "paymentid":response.razorpay_payment_id
            }
            let seq1 = this.service.post('/School/verifyRazorpaysig',params);
            seq1.subscribe((res:any)=>{
                this.signature = res['Data'];
                // alert(this.signature);
                if(this.signature == response.razorpay_signature){
                  let para ={
                    "studentid": this.selectedstudents,
                    "schoolid":this.schoolid,
                    "payment_id":this.payment_id,
                    "amount": this.totalAmountCalculated, 
                    "payment_status":"Paid"
                   }
                     let seq = this.service.post('/School/updatePaymentStatus',para);
                     seq.subscribe((res:any)=>{
                     this.result = res['Msg'];
                      alert(this.result);
                      window.location.reload();
                     },err=>{
                      alert("Error");
                   
                    })
                   }
              },err=>{
                alert("Error");
                   
              })
        
        
      }
      // call your backend api to verify payment signature & capture transaction
    });
      this.rzp1 = new this.nav.nativeWindow.Razorpay(options);
      this.rzp1.open();
  },err=>{
    alert("Error");
       
  })


}


}