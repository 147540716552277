
<div class="container-fluied">
  
 <div class="clsUpdateStatus  row mt3">  
      <div class="col-sm-10 optionWrap1">
       
        
       
      </div>       
      
    <div class="col-sm-2 optionWrap2 ">
      <button type="button" class="btn btn-primary btn-sm" (click)="exportexcel()"><i class="bi bi-cloud-arrow-down-fill"></i>  Export to Excel</button>
    </div>
  </div> 
</div>
<div class="container-fluied">
  <div class="row">
    <div class="col-sm-12 tablescroll" >

      <p-table #dt [value]="students"  [(selection)]="selectedCandidates" dataKey="studentid" id="excel-table" styleClass="p-datatable-customers" [rowHover]="true"
      [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50,100,1000,{ showAll: 'All' }]" [loading]="loading"
      [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [filterDelay]="0" [globalFilterFields]="['enrollmentyear','rollno','stream','course','stname','ftname','division','gender','submission_status','pmt_status','pmt_ID']">
     
     <ng-template pTemplate="caption">
        <div class="table-header">
            Candidate Details
            <span class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="dt.filterGlobal(getEventValue($event), 'contains')" placeholder="Search" />
            </span>
        </div>
    </ng-template>
    <ng-template pTemplate="header">
        <tr class="headerRow">
          
          <!-- <th style="width: 3rem"></th> -->
          <th id="noExl" style="width: 160px;">Action </th>
          <th pSortableColumn="enrollmentyear" style="width: 95px;">Enrollment Year <p-sortIcon field="enrollmentyear"></p-sortIcon></th> 
          <th *ngIf="standard == '11th'" pSortableColumn="stream" style="width: 95px;">Stream <p-sortIcon field="stream"></p-sortIcon></th> 
          <th *ngIf="standard == '11th'" pSortableColumn="course" style="width: 95px;">Course <p-sortIcon field="course"></p-sortIcon></th> 
          <!-- <th pSortableColumn="studentid" style="width: 165px;">Student Id <p-sortIcon field="studentid"></p-sortIcon></th> -->
          <th pSortableColumn="username" style="width: 135px;">Username <p-sortIcon field="username"></p-sortIcon></th>
          <th class="smallCol" pSortableColumn="division" style="width: 165px;">Division <p-sortIcon field="division"></p-sortIcon></th>
          <th pSortableColumn="rollno" style="width: 95px;">Roll no <p-sortIcon field="rollno"></p-sortIcon></th> 
        
          <!-- enrolment year, stream , course         -->
          <th pSortableColumn="stname" style="width: 175px;">Student Name <p-sortIcon field="stname"></p-sortIcon></th>
          <!-- <th pSortableColumn="ftname" style="width: 165px;">Father Name <p-sortIcon field="ftname"></p-sortIcon></th> -->
          <th class="smallCol" pSortableColumn="gender" style="width: 100px;">Gender<p-sortIcon field="gender"></p-sortIcon></th>
          <th pSortableColumn="submission_status" style="width: 140px;">Regist Status <p-sortIcon field="submission_status"></p-sortIcon></th>
          <th pSortableColumn="pmt_status" style="width: 125px;">Pmt_status <p-sortIcon field="pmt_status"></p-sortIcon></th>
          <th class="smallCol2" pSortableColumn="pmt_ID" style="width: 165px;">Pmt ID <p-sortIcon field="pmt_ID"></p-sortIcon></th>

          <!-- <th style="width: 8rem"></th> -->
      </tr>
        <tr>
          <!-- <th>
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th> -->
          <th></th>
          <th>
            <span class="p-input-icon-left"><i class="pi pi-search"></i>
            <input pInputText type="text" (input)="dt.filter(getenrollmentyearEventValue($event), 'enrollmentyear', 'startsWith')" placeholder="Enrll Year" class="p-column-filter">
            </span>   
          </th>
          <th *ngIf="standard == '11th'">
            <span class="p-input-icon-left"><i class="pi pi-search"></i>
            <input pInputText type="text" (input)="dt.filter(getstreamEventValue($event), 'stream', 'startsWith')" placeholder="Stream" class="p-column-filter">
            </span>   
          </th>
          <th *ngIf="standard == '11th'">
            <span class="p-input-icon-left"><i class="pi pi-search"></i>
            <input pInputText type="text" (input)="dt.filter(getcourseEventValue($event), 'course', 'startsWith')" placeholder="Course" class="p-column-filter">
            </span>   
          </th>
          <th>
            <span class="p-input-icon-left"><i class="pi pi-search"></i>
            <input pInputText type="text" (input)="dt.filter(getusernameEventValue($event), 'username', 'startsWith')" placeholder="Username" class="p-column-filter">
            </span>   
          </th>
          <th>
            <span class="p-input-icon-left"><i class="pi pi-search"></i>
            <input pInputText type="text" (input)="dt.filter(getdivisionEventValue($event), 'division', 'startsWith')" placeholder="Division" class="p-column-filter">
            </span>
          </th>
          <th>
            <span class="p-input-icon-left"><i class="pi pi-search"></i>
            <input pInputText type="text" (input)="dt.filter(getidEventValue($event), 'rollno', 'startsWith')" placeholder="Roll No" class="p-column-filter">
            </span>   
          </th>
         
          <th>
            <span class="p-input-icon-left"><i class="pi pi-search"></i>
            <input pInputText type="text" (input)="dt.filter(getnameEventValue($event), 'stname', 'startsWith')" placeholder="Student Name" class="p-column-filter">
            </span>
          </th>
          <!-- <th>
              <input pInputText type="text" (input)="dt.filter(getmiddleNameEventValue($event), 'secondname', 'startsWith')" placeholder="Middle Name" class="p-column-filter">
          </th> -->
          <!-- <th>
            <span class="p-input-icon-left"><i class="pi pi-search"></i>
            <input pInputText type="text" (input)="dt.filter(getlastNameEventValue($event), 'ftname', 'startsWith')" placeholder="Father Name" class="p-column-filter">
            </span>
          </th>
           -->
          <th>
            <span class="p-input-icon-left"><i class="pi pi-search"></i>
            <input pInputText type="text" (input)="dt.filter(getgenderEventValue($event), 'gender', 'startsWith')" placeholder="Gender" class="p-column-filter">
            </span>
        </th>
       
          <th>
            <span class="p-input-icon-left"><i class="pi pi-search"></i>
              <input pInputText type="text" (input)="dt.filter(getresigstatusEventValue($event), 'submission_status', 'startsWith')" placeholder="Registration Status" class="p-column-filter">
             </span> 
          </th>
          <th>
            <span class="p-input-icon-left"><i class="pi pi-search"></i>
            <input pInputText type="text" (input)="dt.filter(getpaymentstatusEventValue($event), 'pmt_status', 'startsWith')" placeholder="Payment Status" class="p-column-filter">
            </span>
        </th>
        <th>
          <span class="p-input-icon-left"><i class="pi pi-search"></i>
          <input pInputText type="text" (input)="dt.filter(getpaymentIDEventValue($event), 'pmt_ID', 'startsWith')" placeholder="Payment ID" class="p-column-filter">
          </span>
      </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-students >
      <tr class="p-selectable-row" >
          <!-- <td>
              <p-tableCheckbox [value]="students" ></p-tableCheckbox>
          </td> -->
          <td >
            <a  *ngIf="students.standard =='9th'; else test" aria-current="page" [routerLink]="['/edit-candidate',students.studentid]"><button class="btn btn-outline-primary btn-sm btnEdit"><i class="bi bi-pencil-square tblbtn"></i></button></a>
            <a *ngIf="students.standard =='9th'; else test1" aria-current="page" ><button class="btn btn-outline-primary btn-sm btnEdit" (click)="resetpassword(students.studentid);" ><i class="bi bi-key-fill"></i></button></a>
            <a *ngIf="students.standard =='9th'; else test2" aria-current="page" ><button class="btn btn-outline-danger btn-sm btnEdit" (click)="deleteCandidate(students.studentid);" ><i class="bi bi-trash-fill"></i></button></a>

            <ng-template #test>
              <a aria-current="page" [routerLink]="['/edit11th-candidate',students.studentid]"><button class="btn btn-outline-primary btn-sm btnEdit"><i class="bi bi-pencil-square tblbtn"></i></button></a>
             </ng-template>
            <ng-template #test1>
              <a aria-current="page" ><button class="btn btn-outline-primary btn-sm btnEdit" (click)="resetpassword(students.studentid);"><i class="bi bi-key-fill"></i></button></a>
            </ng-template>
            <ng-template #test2>
              <a aria-current="page" ><button class="btn btn-outline-danger btn-sm btnEdit" (click)="deleteCandidate(students.studentid);"><i class="bi bi-trash-fill"></i></button></a>
            </ng-template>
          </td>
         
          <!-- <td>
            <span class="p-column-title">Student_ID</span>
            {{students.studentid}}
        </td> -->
        <td>
          <!-- <span class="p-column-title">Roll No</span> -->
          {{students.enrollmentyear}}
      </td>
      <td *ngIf="standard == '11th'">
          <!-- <span class="p-column-title">Roll No</span> -->
          {{students.stream}}
      </td>
      <td *ngIf="standard == '11th'">
          <!-- <span class="p-column-title">Roll No</span> -->
          {{students.course}}
      </td>
     
        <td>
          <!-- <span class="p-column-title">Roll No</span> -->
          {{students.username}}
      </td>
      <td>
        <!-- <span class="p-column-title">Division</span> -->
        {{students.division}}
        
    </td>
          <td>
              <!-- <span class="p-column-title">Roll No</span> -->
              {{students.rollno}}
          </td>
          

          <td>
              <!-- <span class="p-column-title">Student Name</span> -->
              {{students.stname}}
          </td>
          <!-- <td>
              <span class="p-column-title">Mother Name</span>
              {{students.mtname}}
  
          </td> -->
          <!-- <td>
            <span class="p-column-title">Father Name</span>
            {{students.ftname}}
  
        </td> -->
          <td>
              <!-- <span class="p-column-title">Gender</span> -->
              {{students.gender}}
             
          </td>
         
        <td>
          <!-- <span class="p-column-title">submission_status</span> -->
          {{students.submission_status}}
          
      </td>
        <td>
          <!-- <span class="p-column-title">Payment Status</span> -->
          {{students.pmt_status}}
      </td>
      <td >
        <span class="p-column-title"></span>
        {{students.pmt_ID}}
      </td>
      
      </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
      <tr>
          <td colspan="9">No Students found.</td>
      </tr>
  </ng-template>
   

      </p-table>
      <br>
   </div>
   <br>
    
  </div>
</div>
