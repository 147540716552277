import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ChartType } from 'angular-google-charts';
import { NavbarService } from '../default/navbar/navbar.service';
import { SchoolserviceService } from '../schoolservice.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-boarddashboard',
  templateUrl: './boarddashboard.component.html',
  styleUrls: ['./boarddashboard.component.scss']
})

export class BoarddashboardComponent {
  last_date_9thstudent_regi!:any;
  last_date_9thschool_submit!:any;
  last_date_11thstudent_regi!:any;
  last_date_11thschool_submit!:any;
  weeklyRegistration_data!:any;
  registration9_data!: any[];   
  registration11_data!:any[]; 
  maleFemale9_data!:any[];
  maleFemale11_data!:any[];
  role_type: any;
  alldates: any;
  lastdate_9th: any;
  lastdate_11th: any;
  lastdatestud_11th: any;
  lastdatestud_9th: any;
  regi9StatusGhraphShow:boolean=false;
  regi11StatusGhraphShow:boolean=false;
  payStatusGhraphShow:boolean=false;
  genStatusGhraphShow:boolean=false;
  gen11StatusGhraphShow:boolean=false;
  studStatusGhraphShow:boolean=false;
  
  constructor(public nav: NavbarService, public router: Router,public datepipe: DatePipe, public service: SchoolserviceService) { }

  ngOnInit(): void {

    this.role_type =  localStorage.getItem('role_type');
    if(this.role_type == 'boardadmin')
    {
      this.nav.hide();
      this.nav.dhide();
      this.nav.adshow();
      this.nav.aduserhide();
      this.nav.asdhide();
      this.nav.studhide();
      this.nav.XIstudhide();
    }
    else
    {
      this.nav.hide();
      this.nav.dhide();
      this.nav.adhide();
      this.nav.adusershow();
      this.nav.asdhide();
      this.nav.studhide();
      this.nav.XIstudhide();
    }

    this.service.post('/School/viewlastdateforAll',{}).subscribe((res:any)=>{
      this.alldates = res['Data'];
    });


    this.getalldashboardinfo();

  }
  
  getalldashboardinfo() {
        let schoolinfo: {  } = {
         
        }
        this.service.post('/BoardApi/getDashboardData', schoolinfo).subscribe((res: any) => {
          //console.log(res);

          if(res.status=="Sucess"){
            if(res.Submitted9==0 && res.Pending9==0 && res.Rejected9==0 && res.Approved9==0){
              this.registration9_data = [
                ['Submitted', ""],
                ['Pending', ""],
                ['Rejected',""],
                ['Approved',""]
              ];
              this.regi9StatusGhraphShow=true;
            }
            else{
             this.registration9_data = [
               ['Submitted', res.Submitted9],
               ['Pending', res.Pending9],
               ['Rejected', res.Rejected9],
               ['Approved', res.Approved9]
             ];
            // //console.log(this.registration9_data);
            this.regi9StatusGhraphShow=false;
            }
          }
          if(res.Submitted11==0 && res.Pending11==0 && res.Rejected11==0 && res.Approved11==0){
            this.registration11_data = [
              ['Submitted', ""],
              ['Pending', ""],
              ['Rejected',""],
              ['Approved',""]
            ];
            this.regi11StatusGhraphShow=true;
           }
           else{
           this.registration11_data = [
            ['Submitted', res.Submitted11],
            ['Pending', res.Pending11],
            ['Rejected', res.Rejected11],
            ['Approved', res.Approved11]
           ];
           this.regi11StatusGhraphShow=false;
           
          }
          if(res.male9==0 && res.female9==0){
            this.maleFemale9_data = [
              ['Boys',""],
              ['Girls',""]
            ];
            this.genStatusGhraphShow=true;
          }
          else{              
           this.maleFemale9_data = [
             ['Boys', res.male9],
             ['Girls', res.female9]
           ];
           this.genStatusGhraphShow=false;
          }
          if(res.male11==0 && res.female11==0){
            this.maleFemale11_data = [
              ['Boys',""],
              ['Girls',""]
            ];
            this.gen11StatusGhraphShow=true;
          }
          else{              
           //male female 11 data
           this.maleFemale11_data = [
            ['Boys', res.male11],
            ['Girls', res.female11]
          ];
           this.gen11StatusGhraphShow=false;
          }
              //console.log(this.registration11_data);
            
              
    });
    this.service.post('/School/viewlastdateforCandidates',schoolinfo).subscribe((res: any)=>{
       //console.log(res);
      //last dates for 11th candidates
        this.last_date_9thstudent_regi = res['Data'][0].lastdate
       //last dates for 9th students
      this.lastdatestud_9th = this.datepipe.transform(this.last_date_9thstudent_regi, 'dd/MM/yyyy')
       //last dates for 9th school        
       this.last_date_11thstudent_regi = res['Data'][1].lastdate
       this.lastdatestud_11th = this.datepipe.transform(this.last_date_11thstudent_regi, 'dd/MM/yyyy')

    })
    this.service.post('/School/viewlastdateforSchools',schoolinfo).subscribe((res: any)=>{
       //console.log(res);
      //last dates for 11th school        
      this.last_date_9thschool_submit = res['Data'][1].lastdate
      this.lastdate_9th = this.datepipe.transform(this.last_date_9thschool_submit, 'dd/MM/yyyy')
     //last dates for 9th school        
     this.last_date_11thschool_submit = res['Data'][0].lastdate
     this.lastdate_11th = this.datepipe.transform(this.last_date_11thschool_submit, 'dd/MM/yyyy')
       
    })
    this.service.post('/School/registrationWeekWise',schoolinfo).subscribe((res: any)=>{
       //console.log(res['week 2'].Wed)
      //weekly registration data       
        
       //Responce:
      // week 1: 0
      // week 2: {Tue: 2, Wed: 1}
      // week 3: {Sat: 1, Sun: 1, Tue: 1}
      // week 4: 0
      //sample format need:
      //["Day of week", week 1, week 2, week 3, week 4, week 5],
    //  this.weeklyRegistration_data = [
    //     ["Monday",   res['week 1'].Mon, res['week 2'].Mon, res['week 3'].Mon, res['week 4'].Mon, res['week 5'].Mon],
    //     ["Tuesday",  res['week 1'].Tue, res['week 2'].Tue, res['week 3'].Tue, res['week 4'].Tue, res['week 5'].Tue],
    //     ["Wednsday", res['week 1'].Wed, res['week 2'].Wed, res['week 3'].Wed, res['week 4'].Wed, res['week 5'].Wed],
    //     ["Thursday", res['week 1'].Thu, res['week 2'].Thu, res['week 3'].Thu, res['week 4'].Thu, res['week 5'].Thu],
    //     ["Friday",   res['week 1'].Fri, res['week 2'].Fri, res['week 3'].Fri, res['week 4'].Fri, res['week 5'].Fri],
    //   ];
    this.weeklyRegistration_data=[
          
          ["Monday",   res['week 1'].Mon, res['week 2'].Mon, res['week 3'].Mon, res['week 4'].Mon],
          ["Tuesday",  res['week 1'].Tue, res['week 2'].Tue, res['week 3'].Tue, res['week 4'].Tue],
          ["Wednsday", res['week 1'].Wed, res['week 2'].Wed, res['week 3'].Wed, res['week 4'].Wed],
          ["Thursday", res['week 1'].Thu, res['week 2'].Thu, res['week 3'].Thu, res['week 4'].Thu],
          ["Friday",   res['week 1'].Fri, res['week 2'].Fri, res['week 3'].Fri, res['week 4'].Fri],
          ["Satarday", res['week 1'].Sat, res['week 2'].Sat, res['week 3'].Sat, res['week 4'].Sat],
          ["Sunday",   res['week 1'].Sun, res['week 2'].Sun, res['week 3'].Sun, res['week 4'].Sun]
        //   ["Monday",  1,3,2,5,2,0],
        // ["Tuesday",  0,2,1,0,0,0],
        // ["Wednsday",  4,3,3,2,3,0],
        // ["Thursday",  2,5,0,1,2,0],
        // ["Friday",  3,0,3,2,1,0],
        // ["Saturday",  3,0,3,2,1,0],
        // ["Sunday",  3,0,3,2,1,0]
        
    ]
    })
    
    
  }
  
  
  //9th students registration settings
  registration9_title = '9th Student Registration';
  registration9_type = ChartType.PieChart;
  registration9_options = {
    colors: ['#8D9B6A','#8F5B34','#8A9EA7','#DAB692'], 
    is3D: true,
    legend: { position: 'right', alignment: 'center', textStyle: { color: 'black', fontSize: 12 } },
    pieSliceText: 'value',
  };
  registration9_width = 500;
  registration9_height = 140;
  
  //11th students registration settings
  registration11_title = '11th Student Registration';
  registration11_type = ChartType.PieChart;  
  registration11_options = {
    colors: ['#FFC94B', '#C1867B','#F17A7E','#4A6163'],
    is3D: true,
    legend: { position: 'right', alignment: 'center', textStyle: { color: 'black', fontSize: 12 } },
    pieSliceText: 'value',
  };
  registration11_width = 500;
  registration11_height = 140;
  //male-female data settings
  maleFemale9_title = '9th Male/Female Percentage';
  maleFemale11_title = '11th Male/Female Percentage';
  maleFemale_type = ChartType.PieChart;
  maleFemale_options = {
    colors: ['#2ca8c2', '#98cb4a'],
    is3D: true,
    legend: { position: 'right', alignment: 'center', textStyle: { color: 'black', fontSize: 16 } },
    pieSliceText: 'value',
  };
  maleFemale_width = 500;
  maleFemale_height = 140;
  // weekly registration  data
  weeklyRegistration_title = 'No of Registration in Month';
  weeklyRegistration_type = ChartType.LineChart;
  
  weeklyRegistration_options = {
    hAxis: {
      title: 'Month',
    },
    vAxis: {
      title: 'No of Registration Weekly'
    },
    
    legend: { position: 'bottom', alignment: 'center', textStyle: { color: 'blue', fontSize: 16 } },
    
  };
  weeklyRegistration_width = 500;
  weeklyRegistration_height = 140;
}
