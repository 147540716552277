<div class="container login-container bg-light mb-3">

</div>
<div class="container login-container bg-light mb-3">
  <br>
  <div class="card border-dark mb-3">
      <h5 class="card-header">Candidate Application</h5>
      <div class="card-body text-dark ">
        <div  class="justify-content-md-center">
         
         
          <form role="form" [formGroup]= "studentprofileform">
            
            <figcaption class="blockquote" style="color: #0062cc;">
              <cite title="Source Title"><b>- Personal Information</b></cite>
             </figcaption> <hr>
             <div class="form-group row mb-3">
              <label for="staticname" class="col-sm-2 col-form-label">Class<span class="star">*</span></label>
              <div class="col-sm-2">
                <input type="text" formControlName="class" class="form-control" id="staticName"  readonly>
              </div>       
              <label for="staticname" class="col-sm-2 col-form-label">Roll No<span class="star">*</span></label>
              <div class="col-sm-2">
                <input type="text" formControlName="rollno" readonly class="form-control" id="staticName"  readonly>
              </div>
             
              <label for="staticname" class="col-sm-2 col-form-label">Division<span class="star">*</span></label>
              <div class="col-sm-2">
                <input type="text"  class="form-control" formControlName="division" readonly id="staticName"  value ="A" readonly>
                
              </div>
              
            </div>
             <div class="form-group row mb-3">
                      
              <label for="staticname" class="col-sm-2 col-form-label">Candidate's Name<span class="star">*</span></label>
              <div class="col-sm-5">
                <input type="text" formControlName="stname" class="form-control" id="staticName" placement="right"placement="right"
                [ngbTooltip]="content1" tooltipClass="formToolTip" 
                [ngClass]="{ 'is-invalid': submitted && personal.stname.errors }" readonly>
                <div *ngIf="submitted && personal.stname.errors" class="invalid-feedback" >
                  <div *ngIf="personal.stname.errors.required">
                    Candidate Name Is Required.
                  </div>
                </div>
                <ng-template #content1>
                  <div class="tooltipWrap">
                    <div class="tooltip-arrow"></div>
                    <div class="toolTipTitle">
                      <strong>Candidate's Name </strong>
                    </div>
                    <div class="toolTipText">
                      <p>Candidate's Name must exactly be the same as registered in Class 10th or equivalent qualifying Examination.</p>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
            <div class="form-group row mb-3">
              <label for="staticfathername" class="col-sm-2 col-form-label">Father's Name<span class="star">*</span></label>
              <div class="col-sm-5">
                <input type="text" formControlName="ftname" class="form-control" id="inputfathername" placement="right"
                [ngbTooltip]="content2" tooltipClass="formToolTip" 
                [ngClass]="{ 'is-invalid': submitted && personal.ftname.errors }" readonly>
                         <div *ngIf="submitted && personal.ftname.errors" class="invalid-feedback">
                           <div *ngIf="personal.ftname.errors.required">
                             Father's Name Is Required.
                           </div>
                         </div>
                         <ng-template #content2>
                          <div class="tooltipWrap">
                            <div class="tooltip-arrow"></div>
                            <div class="toolTipTitle">
                              <strong>Father's Name </strong>
                            </div>
                            <div class="toolTipText">
                              <p>Father's name must exactly be the same as registered in Class 10th / equivalent qualifying Examination. No prefix/title such as Mr / Shri / Fr / Dr / Col etc should be used.</p>
                            </div>
                          </div>
                        </ng-template>
             
              </div>
            </div>
            <div class="form-group row mb-3">
              <label for="staticmothername" class="col-sm-2 col-form-label">Mother's Name<span class="star">*</span></label>
              <div class="col-sm-5">
                <input type="text" formControlName="mtname" class="form-control" id="inputmothername" placement="right"
                [ngbTooltip]="content3" tooltipClass="formToolTip" 
                [ngClass]="{ 'is-invalid': submitted && personal.ftname.errors }" readonly>
                         <div *ngIf="submitted && personal.ftname.errors" class="invalid-feedback">
                           <div *ngIf="personal.ftname.errors.required">
                             Mother's Name Is Required.
                           </div>
                         </div>
                         <ng-template #content3>
                          <div class="tooltipWrap">
                            <div class="tooltip-arrow"></div>
                            <div class="toolTipTitle">
                              <strong>Mother's Name </strong>
                            </div>
                            <div class="toolTipText">
                              <p>Mother's name must exactly be the same as registered in Class 10th / equivalent qualifying Examination. No prefix/title such as Mr / Shri / Fr / Dr / Col etc should be used.</p>
                            </div>
                          </div>
                        </ng-template> 
       
              </div>
            </div>
            <div class="form-group row mb-3">
              <label for="staticdob" class="col-sm-2 col-form-label">Date Of Birth<span class="star">*</span></label>
              <div class="col-sm-5">
                <input type="text" formControlName="dob" class="form-control" id="inputdob" 
                [ngClass]="{ 'is-invalid': submitted && personal.dob.errors }" readonly>
                <div *ngIf="submitted && personal.dob.errors" class="invalid-feedback">
                  <div *ngIf="personal.dob.errors.required">
                    Date Of Birth Is Required.
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row mb-3">
              <label for="staticgender" class="col-sm-2 col-form-label">Gender<span class="star">*</span></label>
              <div class="col-sm-5">
                  <select id="inputgender" formControlName="gender" class="form-select" readonly [ngClass]="{ 'is-invalid': submitted && personal.gender.errors }" >
                    <option hidden value="" disabled selected>Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <!-- <option value="Transe_gender">Transe Gender</option> -->
                    </select>
                    
                <div *ngIf="submitted && personal.gender.errors" class="invalid-feedback">
                  <div *ngIf="personal.gender.errors.required">
                    Gender Is Required.
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row mb-3">
              <label for="staticEmail" class="col-sm-2 col-form-label">Email<span class="star">*</span></label>
              <div class="col-sm-5">
                <input type="email" formControlName="email" class="form-control" id="staticEmail" placement="right"
                [ngbTooltip]="content4" tooltipClass="formToolTip" [ngClass]="{ 'is-invalid': submitted && personal.email.errors }" 
                >
                         <div *ngIf="submitted && personal.email.errors" class="invalid-feedback">
                          <div *ngIf="personal.email.errors.required">
                            Email Id Is Required.
                          </div>
                          <div *ngIf="personal.email.errors.email">Email must be a valid email address</div>
                        </div>
                        <ng-template #content4>
                          <div class="tooltipWrap" >
                            <div class="tooltip-arrow"></div>
                            <div class="toolTipTitle">
                              <strong>Email ID !!</strong>
                            </div>
                            <div class="toolTipText">
                              <p>Please fill up carefully, as all communications would be sent at this email ID.</p>
                            </div>
                          </div>
                        </ng-template>
                </div>
            </div>
            <div class="form-group row mb-3">
              <label for="staticEmail" class="col-sm-2 col-form-label"> Mobile Number<span class="star">*</span></label>
              <div class="col-sm-5">
                <input type="text" formControlName="mobile_no" class="form-control" id="staticEmail" placement="right"
                [ngbTooltip]="content5" tooltipClass="formToolTip" [ngClass]="{ 'is-invalid': submitted && personal.mobile_no.errors }"
                (keypress)="allowmobileno($event)">
                         <div *ngIf="submitted && personal.mobile_no.errors" class="invalid-feedback">
                          <div *ngIf="personal.mobile_no.errors.required">
                              Mobile Number Is Required.
                          </div> 
                          <div *ngIf="personal.mobile_no.errors.pattern">Mobile number is not 10 digit</div>
                        </div>
                        <div [innerHTML]="mobmsg" style="color: rgb(253, 0, 0);"></div>
                        <ng-template #content5>
                          <div class="tooltipWrap">
                            <div class="tooltip-arrow"></div>
                            <div class="toolTipTitle">
                              <strong> Mobile Number. !!</strong>
                            </div>
                            <div class="toolTipText">
                              <p>Please fill up carefully as all SMS would be sent on this  Mobile Number.</p>
                            </div>
                          </div>
                        </ng-template>
              </div>
            </div>
            <div class="form-group row mb-3">
              <label for="staticEmail" class="col-sm-2 col-form-label">Alternate Contact Number</label>
              <div class="col-sm-5">
                <input type="text" formControlName="alt_mobile_no" class="form-control" id="staticEmail" placement="right"
                placement="right" [ngbTooltip]="content6" tooltipClass="formToolTip"  (keypress)="allowNumeric($event)">
                
                <div [innerHTML]="conmsgs" style="color: rgb(253, 0, 0);"></div>
                <ng-template #content6>
                  <div class="tooltipWrap">
                    <div class="tooltip-arrow"></div>
                    <div class="toolTipTitle">
                      <strong>Secondary Contact number</strong>
                    </div>
                    <div class="toolTipText">
                      <p>Secondary Contact no must be working and active.</p>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
                        <div class="form-group row mb-3">
                          <label for="staticEmail" class="col-sm-2 col-form-label">AADHAR Number<span class="star">*</span></label>
                          <div class="col-sm-5">
                            <input type="text" formControlName="aadhar" class="form-control" id="staticEmail"
                            (keypress)="allowNumericforaadhar($event)"[ngClass]="{ 'is-invalid': submitted && personal.aadhar.errors }" 
                            >
                                     <div *ngIf="submitted && personal.aadhar.errors" class="invalid-feedback">
                                      <div *ngIf="personal.aadhar.errors.required">
                                        AADHAR Number Is Required.
                                      </div>
                                      <div [innerHTML]="aadharmsg" style="color: rgb(253, 0, 0);"></div>
                                      <div *ngIf="personal.aadhar.errors.pattern">Aadhar number must be 12 digits</div>
                                    </div>
                            
                          </div>
                        </div>
                        <div class="form-group row mb-3">
                          <label for="staticcaste" class="col-sm-2 col-form-label">Caste<span class="star">*</span></label>
                          <div class="col-sm-5">
                            <select id="inputcaste" formControlName="caste" class="form-select"  [ngClass]="{ 'is-invalid': submitted && personal.caste.errors }">
                              <option hidden value="" disabled selected>Select Caste</option>
                               <option value="General">General</option>
                              <option value="OBC">OBC</option>
                              <option value="SC">SC</option>
                              <option value="ST">ST</option>
                              <!-- <option value="East_Indians">East Indians</option>
                              <option value="Goan_Catholics">Goan Catholics</option>
                              <option value="Karwari_Catholics">Karwari Catholics</option>
                              <option value="Koli_Christians">Koli Christians</option>
                              <option value="Mangalorean_Catholics">Mangalorean Catholics</option> -->
                            </select>
                            
                            <div *ngIf="submitted && personal.caste.errors" class="invalid-feedback">
                              <div *ngIf="personal.caste.errors.required">
                                Caste Is Required.
                              </div>
                            </div>
                          </div>
                        </div>
                        <figcaption class="blockquote" style="color: #0062cc;">
                          <cite title="Source Title"><b>- Address Information</b></cite>
                       </figcaption> <hr>
                       <div class="form-group row mb-3">
                        <label for="staticEmail" class="col-sm-2 col-form-label">Street<span class="star">*</span></label>
                        <div class="col-sm-5">
                          <input type="text" formControlName="street" class="form-control" id="staticEmail" [ngbTooltip]="content7" tooltipClass="formToolTip" placement="right"
                          [ngClass]="{ 'is-invalid': submitted && personal.street.errors }">
                          <div *ngIf="submitted && personal.street.errors" class="invalid-feedback">
                           <div *ngIf="personal.street.errors.required">
                             Street Is Required.
                           </div>
                         </div>
                         <ng-template #content7>
                          <div class="tooltipWrap">
                            <div class="tooltip-arrow"></div>
                            <div class="toolTipTitle">
                              <strong>Street Name </strong>
                            </div>
                            <div class="toolTipText">
                              <p>Street Name must be as per residential address proof</p>
                            </div>
                          </div>
                        </ng-template> 
                        </div>
                      </div>
                      <div class="form-group row mb-3">
                        <label for="staticEmail" class="col-sm-2 col-form-label">Locality</label>
                        <div class="col-sm-5">
                          <input type="text" formControlName="locality" class="form-control" id="staticEmail" [ngbTooltip]="content8" tooltipClass="formToolTip" placement="right">
                          <ng-template #content8>
                            <div class="tooltipWrap">
                              <div class="tooltip-arrow"></div>
                              <div class="toolTipTitle">
                                <strong>Locality!!!</strong>
                              </div>
                              <div class="toolTipText">
                                <p>Please fill up locality must be as per residential address proof</p>
                              </div>
                            </div>
                          </ng-template>
                        </div>
                      </div>
                      <div class="form-group row mb-3">
                        <label for="staticAddrArea" class="col-sm-2 col-form-label">City/Town/Village<span class="star">*</span></label>
                        <div class="col-sm-5">
                          <input type="text" formControlName="city" class="form-control" id="staticAddrArea" [ngbTooltip]="content9" tooltipClass="formToolTip" placement="right"
                          [ngClass]="{ 'is-invalid': submitted && personal.city.errors }" (keypress)="keyPressAlphanumeric($event)" >
                          <div *ngIf="submitted && personal.city.errors" class="invalid-feedback">
                           <div *ngIf="personal.city.errors.required">
                             City Is Required.
                           </div>
                         </div>
                         <div [innerHTML]="alphmsg" style="color: rgb(253, 0, 0);"></div>
                         <ng-template #content9>
                          <div class="tooltipWrap">
                            <div class="tooltip-arrow"></div>
                            <div class="toolTipTitle">
                              <strong>City/Town/Village!</strong>
                            </div>
                            <div class="toolTipText">
                              <p>Please fill up City must be as per residential address proof.</p>
                            </div>
                          </div>
                        </ng-template>
                        </div>
                      </div>
                      <div class="form-group row mb-3">
                        <label for="staticEmail" class="col-sm-2 col-form-label">State<span class="star">*</span></label>
                        <div class="col-sm-5">
                          <input type="text" formControlName="state" class="form-control" id="staticEmail" [ngbTooltip]="content10" tooltipClass="formToolTip" placement="right"
                          [ngClass]="{ 'is-invalid': submitted && personal.state.errors }">
                          <div *ngIf="submitted && personal.state.errors" class="invalid-feedback">
                           <div *ngIf="personal.state.errors.required">
                             State Is Required.
                           </div>
                         </div>
                         <ng-template #content10>
                          <div class="tooltipWrap">
                            <div class="tooltip-arrow"></div>
                            <div class="toolTipTitle">
                              <strong>State!!</strong>
                            </div>
                            <div class="toolTipText">
                              <p>Please fill up carefully as per provided document.</p>
                            </div>
                          </div>
                        </ng-template>
                        </div>
                      </div>
                      <div class="form-group row mb-3">
                        <label for="staticEmail" class="col-sm-2 col-form-label">Taluka<span class="star">*</span></label>
                        <div class="col-sm-3">
                          <!-- <input type="text" formControlName="taluka" class="form-control" id="staticEmail" [ngbTooltip]="content11" tooltipClass="formToolTip" placement="right"
                          [ngClass]="{ 'is-invalid': submitted && personal.taluka.errors }"> -->
                          <select id="inputgender" formControlName="taluka" class="form-select" [ngbTooltip]="content11" tooltipClass="formToolTip" placement="right"
                          [ngClass]="{ 'is-invalid': submitted && personal.taluka.errors }" (change)="getdistrict($event.target)">
                          <option selected  value="" disabled >Select Taluka</option>
                            <option *ngFor="let t of alltalukas" [value]="t.taluka_name">{{t.taluka_name}}</option>
                          </select>
                          <div *ngIf="submitted && personal.taluka.errors" class="invalid-feedback">
                           <div *ngIf="personal.taluka.errors.required">
                             Taluka Is Required.
                           </div>
                         </div>
                         <ng-template #content11>
                          <div class="tooltipWrap">
                            <div class="tooltip-arrow"></div>
                            <div class="toolTipTitle">
                              <strong>Taluka!!</strong>
                            </div>
                            <div class="toolTipText">
                              <p>Please fill up carefully as per residential address proof</p>
                            </div>
                          </div>
                        </ng-template>
                        </div>
                        <label for="staticEmail" class="col-sm-2 col-form-label">District<span class="star">*</span></label>
                        <div class="col-sm-3">
                          <input type="text" formControlName="district" class="form-control" id="staticEmail" [ngbTooltip]="content12" tooltipClass="formToolTip" placement="right"
                          [ngClass]="{ 'is-invalid': submitted && personal.district.errors }" readonly>
                          <div *ngIf="submitted && personal.district.errors" class="invalid-feedback">
                           <div *ngIf="personal.district.errors.required">
                             District Is Required.
                           </div>
                         </div>
                         <ng-template #content12>
                          <div class="tooltipWrap">
                            <div class="tooltip-arrow"></div>
                            <div class="toolTipTitle">
                              <strong>District!!</strong>
                            </div>
                            <div class="toolTipText">
                              <p>Please fill up carefully as per residential address proof</p>
                            </div>
                          </div>
                        </ng-template>
                        </div>
                      </div>
                      <div class="form-group row mb-3">
                        <label for="staticEmail" class="col-sm-2 col-form-label">Landmark</label>
                        <div class="col-sm-3">
                          <input type="text" formControlName="landmark" class="form-control" id="staticEmail">
                        </div>
                        <label for="staticEmail" class="col-sm-2 col-form-label">Pincode<span class="star">*</span></label>
                        <div class="col-sm-3">
                          <input type="text" formControlName="pincode" class="form-control" id="staticEmail" [ngbTooltip]="content13" tooltipClass="formToolTip" placement="right"
                          [ngClass]="{ 'is-invalid': submitted && personal.pincode.errors }" (keypress)="allowNumericDigitsOnlyOnKeyUp($event)">
                          <div *ngIf="submitted && personal.pincode.errors" class="invalid-feedback">
                           <div *ngIf="personal.pincode.errors.required">
                             Pincode Is Required.
                           </div>
                           <div *ngIf="personal.pincode.errors.pattern">Enter only number.</div>
                           <div [innerHTML]="pinmsg" style="color: rgb(253, 0, 0);"></div>
                         </div>
                        
                         <ng-template #content13>
                          <div class="tooltipWrap">
                            <div class="tooltip-arrow"></div>
                            <div class="toolTipTitle">
                              <strong>Pincode!!</strong>
                            </div>
                            <div class="toolTipText">
                              <p>Please fill up carefully as per residential address proof</p>
                            </div>
                          </div>
                        </ng-template>
                        </div>
                      </div>
                      <div class="form-group row mb-3">
                        <label for="staticEmail" class="col-sm-2 col-form-label">House OwnerShip</label>
                        <div class="col-sm-1" style="margin-top: 9px;margin-left: 25px;width: 61px;">
                            <input class="form-check-input col-sm-3" type="radio" formControlName="house_ownership" name="house_ownership" id="gridRadios1" value="Own" > Own
                        </div>
                        <div class="col-sm-1" style="margin-top: 9px;margin-left: 25px;width: 61px;">
                            <input class="form-check-input col-sm-" type="radio" formControlName="house_ownership" name="house_ownership" id="gridRadios2" value="Rent" > Rent
                      
                        </div>
                     </div>
                      <div class="hidden form-group row mb-3">
                        <label for="staticEmail" class="col-sm-2 col-form-label">Internet Coverage</label>
                        <div class="col-sm-1" style="margin-top: 9px;margin-left: 25px; width: 61px;">
                            <input class="form-check-input col-sm-3" type="radio" formControlName="internet_coverage" name="internet_coverage" id="gridRadios1" value="Yes" > Yes
                        </div>
                        <div class="col-sm-1" style="margin-top: 9px;margin-left: 25px;width: 61px;">
                            <input class="form-check-input col-sm-" type="radio" formControlName="internet_coverage" name="internet_coverage" id="gridRadios2" value="No" > No
                      
                        </div>
                     </div>
                     <div class=" hidden form-group row mb-3">
                        <label for="staticEmail" class="col-sm-2 col-form-label">Distance To Coverage (in Kms)</label>
                        <div class="col-sm-5">
                            <input type="text" class="form-control"  formControlName="distance_coverage" id="staticEmail"
                            [ngbTooltip]="content14" tooltipClass="formToolTip" placement="right"  (keypress)="allowNumericDfordistance($event)">
                            <div [innerHTML]="distmsg" style="color: rgb(253, 0, 0);"></div>
                          </div>
                         <ng-template #content14>
                          <div class="tooltipWrap">
                            <div class="tooltip-arrow"></div>
                            <div class="toolTipTitle">
                              <strong>distance coverage!!</strong>
                            </div>
                            <div class="toolTipText">
                              <p>Please fill up carefully in kilometers </p>
                            </div>
                          </div>
                        </ng-template>
                     </div>
                     <div class="form-group row mb-3"  >
                      <label for="staticname" class="col-sm-2 col-form-label">Distance of School from House (in Kms)</label>
                        <div class="col-sm-5">
                          <input type="text"  class="form-control" formControlName="distance_of_school_to_house" id="staticName" 
                          [ngbTooltip]="content14" tooltipClass="formToolTip" placement="right" (keypress)="allowNumericDigitschool($event)">
                          <div [innerHTML]="schoolmsg" style="color: rgb(253, 0, 0);"></div>
                        </div>
                        <ng-template #content14>
                          <div class="tooltipWrap">
                            <div class="tooltip-arrow"></div>
                            <div class="toolTipTitle">
                              <strong>Distance to school from place of residence (in Kms)!!</strong>
                            </div>
                            <div class="toolTipText">
                              <p>Please fill up carefully in kilometers </p>
                            </div>
                          </div>
                        </ng-template>  
                     </div>
                     <div class="form-group row mb-3"  >
                      <label for="staticname" class="col-sm-2 col-form-label">Mode of Transport</label>
                      <div class="col-sm-5">
                        <select id="inputgender" formControlName="mode_of_transport" class="form-select">
                          <option selected disabled>Select Mode Of Transport</option>
                          <option value="Walking">Walking</option>
                          <option value="Private_Vehicle">Private Vehicle</option>
                          <option value="Auto_Rikshaw">Auto Rickshaw</option>
                          <option value="Public_Transport">Public Transport</option>
                        </select>
                      </div>
                     
                     </div>
                      <div class="form-group row mb-3">
                        <label for="staticEmail" class="col-sm-2 col-form-label">Access to Smart Phone</label>
                        <div class="col-sm-1" style="margin-top: 9px;margin-left: 25px; width: 61px;">
                            <input class="form-check-input col-sm-3" type="radio" formControlName="access_to_smart_phone" name="access_smartphone" id="gridRadios1" value="Yes" > Yes
                        </div>
                        <div class="col-sm-1" style="margin-top: 9px;margin-left: 25px; width: 61px;">
                            <input class="form-check-input col-sm-3" type="radio" formControlName="access_to_smart_phone" name="access_smartphone" id="gridRadios2" value="No" > No
                      
                        </div>
                     </div>
                     <div class="form-group row mb-3">
                        <label for="staticEmail" class="col-sm-2 col-form-label">Access to Internet</label>
                        <div class="col-sm-1" style="margin-top: 9px;margin-left: 25px; width: 61px;">
                            <input class="form-check-input col-sm-3" type="radio" formControlName="access_internet" name="access_internet" id="gridRadios1" value="Yes" > Yes
                        </div>
                        <div class="col-sm-1" style="margin-top: 9px;margin-left: 25px; width: 61px;">
                            <input class="form-check-input col-sm-3" type="radio" formControlName="access_internet" name="access_internet" id="gridRadios2" value="No" > No
                      
                        </div>
                     </div>
                    
                        <figcaption class="blockquote" style="color: #0062cc;">
                          <cite title="Source Title"><b>- Other Information</b></cite>
                       </figcaption> <hr>
  
                       <div class="form-group row mb-3">
                        <label for="staticEmail" id="quallabel" class="col-sm-2 col-form-label" >Father's Education</label>
                        <div class="col-sm-5">
                            <input type="text" class="form-control" formControlName="fathers_qualification" id="staticEmail"
                            placement="right" [ngbTooltip]="content15" tooltipClass="formToolTip" >
                         </div>
                         <ng-template #content15>
                          <div class="tooltipWrap">
                            <div class="tooltip-arrow"></div>
                            <div class="toolTipTitle">
                              <strong>father's qualification!!</strong>
                            </div>
                            <div class="toolTipText">
                              <p>Please fill father's qualification.</p>
                            </div>
                          </div>
                        </ng-template>
                      </div>
                      <div class="form-group row mb-3">
                         <label for="staticEmail" class="col-sm-2 col-form-label">Father's Job</label>
                        <div class="col-sm-5">
                            <input type="text" class="form-control" formControlName="fathers_job" id="staticEmail"
                            placement="right" [ngbTooltip]="content16" tooltipClass="formToolTip" >
                         </div>
                         <ng-template #content16>
                          <div class="tooltipWrap">
                            <div class="tooltip-arrow"></div>
                            <div class="toolTipTitle">
                              <strong>father's job!!</strong>
                            </div>
                            <div class="toolTipText">
                              <p>Please fill Current work Profile of Father</p>
                            </div>
                          </div>
                        </ng-template>
                      </div>
                      <div class="form-group row mb-3">
                        <label for="staticEmail" class="col-sm-2 col-form-label">Father's Annual Income</label>
                        <div class="col-sm-5">
                            <input type="number" class="form-control" formControlName="fathers_annual_income" id="staticEmail"
                            placement="right" [ngbTooltip]="content17" tooltipClass="formToolTip" >
                         </div>
                         <ng-template #content17>
                          <div class="tooltipWrap">
                            <div class="tooltip-arrow"></div>
                            <div class="toolTipTitle">
                              <strong> Father's annual income!!</strong>
                            </div>
                            <div class="toolTipText">
                              <p>Please fill average yearly income, related documents can be asked for varification</p>
                            </div>
                          </div>
                        </ng-template>
                      </div>
                      <div class="form-group row mb-3">
                         <label for="staticEmail" id="quallabel" class="col-sm-2 col-form-label">Mother's Education</label>
                        <div class="col-sm-5">
                            <input type="text" class="form-control" formControlName="mothers_qualification" id="staticEmail"
                            placement="right" [ngbTooltip]="content18" tooltipClass="formToolTip" >
                         </div>
                         <ng-template #content18>
                          <div class="tooltipWrap">
                            <div class="tooltip-arrow"></div>
                            <div class="toolTipTitle">
                              <strong> Mother's Qualification!!</strong>
                            </div>
                            <div class="toolTipText">
                              <p>Please fill Mother's Qualification.</p>
                            </div>
                          </div>
                        </ng-template>
                      </div>
                      <div class="form-group row mb-3">
                        <label for="staticEmail" class="col-sm-2 col-form-label">Mother's Job</label>
                        <div class="col-sm-5">
                            <input type="text" class="form-control" formControlName="mothers_job" id="staticEmail"
                            placement="right" [ngbTooltip]="content19" tooltipClass="formToolTip" >
                         </div>
                         <ng-template #content19>
                          <div class="tooltipWrap">
                            <div class="tooltip-arrow"></div>
                            <div class="toolTipTitle">
                              <strong> Mother's job!!</strong>
                            </div>
                            <div class="toolTipText">
                              <p>Please fill current job profile, related documents can be asked for varification</p>
                            </div>
                          </div>
                        </ng-template>
                      </div>
                      <div class="form-group row mb-3">
                         <label for="staticEmail" class="col-sm-2 col-form-label">Mother's Annual Income</label>
                        <div class="col-sm-5">
                            <input type="number" class="form-control" formControlName="mothers_annual_income" id="staticEmail"
                            placement="right" [ngbTooltip]="content20" tooltipClass="formToolTip" >
                         </div>
                         <ng-template #content20>
                          <div class="tooltipWrap">
                            <div class="tooltip-arrow"></div>
                            <div class="toolTipTitle">
                              <strong> Mother's annual income!!</strong>
                            </div>
                            <div class="toolTipText">
                              <p>Please fill annual income of mother, related documents can be asked for varification</p>
                            </div>
                          </div>
                        </ng-template>
                      </div>
                        <figcaption class="blockquote" style="color: #0062cc;">
                          <cite title="Source Title"><b>-  Academic Information </b></cite>
                       </figcaption> <hr>
                       <div class="form-group row mb-3">
                                
                        <label for="staticname" class="col-sm-2 col-form-label">Board Name<span class="star">*</span></label>
                            <div class="col-sm-5">
                              <select id="inputgender" formControlName="boardname" class="form-select" #b (change)="boardChanged(b.value)" 
                              [ngClass]="{ 'is-invalid': submitted && personal.boardname.errors }">
                                <option value="" disabled selected>Select Board</option>
                                    <option value="Goa_Board">Goa Board</option>
                                    <option value="other">Other</option>
                              </select>
                             
                              <div *ngIf="submitted && personal.boardname.errors" class="invalid-feedback">
                               <div *ngIf="personal.boardname.errors.required">
                                 Board Name Is Required.
                               </div>                                            
                             </div>
                            </div>
                        </div>
                            <div class="form-group row mb-3" *ngIf="b.value == 'Goa_Board'" >
                              <label for="staticname" class="col-sm-2 col-form-label">SSC Exam Month-Year <small>(As Per Marksheet)</small><span class="star">*</span></label>
                                <div class="col-sm-3">
                                  <input type="text"  class="form-control" formControlName="exam"  [ngClass]="{ 'is-invalid': submitted && personal.exam.errors }" >
                                  <div *ngIf="submitted && personal.exam.errors" class="invalid-feedback">
                                    <div *ngIf="personal.exam.errors.required">
                                      SSC Exam Year Is Required.
                                    </div>                                            
                                  </div>        
                                </div>
                                <label for="staticname" class="col-sm-2 col-form-label">SSC Seat Number<span class="star">*</span></label>
                                <div class="col-sm-3">
                                  <input type="text"  class="form-control" formControlName="seat" id="staticName"
                                  [ngClass]="{ 'is-invalid': submitted && personal.seat.errors }">
                                  <div *ngIf="submitted && personal.seat.errors" class="invalid-feedback">
                                    <div *ngIf="personal.seat.errors.required">
                                      Seat No Is Required.
                                    </div>                                            
                                  </div>         
                                </div>
                                <!-- <input type="button" class="btnSubmit btn btn-primary"  value="Submit" /> -->
                            </div>
                            <div *ngIf="b.value == 'other'">
                                <div class="form-group row mb-3"  >
                                <label for="staticname" class="col-sm-2 col-form-label">Board's Name<span class="star">*</span></label>
                                  <div class="col-sm-5">
                                    <input type="text"  class="form-control" formControlName="otherboardname" id="staticName"  
                                    [ngClass]="{ 'is-invalid': submitted && personal.otherboardname.errors }">
                                             <div *ngIf="submitted && personal.otherboardname.errors" class="invalid-feedback">
                                              <div *ngIf="personal.otherboardname.errors.required">
                                                Other Board Name Is Required.
                                              </div>                                            
                                            </div>
                                  </div>
                               </div>
                               <div class="form-group row mb-3"  >
                                <label for="staticname" class="col-sm-2 col-form-label">Provisional/ Final Eligibility Number<span class="star">*</span></label>
                                  <div class="col-sm-5">
                                    <input type="text"  class="form-control" formControlName="eligibilitynumber" id="staticName" 
                                    [ngClass]="{ 'is-invalid': submitted && personal.eligibilitynumber.errors }" 
                                    >
                                             <div *ngIf="submitted && personal.eligibilitynumber.errors" class="invalid-feedback">
                                              <div *ngIf="personal.eligibilitynumber.errors.required">
                                                Eligibility Number Is Required.
                                              </div>                                            
                                            </div>
                                              
                                  </div>
                               </div>
                               
                              <div class="form-group row mb-3">
                                <label for="staticEmail" class="col-sm-2 col-form-label">Upload Xth Marksheet<span class="star">*</span></label>
                                <div class="col-sm-5">
                                    <input type="file"  class="form-control" formControlName="marksheet" (change)="onmarksheetSelect($event)" id="staticEmail"
                                    [ngClass]="{ 'is-invalid': submitted && personal.marksheet.errors }" 
                                    >
                                             <div *ngIf="submitted && personal.marksheet.errors" class="invalid-feedback">
                                              <div *ngIf="personal.marksheet.errors.required">
                                                Xth Marksheet Is Required.
                                              </div>                                            
                                            </div>
                                    <div *ngIf="marksinfo == null; else showmarks">
                                      <!-- <a href="{{photoinfo}}" class="btn btn-success" >view</a> -->
                                      <!-- <a href="{{photoinfo}}" class="btn btn-success" target="_blank" >view</a> -->
                                    </div>
                                    <ng-template #showmarks>
                                      <a href="{{marksinfo}}" class="btn btn-success  " download>Download Uploaded Marksheet</a>
                                      <!-- <a href="{{marksinfo}}" class="btn btn-success btn-sm photobtn" target="_blank">view</a> -->
                                      <!-- <button type="button" class="btn btn-success" data-toggle="modal" data-target="#marksheetModal">
                                        View
                                      </button> -->
                                       <!-- Modal -->
                                       <!-- <div class="modal fade" data-backdrop="static" id="marksheetModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div class="modal-dialog" role="document">
                                          <div class="modal-content">
                                             <div class="modal-header">
                                              <h5 class="modal-title" id="exampleModalLabel">Uploaded Marksheet</h5> -->
                                              <!--<button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                              </button> -->
                                            <!-- </div>
                                            <div class="modal-body mx-auto">
                                                <img [src]="marksinfo">
                                            </div>
                                            <div class="modal-footer">
                                              <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
                                            </div>
                                          </div>
                                        </div>
                                      </div> -->
                                      <!-- modal end -->
                                    </ng-template>  
                                </div>
                                <!-- <input style="color: blue; border: none; font-size: smaller;" class="form-control" formControlName="marksheet" name="marksheet"  type="text" value=""  > -->
                                
                              </div>
                            </div>
                            <div *ngIf="checkstream !='Vocational' else alrdystream">
                              <div class="form-group row mb-3" >
                      
                        
                                <label for="staticname" class="col-sm-2 col-form-label">Stream</label>
                                <div class="col-sm-5">
                                    <select id="stream" formControlName="stream" class="form-select" #t (change)="streamChanged(t.value)" [attr.disabled] ="true">
                                      <option hidden value="" disabled selected>Select Stream</option>
                                      <option value="Science">Science</option>
                                      <option value="Commerce">Commerce</option>
                                      <option value="Arts">Arts</option>
                                        <option value="Vocational">Vocational</option>
                                      </select>
                                </div>
                          </div>
                           <div *ngIf="t.value == 'Vocational'" >
                          <div class="form-group row mb-3" >
                              <label for="staticname" class="col-sm-2 col-form-label">Course</label>
                                <div class="col-sm-5">
                                  <!-- <input type="text" hidden class="form-control" formControlName="course" id="staticName"  [(ngModel)]="course1" > -->
                                  <select id="course" formControlName="course"  class="form-select" >
                                    <option *ngFor="let course of coursesall" [value]="course.coursecode" >{{course.subject_name}}</option>
                                  </select>
                               
                                </div>
                           </div>
                           
                        </div>    
                      </div>
                      
                        <ng-template #alrdystream>
                          <div class="form-group row mb-3">
                      
                        
                            <label for="staticname" class="col-sm-2 col-form-label">Stream</label>
                            <div class="col-sm-5">
                              <input type="text"  class="form-control" formControlName="stream" id="staticName"  readonly>
                            </div>
                          </div>
                          <div class="form-group row mb-3">
                          <label for="staticname" class="col-sm-2 col-form-label">Course</label>
                                      <div class="col-sm-5">
                                        <input type="text"  class="form-control" formControlName="course" id="staticName" readonly >
                                        
                                      </div>
                       
                    </div>
                        </ng-template>

                        <div *ngIf="othercourse == 'Other' || othercourse == undefined  else othersubject">
                          <div class="form-group row mb-3">
                            <label for="subject_one" class="col-sm-2 col-form-label">Subject 1<span class="star">*</span></label>
                            <div class="col-sm-5">
                                <select id="subject_one" formControlName="subject_one"  class="form-select" (change)="selected($event)" [ngClass]="{ 'is-invalid': submitted && personal.subject_one.errors }" >
                                  <option hidden value="" disabled selected>Select Subject</option>
                                    <option *ngFor="let sub of subject" [value]="sub.subjectname" >{{sub.subjectname}}</option>
                                    <!-- <option [value]="sub.position">Marathi</option> -->
                                  </select>
                                  <div *ngIf="submitted && personal.subject_one.errors" class="invalid-feedback">
                                    <div *ngIf="personal.subject_one.errors.required">
                                      Subject Is Required.
                                    </div>
                                  </div> 
                            </div>
                          </div>
                          <div class="form-group row mb-3">
                            <label for="subject_two" class="col-sm-2 col-form-label">Subject 2<span class="star">*</span></label>
                            <div class="col-sm-5">
                                <select id="subject_two" formControlName="subject_two" class="form-select" (change)="selectedsubjtwo($event)"  [ngClass]="{ 'is-invalid': submitted && personal.subject_two.errors }">
                                  <option hidden value="" disabled selected>Select Subject</option>
                                    <option *ngFor="let sub1 of subject1" [value]="sub1.subjectname">{{sub1.subjectname}}</option>
                                    <!-- <option value="Marathi">Marathi</option> -->
                                  </select>
                                  <div *ngIf="submitted && personal.subject_two.errors" class="invalid-feedback">
                                    <div *ngIf="personal.subject_two.errors.required">
                                      Subject Is Required.
                                    </div>
                                  </div> 
                            </div>
                          </div>
                          <div class="form-group row mb-3">
                            <label for="subject_three" class="col-sm-2 col-form-label">Subject 3<span class="star">*</span></label>
                            <div class="col-sm-5">
                                <select id="subject_three" formControlName="subject_three" class="form-select" (change)="selectedsubjthree($event)" [ngClass]="{ 'is-invalid': submitted && personal.subject_three.errors }">
                                    <option hidden value="" disabled selected>Select Subject</option>
                                    <option *ngFor="let sub2 of subject2" [value]="sub2.subjectname">{{sub2.subjectname}}</option>
                                    <!-- <option value="Marathi">Soc Sci</option> -->
                                  </select>
                                  <div *ngIf="submitted && personal.subject_three.errors" class="invalid-feedback">
                                    <div *ngIf="personal.subject_three.errors.required">
                                      Subject Is Required.
                                    </div>
                                  </div> 
                            </div>
                          </div>
                          <div class="form-group row mb-3">
                            <label for="subject_four" class="col-sm-2 col-form-label">Subject 4<span class="star">*</span></label>
                            <div class="col-sm-5">
                                <select id="subject_four" formControlName="subject_four" class="form-select" (change)="selectedsubjfour($event)" [ngClass]="{ 'is-invalid': submitted && personal.subject_four.errors }" >
                                    <option hidden value="" disabled selected>Select Subject</option>
                                    <option *ngFor="let sub3 of subject3" [value]="sub3.subjectname">{{sub3.subjectname}}</option>
                                    <!-- <option value="Marathi">Marathi</option> -->
                                  </select>
                                  <div *ngIf="submitted && personal.subject_four.errors" class="invalid-feedback">
                                    <div *ngIf="personal.subject_four.errors.required">
                                      Subject Is Required.
                                    </div>
                                  </div> 
                            </div>
                          </div>
                          <div class="form-group row mb-3">
                            <label for="subject_five" class="col-sm-2 col-form-label">Subject 5<span class="star">*</span></label>
                            <div class="col-sm-5">
                                <select id="subject_five" formControlName="subject_five" class="form-select" (change)="selectedsubjfive($event)" [ngClass]="{ 'is-invalid': submitted && personal.subject_five.errors }" >
                                    <option hidden value="" disabled selected>Select Subject</option>
                                    <option *ngFor="let sub4 of subject4" [value]="sub4.subjectname">{{sub4.subjectname}}</option>
                                    <!-- <option value="Marathi">Marathi</option> -->
                                  </select>
                                  <div *ngIf="submitted && personal.subject_five.errors" class="invalid-feedback">
                                    <div *ngIf="personal.subject_five.errors.required">
                                      Subject Is Required.
                                    </div>
                                  </div> 
                            </div>
                          </div>
                          <div class="form-group row mb-3">
                            <label for="subject_six" class="col-sm-2 col-form-label">Subject 6<span class="star">*</span></label>
                            <div class="col-sm-5">
                                <select id="subject_six" formControlName="subject_six" class="form-select" (change)="selectedsubjsix($event)" [ngClass]="{ 'is-invalid': submitted && personal.subject_six.errors }" >
                                  <option hidden value="" disabled selected>Select Subject</option>
                                    <option *ngFor="let sub5 of subject5" [value]="sub5.subjectname">{{sub5.subjectname}}</option>
                                    <!-- <option value="Marathi">Marathi</option> -->
                                  </select>
                                  <div *ngIf="submitted && personal.subject_six.errors" class="invalid-feedback">
                                    <div *ngIf="personal.subject_six.errors.required">
                                      Subject Is Required.
                                    </div>
                                  </div> 
                            </div>
                          </div>
                        </div>
                        <ng-template #othersubject>

                          <div class="form-group row mb-3">
                            <label for="subject_one" class="col-sm-2 col-form-label">Subject 1<span class="star">*</span></label>
                            <div class="col-sm-5">
                                <select id="subject_one" formControlName="subject_one"  class="form-select" [(ngModel)]="course1" (change)="selected($event)" [attr.disabled]="true" >
                                    <option hidden value="" disabled selected>Select Subject</option>
                                    <option  [value]="course1" >{{course1}}</option>
                                    <!-- <option [value]="sub.position">Marathi</option> -->
                                  </select>
                                  <!-- <div *ngIf="submitted && personal.subject_one.errors" class="invalid-feedback">
                                    <div *ngIf="personal.subject_one.errors.required">
                                      Subject Is Required.
                                    </div>
                                  </div>  -->
                            </div>
                          </div>
                          <div class="form-group row mb-3">
                            <label for="subject_two" class="col-sm-2 col-form-label">Subject 2<span class="star">*</span></label>
                            <div class="col-sm-5">
                                <select id="subject_two" formControlName="subject_two" class="form-select" [(ngModel)]="course2" (change)="selectedsubjtwo($event)" [attr.disabled]="true"  >
                                    <option hidden value="" disabled selected>Select Subject</option>
                                    <option [value]="course2" >{{course2}}</option>
                                    <!-- <option value="Marathi">Marathi</option> -->
                                  </select>
                                  <!-- <div *ngIf="submitted && personal.subject_two.errors" class="invalid-feedback">
                                    <div *ngIf="personal.subject_two.errors.required">
                                      Subject Is Required.
                                    </div>
                                  </div>  -->
                            </div>
                          </div>
                          <div class="form-group row mb-3">
                            <label for="subject_three" class="col-sm-2 col-form-label">Subject 3<span class="star">*</span></label>
                            <div class="col-sm-5">
                                <select id="subject_three" formControlName="subject_three" class="form-select" (change)="selectedsubjthree($event)" [ngClass]="{ 'is-invalid': submitted && personal.subject_three.errors }" >
                                    <option hidden value="" disabled selected>Select Subject</option>
                                    <option  [value]="course3">{{course3}}</option>
                                    <!-- <option value="Marathi">Soc Sci</option> -->
                                  </select>
                                  <div *ngIf="submitted && personal.subject_three.errors" class="invalid-feedback">
                                    <div *ngIf="personal.subject_three.errors.required">
                                      Subject Is Required.
                                    </div>
                                  </div> 
                            </div>
                          </div>
                          <div class="form-group row mb-3">
                            <label for="subject_four" class="col-sm-2 col-form-label">Subject 4<span class="star">*</span></label>
                            <div class="col-sm-5">
                                <select id="subject_four" formControlName="subject_four" class="form-select" (change)="selectedsubjfour($event)" [ngClass]="{ 'is-invalid': submitted && personal.subject_four.errors }" >
                                    <option hidden value="" disabled selected>Select Subject</option>
                                    <option  [value]="course4">{{course4}}</option>
                                    <!-- <option value="Marathi">Marathi</option> -->
                                  </select>
                                  <div *ngIf="submitted && personal.subject_four.errors" class="invalid-feedback">
                                    <div *ngIf="personal.subject_four.errors.required">
                                      Subject Is Required.
                                    </div>
                                  </div> 
                            </div>
                          </div>
                          <div class="form-group row mb-3">
                            <label for="subject_five" class="col-sm-2 col-form-label">Subject 5<span class="star">*</span></label>
                            <div class="col-sm-5">
                                <select id="subject_five" formControlName="subject_five" class="form-select" (change)="selectedsubjfive($event)" [ngClass]="{ 'is-invalid': submitted && personal.subject_five.errors }" >
                                    <option hidden value="" disabled selected>Select Subject</option>
                                    <option  [value]="course5">{{course5}}</option>
                                    <!-- <option value="Marathi">Marathi</option> -->
                                  </select>
                                  <div *ngIf="submitted && personal.subject_five.errors" class="invalid-feedback">
                                    <div *ngIf="personal.subject_five.errors.required">
                                      Subject Is Required.
                                    </div>
                                  </div> 
                            </div>
                          </div>
                          <div class="form-group row mb-3">
                            <label for="subject_six" class="col-sm-2 col-form-label">Subject 6<span class="star">*</span></label>
                            <div class="col-sm-5">
                                <select id="subject_six" formControlName="subject_six" class="form-select" (change)="selectedsubjsix($event)" [ngClass]="{ 'is-invalid': submitted && personal.subject_six.errors }"  >
                                  <option hidden value="" disabled selected>Select Subject</option>
                                  <option  [value]="course6">{{course6}}</option>
                                    <!-- <option value="Marathi">Marathi</option> -->
                                  </select>
                                  <div *ngIf="submitted && personal.subject_six.errors" class="invalid-feedback">
                                    <div *ngIf="personal.subject_six.errors.required">
                                      Subject Is Required.
                                    </div>
                                  </div> 
                            </div>
                          </div>
                          

                        </ng-template>

                        
                        <div class="form-group row mb-3">
                          <label for="subject_seven" class="col-sm-2 col-form-label">Subject 7</label>
                          <div class="col-sm-5">
                              <select id="subject_seven" formControlName="subject_seven" class="form-select" [ngClass]="{ 'is-invalid': submitted && personal.subject_seven.errors }" >
                                <option  value=""  selected>Select Subject</option>
                                  <option *ngFor="let sub6 of subject6" [value]="sub6.subjectname">{{sub6.subjectname}}</option>
                                  <!-- <option value="Marathi">Marathi</option> -->
                                </select>
                                <div *ngIf="submitted && personal.subject_seven.errors" class="invalid-feedback">
                                  <div *ngIf="personal.subject_seven.errors.required">
                                    Subject Is Required.
                                  </div>
                                </div> 
                          </div>
                        </div>
                    
                    <div class="form-group row mb-3">
                      <label for="subject_eight" class="col-sm-2 col-form-label">Subject 8<span class="star">*</span></label>
                      <div class="col-sm-5">
                          <select id="subject_eight" formControlName="subject_eight" class="form-select" [ngClass]="{ 'is-invalid': submitted && personal.subject_eight.errors }" >
                            <option hidden value="" disabled selected>Select Subject</option>
                              <option *ngFor="let sub7 of subject7" [value]="sub7.subjectname">{{sub7.subjectname}}</option>
                              <!-- <option value="Marathi">Marathi</option> -->
                            </select>
                            <div *ngIf="submitted && personal.subject_eight.errors" class="invalid-feedback">
                              <div *ngIf="personal.subject_eight.errors.required">
                                Subject Is Required.
                              </div>
                            </div> 
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label for="subject_nine" class="col-sm-2 col-form-label">Subject 9<span class="star">*</span></label>
                      <div class="col-sm-5">
                          <select id="subject_nine" formControlName="subject_nine" class="form-select" [ngClass]="{ 'is-invalid': submitted && personal.subject_nine.errors }" >
                            <option hidden value="" disabled selected>Select Subject</option>
                              <option *ngFor="let sub8 of subject8" [value]="sub8.subjectname">{{sub8.subjectname}}</option>
                              <!-- <option value="Marathi">Marathi</option> -->
                            </select>
                            <div *ngIf="submitted && personal.subject_nine.errors" class="invalid-feedback">
                              <div *ngIf="personal.subject_nine.errors.required">
                                Subject Is Required.
                              </div>
                            </div> 
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label for="subject_ten" class="col-sm-2 col-form-label">Subject 10<span class="star">*</span></label>
                      <div class="col-sm-5">
                          <select id="subject_ten" formControlName="subject_ten" class="form-select" [ngClass]="{ 'is-invalid': submitted && personal.subject_ten.errors }" >
                            <option hidden value="" disabled selected>Select Subject</option>
                              <option *ngFor="let sub9 of subject9" [value]="sub9.subjectname">{{sub9.subjectname}}</option>
                              <!-- <option value="Marathi">Marathi</option> -->
                            </select>
                            <div *ngIf="submitted && personal.subject_ten.errors" class="invalid-feedback">
                              <div *ngIf="personal.subject_ten.errors.required">
                                Subject Is Required.
                              </div>
                            </div> 
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label for="subject_eleven" class="col-sm-2 col-form-label">Subject 11<span class="star">*</span></label>
                      <div class="col-sm-5">
                          <select id="subject_eleven" formControlName="subject_eleven" class="form-select" [ngClass]="{ 'is-invalid': submitted && personal.subject_eleven.errors }" >
                            <option hidden value="" disabled selected>Select Subject</option>
                              <option *ngFor="let sub10 of subject10" [value]="sub10.subjectname">{{sub10.subjectname}}</option>
                              <!-- <option value="Marathi">Marathi</option> -->
                            </select>
                            <div *ngIf="submitted && personal.subject_eleven.errors" class="invalid-feedback">
                              <div *ngIf="personal.subject_eleven.errors.required">
                                Subject Is Required.
                              </div>
                            </div> 
                      </div>
                    </div>
                    <figcaption class="blockquote" style="color: #0062cc;">
                      <cite title="Source Title"><b>- Upload Photo & Signature </b></cite>
                   </figcaption> <hr>
                   <div class="form-group row mb-3">
                    <label for="staticEmail" class="col-sm-2 col-form-label">Photo<span class="star">*</span></label>
                   <div class="col-sm-5">
                       <input type="file" accept="image/png, image/jpeg" class="form-control" id="photo" (change)="onPhotoSelect($event)" [ngClass]="{ 'is-invalid': submitted && personal.photo.errors }" >
                       <div *ngIf="submitted && personal.photo.errors" class="invalid-feedback">
                        <div *ngIf="personal.photo.errors.required">
                          Photo Is Required.
                        </div>
                        <!-- <div style="display: block;color: green;" *ngIf="photo_upload_success" class="invalid-feedback">
                          Uploaded successfully
                        </div> -->
                      </div> 
                      <!-- <input style="color: blue; border: none; font-size: smaller;"  class="form-control"  formControlName="photo" name="photo"  type="text" value="" [ngClass]="{ 'is-invalid': submitted && personal.photo.errors }" > -->
                      <div *ngIf="photoinfo == null; else showpic">
                        <!-- <a href="{{photoinfo}}" class="btn btn-success" >view</a> -->
                        <!-- <a href="{{photoinfo}}" class="btn btn-success" target="_blank" >view</a> -->
                      </div>
                      <ng-template #showpic>
                        <!-- <a href="{{photoinfo}}" class="btn btn-success btn-sm photobtn" target="_blank">view</a> -->
                        <button type="button" class="btn btn-success mt-3" data-toggle="modal" data-target="#photoModal">
                          View Uploaded Photo
                        </button>
                         <!-- Modal -->
                         <div class="modal fade" data-backdrop="static" id="photoModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                          <div class="modal-dialog" role="document">
                            <div class="modal-content">
                               <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Uploaded Photo</h5>
                                <!--<button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                  <span aria-hidden="true">&times;</span>
                                </button> -->
                              </div>
                              <div class="modal-body mx-auto viewimgmodalbody">
                                  <img [src]="photoinfo">
                              </div>
                              <div class="modal-footer">
                                <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
                              </div>
                            </div>
                          </div>
                        </div>
								<!-- Modal End-->
                      </ng-template>

                    </div>
                   <!-- <div class="col-sm-4">
                      <input type="button" class="btnSubmit btn btn-primary"  value="Upload" (click)="onPhotoSubmit()" /> 
                    </div> -->
                    
                 </div>
                 <div class="form-group row mb-3">
                  <label for="staticEmail" class="col-sm-2 col-form-label">Signature<span class="star">*</span></label>
                 <div class="col-sm-5">
                     <input type="file" accept="image/png, image/jpeg" class="form-control"  id="signature" (change)="onsignatureSelect($event)" [ngClass]="{ 'is-invalid': submitted && personal.signature.errors }">
                     <div *ngIf="submitted && personal.signature.errors" class="invalid-feedback">
                      <div *ngIf="personal.signature.errors.required">
                        Signature Is Required.
                      </div>
                      <!-- <div style="display: block;color: green;" *ngIf="signature_upload_success" class="invalid-feedback">
                        Uploaded successfully
                      </div> -->
                    </div> 
                    <!-- <input style="color: blue; border: none; font-size: smaller;" class="form-control" formControlName="signature" name="signature"  type="text" value="" [ngClass]="{ 'is-invalid': submitted && personal.signature.errors }" > -->
                    <div *ngIf="signinfo == null; else showsign">
                      <!-- <a href="{{photoinfo}}" class="btn btn-success" >view</a> -->
                      <!-- <a href="{{photoinfo}}" class="btn btn-success" target="_blank" >view</a> -->
                    </div>
                    <ng-template #showsign>
                      <!-- <a href="{{signinfo}}" class="btn btn-success btn-sm photobtn" target="_blank">view</a> -->
                      <button type="button" class="btn btn-success mt-3" data-toggle="modal" data-target="#signModal">
                        View Uploaded Signature
                      </button>
                       <!-- Modal -->
                       <div class="modal fade" data-backdrop="static" id="signModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                          <div class="modal-content">
                             <div class="modal-header">
                              <h5 class="modal-title" id="exampleModalLabel">Uploaded Signature</h5>
                              <!--<button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button> -->
                            </div>
                            <div class="modal-body  mx-auto viewimgmodalbody">
                                <img [src]="signinfo">
                            </div>
                            <div class="modal-footer">
                              <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                </div>
                <!-- <div class="col-sm-4">
                  <input type="button" class="btnSubmit btn btn-primary"  value="Upload" (click)="onSignatureSubmit()" /> 
                </div> -->
                 </div>
                 
                      <figcaption class="blockquote" style="color: #0062cc;">
                        <cite title="Source Title"><b>-  Security Details</b></cite>
                     </figcaption> <hr>
                     <div class="form-group row mb-3">
                      <label for="staticgender" class="col-sm-2 col-form-label">Security Question<span class="star">*</span></label>
                      <div class="col-sm-5">
                          <select id="inputgender" formControlName="security_ques" class="form-select" [ngClass]="{ 'is-invalid': submitted && personal.security_ques.errors }">
                            <option hidden value="" disabled selected>Select Security Question</option>
                              <option value="What is the name of your first school">What is the name of your first school?</option>
                              <option value="What is your neighbour's pet name">What is your neighbour's pet name ?</option>
                              <option value="Which is Your Favourite book">Which is Your Favourite book?</option>
                            </select>
                            
                         <div *ngIf="submitted && personal.security_ques.errors" class="invalid-feedback">
                          <div *ngIf="personal.security_ques.errors.required">
                            Security Question Is Required.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label for="staticEmail" class="col-sm-2 col-form-label">Security Answer<span class="star">*</span></label>
                      <div class="col-sm-5">
                          <input type="text" formControlName="security_ans" class="form-control" id="security_ans" [ngClass]="{ 'is-invalid': submitted && personal.security_ans.errors }">
                         
                          <div *ngIf="submitted && personal.security_ans.errors" class="invalid-feedback">
                            <div *ngIf="personal.security_ans.errors.required">
                              Security Answer Is Required.
                            </div>
                          </div>
                          
                       </div>
                    </div>
                     <div class="form-group row mb-3">
                        <label for="staticEmail" class="col-sm-2 col-form-label">Security Pin</label>
                        <span class="col-sm-3 captcha" id="captcha" style="background-image:url('/assets/img/brand/dot.jpg');background-repeat: no-repeat;width:'200px'">{{captcha}} </span>
                        <span class="col-sm-2 mobref"> <a (click)="refresh()"> <img src="/assets/img/brand/refresh.png" height="50px" width="50px"></a></span>
                       
                     </div>
                     <div class="form-group row mb-3">
                        <label for="staticEmail" class="col-sm-2 col-form-label">Enter Security Pin<span class="star">*</span></label>
                       <div class="col-sm-5">
                           <input type="text" formControlName="captcha_text" class="form-control" id="captcha_text" [ngClass]="{ 'is-invalid': submitted && personal.captcha_text.errors }">
                           <div *ngIf="submitted && personal.captcha_text.errors" class="invalid-feedback">
                            <div *ngIf="personal.captcha_text.errors.required">
                              Cpatcha Is Required.
                            </div>
                          </div> 
                           
                        </div>
                     </div>
                      
                     <div class="form-group row mb-3">
                      <!-- <label for="staticEmail" class="col-sm-2 col-form-label"></label> -->
                        <input type="button" class="col-sm-2 btnSubmit btnSubmit1 btn btn-success" value="Save Changes" (click)="onsavedata()" /> &nbsp;
                     
                        <input type="button" class="col-sm-2 btnSubmit btnSubmit1 btn btn-primary"  value="Submit" (click)="onstudentprofilesubmit()" [disabled] = 'isdisable'/>  &nbsp;
                        <input type="button" class="col-sm-2 btnSubmit btnSubmit1 btn btn-secondary" value="Cancel"  [routerLink]="['/landing']"/>&nbsp;
                     </div>

                       <br>
              
          </form>
        </div>       
      </div>
    </div>
    <div class="row mb-3"></div>
</div>