import { Component, OnInit, ViewChild } from '@angular/core';
import { SchoolserviceService } from '../schoolservice.service';
import { NavbarService } from '../default/navbar/navbar.service';
import { Table } from 'primeng/table';
import { PrimeNGConfig } from 'primeng/api';
import { schools } from './schools';
@Component({
  selector: 'app-view-unregistred-schools',
  templateUrl: './view-unregistred-schools.component.html',
  styleUrls: ['./view-unregistred-schools.component.scss']
})
export class ViewUnregistredSchoolsComponent implements OnInit {
  role_type: any;
  loading: boolean = true;
  schooldetails!: schools[];
  infoschool !: schools[];
  currStandard9th!:any;
  currStandard11th!:any;
  std:String="9th";
  
  selectedSchooldetails!: schools[];
  selectedeleSchooldetails!: schools[];
  @ViewChild('dt')
  table!: Table;
  constructor(private primengConfig: PrimeNGConfig,public service:SchoolserviceService,public nav:NavbarService) { }

  ngOnInit(): void {
    this.role_type =  localStorage.getItem('role_type');
    if(this.role_type == 'boardadmin')
    {
      this.nav.hide();
      this.nav.dhide();
      this.nav.adshow();
      this.nav.aduserhide();
      this.nav.asdhide();
      this.nav.studhide();
      this.nav.XIstudhide();
    }
    else
    {
      this.nav.hide();
      this.nav.dhide();
      this.nav.adhide();
      this.nav.adusershow();
      this.nav.asdhide();
      this.nav.studhide();
      this.nav.XIstudhide();
    }
    this.primengConfig.ripple = true;
    this.getAllSchoolData();
  }
  getStd(std:any){
    //console.log(std);
    this.std=std;
    this.getAllSchoolData();
  }
  getEventValue($event:any) :string {
  return $event.target.value;
  }
  
  getAllSchoolData(){
    let schoolId: { } = {
     
    }
    // if(this.std=='9th'){
     // alert("9th");
    this.service.post('/BoardApi/get9thUnregisteredSchools',schoolId).subscribe((allSchool: any) => {
        //console.log("9th");
        //console.log(allSchool.Msg);
       this.schooldetails=allSchool['9th'];
       this.infoschool=allSchool['11th'];
       this.loading = false;
    })
  // }
  // if(this.std=='11th'){
  //   //alert("11th");
  //   this.service.post('/BoardApi/get9thUnregisteredSchools',schoolId).subscribe((all11thSchool: any) => {
  //     // //console.log("11th");
  //     //console.log(all11thSchool);
     
  //    console.log(this.infoschool);
  //    this.loading = false;
  // })
  //}
  }

}
