import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SchoolserviceService } from '../schoolservice.service';
import { DatePipe } from '@angular/common';
import { NavbarService } from '../default/navbar/navbar.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-viewcandidateprofile',
  templateUrl: './viewcandidateprofile.component.html',
  styleUrls: ['./viewcandidateprofile.component.scss'],
  providers: [DatePipe]
})
export class ViewcandidateprofileComponent implements OnInit {

  studID:any;
  userData: any;
  studentprofileform: FormGroup;
  imgstr = Image;
  imgsignaturestr = Image;
  imgphotostr = Image;
  isdisabled : boolean = true;
  
  subject:any;
  subject1:any;
  subject2:any;
  subject3:any;
  subject4:any;
  subject5:any;
  subject6:any;
  studschool: any;
  subjectmap: any;
  streamvalue: any;
  boardvalue: any;
  status: any;
  checkstream: any;
  subject7: any;
  subject8: any;
  subject9: any;
  subject10: any;
  coursesall:any;
  coursecode: any;
  coursename: any;
  coursesubj: any;
  subjcode: any;
  course1:any;
  course2:any;
  course3: any;
  course4: any;
  course6: any;
  course5: any;
  othercourse: any;
  subject11: any;
  subject12: any;

  constructor(public formbuilder:FormBuilder,public router:Router, public service:SchoolserviceService,public datepipe: DatePipe,public nav:NavbarService,private sanitizer: DomSanitizer) 
  { 
    this.studentprofileform = formbuilder.group({
      class:[''],
      rollno:[''],
      division:[''],
      stream:['',Validators.required],
      course:[''],
      boardname:[''],
      stname:['',Validators.required],
      ftname:['',Validators.required],
      mtname:['',Validators.required],
      dob:['',Validators.required],
      gender:['',Validators.required],
      email:['',Validators.required],
      mobile_no:['',Validators.required],
      alt_mobile_no:[''],
      aadhar:[''],
      caste:[''],
      street:['',Validators.required],
      locality:['',Validators.required],
      city:['',Validators.required],
      state:['',Validators.required],
      taluka:['',Validators.required],
      district:['',Validators.required],
      landmark:[''],
      pincode:['',Validators.required],
      house_ownership:['',Validators.required], 
      internet_coverage:['',Validators.required],
      distance_coverage:['',Validators.required],
      access_to_smart_phone:['',Validators.required],
      access_internet:['',Validators.required],
      distance_of_school_to_house:[''],
      mode_of_transport:[''],
      otherboardname:[''],
      eligibilitynumber:[''],
      marksheet:[''],
      security_ques:['',Validators.required],
      security_ans:['',Validators.required],
      fathers_qualification:['',Validators.required],
      fathers_job:['',Validators.required],
      fathers_annual_income:['',Validators.required],
      mothers_qualification:['',Validators.required],
      mothers_job:['',Validators.required],
      mothers_annual_income:['',Validators.required],
      photo:['',Validators.required],
      signature:['',Validators.required],
      subject_one:['',Validators.required],
      subject_two:['',Validators.required],
      subject_three:['',Validators.required],
      subject_four:['',Validators.required],
      subject_five:['',Validators.required],
      subject_six:['',Validators.required],
      subject_seven:['',Validators.required],
      subject_eight:['',Validators.required],
      subject_nine:['',Validators.required],
      subject_ten:['',Validators.required],
      subject_eleven:['',Validators.required],
      captcha_text:['',Validators.required],
      seat:[''],
      exam:['']

      })
  }

  ngOnInit(): void {

    // this.router.onSameUrlNavigation = 'reload'; 
    // this.status = localStorage.getItem("submission_status");
    this.studID = localStorage.getItem('studentid');
  //  //console.log(this.status);
    
    // let user:{studentid:any}={
    //   studentid:this.studID
    // }
    // let seq = this.service.post('/StudentApi11/getstudentclassForm',user);
    //  seq.subscribe((res:any) => {
            
    //         this.studschool= res['studschool'];
    //         this.status = this.studschool[0].submission_status;
    //         //console.log(this.status);
    //         if(this.status != "New" && this.status != "Pending")
    //       {
    //         // this.router.onSameUrlNavigation = 'reload'; 
    //         this.router.navigate(['/viewcandidateprofile']);
    //         // this.router.onSameUrlNavigation = 'reload'; 


    //       }
         
             
    //  });

     this.nav.hide();
     this.nav.dhide();
     this.nav.adhide();
     this.nav.asdhide();
     this.nav.studhide();
     this.nav.XIstudshow();
     this.nav.aduserhide();
             
    this.studentprofileform.controls.class.setValue('11th');
    // //console.log(this.studID);
  if(this.studID)
  {
    this.getstudentform();
    this.getstudentclassForm();
    this.getselectedsubject();
  }
  this.getsubjects();
  }

  // select stream
  streamChanged(value: any)
  {
    this.streamvalue = value;
    //console.log(this.streamvalue);
  }

  // select board

  boardChanged(value: any)
  {
    this.boardvalue = value;
    //console.log(this.boardvalue);
  }

  getstudentform()
  {
    this.router.onSameUrlNavigation = 'reload'; 
           
    let user:{studentid:any}={
      studentid:this.studID
    }
    let seq = this.service.post('/StudentApi11/getstudentForm',user);
     seq.subscribe((res:any) => {
            // this.isdisable= true;
             this.userData= res['Data'];
            //  //console.log(this.userData[0].security_ques);
            this.studentprofileform.controls['stname'].setValue(this.userData[0].stname);
            this.studentprofileform.controls['ftname'].setValue(this.userData[0].ftname);
            this.studentprofileform.controls['mtname'].setValue(this.userData[0].mtname);
            this.studentprofileform.controls['dob'].setValue(this.userData[0].dob);
            this.studentprofileform.controls['gender'].setValue(this.userData[0].gender);
            this.studentprofileform.controls['email'].setValue(this.userData[0].email);
            this.studentprofileform.controls['mobile_no'].setValue(this.userData[0].mobile_no);
            this.studentprofileform.controls['alt_mobile_no'].setValue(this.userData[0].alt_mobile_no);
            this.studentprofileform.controls['aadhar'].setValue(this.userData[0].aadhar);
            this.studentprofileform.controls['caste'].setValue(this.userData[0].caste);
            this.studentprofileform.controls['street'].setValue(this.userData[0].street);
            this.studentprofileform.controls['locality'].setValue(this.userData[0].locality);
            this.studentprofileform.controls['city'].setValue(this.userData[0].city);
            this.studentprofileform.controls['state'].setValue(this.userData[0].state);
            this.studentprofileform.controls['taluka'].setValue(this.userData[0].taluka);
            this.studentprofileform.controls['district'].setValue(this.userData[0].district);
            this.studentprofileform.controls['landmark'].setValue(this.userData[0].landmark);
            this.studentprofileform.controls['pincode'].setValue(this.userData[0].pincode);
            this.studentprofileform.controls['house_ownership'].setValue(this.userData[0].house_ownership);
            this.studentprofileform.controls['internet_coverage'].setValue(this.userData[0].internet_coverage);
            this.studentprofileform.controls['distance_coverage'].setValue(this.userData[0].distance_coverage);
            this.studentprofileform.controls['access_to_smart_phone'].setValue(this.userData[0].access_to_smart_phone);
            this.studentprofileform.controls['access_internet'].setValue(this.userData[0].access_internet);
            this.studentprofileform.controls['distance_of_school_to_house'].setValue(this.userData[0].distance_of_school_to_house);
            this.studentprofileform.controls['mode_of_transport'].setValue(this.userData[0].mode_of_transport);
            this.studentprofileform.controls['security_ques'].setValue(this.userData[0].security_ques);
            this.studentprofileform.controls['security_ans'].setValue(this.userData[0].security_ans);
            this.studentprofileform.controls['fathers_qualification'].setValue(this.userData[0].fathers_qualification);
            this.studentprofileform.controls['fathers_job'].setValue(this.userData[0].fathers_job);
            this.studentprofileform.controls['fathers_annual_income'].setValue(this.userData[0].fathers_annual_income);
            this.studentprofileform.controls['mothers_qualification'].setValue(this.userData[0].mothers_qualification);
            this.studentprofileform.controls['mothers_job'].setValue(this.userData[0].mothers_job);
            this.studentprofileform.controls['mothers_annual_income'].setValue(this.userData[0].mothers_annual_income);
            this.studentprofileform.controls['boardname'].setValue(this.userData[0].boardname);
            this.studentprofileform.controls['otherboardname'].setValue(this.userData[0].otherboardname);
            this.studentprofileform.controls['eligibilitynumber'].setValue(this.userData[0].eligibilitynumber);
            this.studentprofileform.controls['seat'].setValue(this.userData[0].seat);
            this.studentprofileform.controls['exam'].setValue(this.userData[0].exam);
          
            //  var photo_upload_path=this.userData[0].photo;
            // //  photo_upload_path =photo_upload_path.substr(42,photo_upload_path.length);
            // this.photo =   this.studentprofileform.controls['photo'].setValue(photo_upload_path); 
            //  var signature_upload_path=this.userData[0].signature;
            //  //  signature_upload_path =signature_upload_path.substr(42,signature_upload_path.length);
            //  this.studentprofileform.controls['signature'].setValue(signature_upload_path);
            this.imgphotostr=this.userData[0].photo;
            this.imgsignaturestr=this.userData[0].signature;
            this.imgstr=this.userData[0].marksheet;
        //     let objectURL =  this.userData[0].photo;

      //   var marksheet_upload_path=this.userData[0].marksheet;
     
      // this.studentprofileform.controls['marksheet'].setValue(marksheet_upload_path);
     });
  }

    // get all selected subject from student

    getstudentclassForm()
    {
      let user:{studentid:any}={
        studentid:this.studID
      }
      let seq = this.service.post('/StudentApi11/getstudentclassForm',user);
       seq.subscribe((res:any) => {
              
              this.studschool= res['studschool'];
               this.studentprofileform.controls['rollno'].setValue(this.studschool[0].rollno);
              this.studentprofileform.controls['division'].setValue(this.studschool[0].division);
              if(this.studschool[0].stream == 'Vocational' || this.studschool[0].stream == 'VOCATIONAL' || this.studschool[0].stream == 'vocational'){
                this.studentprofileform.controls['stream'].setValue('Vocational');
               }
               else
               if(this.studschool[0].stream == 'Science' || this.studschool[0].stream == 'SCIENCE' || this.studschool[0].stream == 'science'){
                this.studentprofileform.controls['stream'].setValue('Science');
               }
               else
               if(this.studschool[0].stream == 'Commerce' || this.studschool[0].stream == 'COMMERCE' || this.studschool[0].stream == 'commerce'){
                this.studentprofileform.controls['stream'].setValue('Commerce');
               }
               else
               if(this.studschool[0].stream == 'Arts' || this.studschool[0].stream == 'ARTS' || this.studschool[0].stream == 'arts'){
                this.studentprofileform.controls['stream'].setValue('Arts');
               }
              this.checkstream =  this.studentprofileform.controls['stream'].value;
              //console.log(this.checkstream);
              this.coursecode = this.studschool[0].course;
            // this service for coursename
           this.service.post('/StudentApi11/getCoursebycode', {coursecode:this.coursecode}).subscribe((res:any)=>{
             this.coursename = res['Data'];
             //console.log(this.coursename)
             this.othercourse = this.coursename[0].subject_name;
             //console.log(this.othercourse);
             this.studentprofileform.controls['course'].setValue(this.coursename[0].subject_name);
           });

           this.service.post('/StudentApi11/getSubjectByCoursecode',{coursecode:this.coursecode}).subscribe((res:any) =>{
             this.coursesubj = res['Data'];
             this.subjcode = res['status'];
             this.course1 = this.coursesubj[0].subjectname;
             this.course2 = this.coursesubj[1].subjectname;
             this.course3 = this.coursesubj[2].subjectname;
             this.course4 = this.coursesubj[3].subjectname;
             this.course5 = this.coursesubj[4].subjectname;
             this.course6 = this.coursesubj[5].subjectname;
            //  //console.log(this.subjcode);
           })
       });
    }
  
    getselectedsubject()
    {
      let user:{studentid:any}={
        studentid:this.studID
      }
        let seq = this.service.post('/StudentApi11/getstudentselectedsubject',user);
        seq.subscribe((res:any)=>{
          this.subjectmap = res['subjectmap'];
              this.studentprofileform.controls['subject_one'].setValue(this.subjectmap[0].Subject);
               this.studentprofileform.controls['subject_two'].setValue(this.subjectmap[1].Subject);
               this.studentprofileform.controls['subject_three'].setValue(this.subjectmap[2].Subject);
               this.studentprofileform.controls['subject_four'].setValue(this.subjectmap[3].Subject);
               this.studentprofileform.controls['subject_five'].setValue(this.subjectmap[4].Subject);
               this.studentprofileform.controls['subject_six'].setValue(this.subjectmap[5].Subject);
               this.studentprofileform.controls['subject_seven'].setValue(this.subjectmap[6].Subject);
               this.studentprofileform.controls['subject_eight'].setValue(this.subjectmap[7].Subject);
               this.studentprofileform.controls['subject_nine'].setValue(this.subjectmap[8].Subject);
               this.studentprofileform.controls['subject_ten'].setValue(this.subjectmap[9].Subject);
               this.studentprofileform.controls['subject_eleven'].setValue(this.subjectmap[10].Subject);
        })
    }
  
    // get subjects in dropdown
  
    getsubjects(){
      let user:{}={
        
      }
      let seq = this.service.post('/StudentApi11/getsubjectlist',user);
      seq.subscribe((res:any) => {
        this.subject = res['Data'];
        this.subject1 =res['Data1'];
        this.subject2 =res['Data2'];
        this.subject3 =res['Data3'];
        this.subject4 =res['Data4'];
        this.subject5 =res['Data5'];
        this.subject6 =res['Data6'];
        this.subject7 =res['Data7'];
        this.subject8 =res['Data8'];
        this.subject9 =res['Data9'];
        this.subject10 =res['Data10'];
        this.subject11 =res['Data11'];
        this.subject12 =res['Data12'];
        // //console.log(this.subject);
  
   });
    }

}
