<div class="container login-container bg-light mb-3">
    <br>
    <div class="card border-dark mb-3">
        <!-- <h5 class="card-header">Student Profile</h5> -->
        <h5 class="card-header pl-2">View Candidate Application <i [routerLink]="['/Allcandidates']" class="bi bi-x-square-fill btnClose"></i></h5>
        <div class="card-body text-dark ">
          <figcaption class="blockquote" style="color: #0062cc;">
              <cite title="Source Title"><b>- Personal Information</b></cite>
           </figcaption> <hr>
           <form role="form" [formGroup]= "studentprofileform"> 
               <div  class="justify-content-md-center">
                   <!-- <div class="form-group row mb-3">
                       <div class="col-sm-2">
                          <img src= "{{this.imgstr}}" alt="{{this.imgstr}}" >
                       </div>
                   </div> -->
                   <div class="form-group row mb-3">
                      
                    <label for="staticname" class="col-sm-2 col-form-label">Rollno</label>
                    <div class="col-sm-2">
                      <input type="text" formControlName="rollno" readonly class="form-control" id="staticName"  readonly>
                    </div>
                   
                    <label for="staticname" class="col-sm-1 col-form-label">Division</label>
                    <div class="col-sm-2">
                      <input type="text"  class="form-control" readonly formControlName="division" id="staticName"  value="A" readonly>
                      
                    </div>
                    
                  </div>
                  
                  <div class="form-group row mb-3">
                      
                      <label for="staticname" class="col-sm-2 col-form-label">Candidate's Name</label>
                      <div class="col-sm-5">
                        <input type="text" formControlName="stname" class="form-control" id="staticName" readonly>
                       
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label for="staticfathername" class="col-sm-2 col-form-label">Father's Name</label>
                      <div class="col-sm-5">
                        <input type="text" formControlName="ftname" class="form-control" id="inputfathername" readonly>
                         
                     
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label for="staticmothername" class="col-sm-2 col-form-label">Mother's Name</label>
                      <div class="col-sm-5">
                        <input type="text" formControlName="mtname" class="form-control" id="inputmothername" readonly>
                         
               
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label for="staticdob" class="col-sm-2 col-form-label">Date Of Birth</label>
                      <div class="col-sm-5">
                        <input type="text" formControlName="dob" class="form-control" id="inputdob" readonly>
                        
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label for="staticgender" class="col-sm-2 col-form-label">Gender</label>
                      <div class="col-sm-5">
                          <select id="inputgender" formControlName="gender" class="form-select" [attr.disabled]="true" >
                              <option selected>Select Gender</option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                              <option value="Transe_gender">Transe Gender</option>
                            </select>
                       
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label for="staticEmail" class="col-sm-2 col-form-label">Email</label>
                      <div class="col-sm-5">
                        <input type="text" formControlName="email" class="form-control" id="staticEmail" readonly>
                           
                        </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label for="staticEmail" class="col-sm-2 col-form-label"> Mobile Number</label>
                      <div class="col-sm-5">
                        <input type="text" formControlName="mobile_no" class="form-control" id="staticEmail" readonly>
                         
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label for="staticEmail" class="col-sm-2 col-form-label">Alternate Contact Number</label>
                      <div class="col-sm-5">
                        <input type="text" formControlName="alt_mobile_no" class="form-control" id="staticEmail" readonly>
                      
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label for="staticEmail" class="col-sm-2 col-form-label">AADHAR Number</label>
                      <div class="col-sm-5">
                        <input type="text" formControlName="aadhar" class="form-control" id="staticEmail" readonly>
                      
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label for="staticcaste" class="col-sm-2 col-form-label">Caste</label>
                      <div class="col-sm-5">
                        <select id="inputcaste" formControlName="caste" class="form-select"  [attr.disabled]="true">
                          <option hidden value="" disabled selected>Select Caste</option>
                          <option value="General">General</option>
                          <option value="OBC">OBC</option>
                          <option value="SC">SC</option>
                          <option value="ST">ST</option>
                          <!-- <option value="East_Indians">East Indians</option>
                          <option value="Goan_Catholics">Goan Catholics</option>
                          <option value="Karwari_Catholics">Karwari Catholics</option>
                          <option value="Koli_Christians">Koli Christians</option>
                          <option value="Mangalorean_Catholics">Mangalorean Catholics</option> -->
                        </select>
                        
                        
                      </div>
                    </div>
                    <figcaption class="blockquote" style="color: #0062cc;">
                      <cite title="Source Title"><b>- Address Information</b></cite>
                   </figcaption> <hr>
                   <div class="form-group row mb-3">
                      <label for="staticEmail" class="col-sm-2 col-form-label">Street</label>
                      <div class="col-sm-5">
                        <input type="text" formControlName="street" class="form-control" id="staticEmail" readonly>
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label for="staticEmail" class="col-sm-2 col-form-label">Locality</label>
                      <div class="col-sm-5">
                        <input type="text" formControlName="locality" class="form-control" id="staticEmail" readonly>
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label for="staticEmail" class="col-sm-2 col-form-label">City/Town/Village</label>
                      <div class="col-sm-5">
                        <input type="text" formControlName="city" class="form-control" id="staticEmail" readonly>
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label for="staticEmail" class="col-sm-2 col-form-label">State</label>
                      <div class="col-sm-5">
                        <input type="text" formControlName="state" class="form-control" id="staticEmail" readonly>
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label for="staticEmail" class="col-sm-2 col-form-label">Taluka</label>
                      <div class="col-sm-3">
                        <input type="text" formControlName="taluka" class="form-control" id="staticEmail" readonly>
                      </div>
                      <label for="staticEmail" class="col-sm-1 col-form-label">District</label>
                      <div class="col-sm-3">
                        <input type="text" formControlName="district" class="form-control" id="staticEmail" readonly>
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label for="staticEmail" class="col-sm-2 col-form-label">Landmark</label>
                      <div class="col-sm-3">
                        <input type="text" formControlName="landmark" class="form-control" id="staticEmail" readonly>
                      </div>
                      <label for="staticEmail" class="col-sm-1 col-form-label">Pincode</label>
                      <div class="col-sm-3">
                        <input type="text" formControlName="pincode" class="form-control" id="staticEmail" readonly >
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label for="staticEmail" class="col-sm-2 col-form-label">House OwnerShip</label>
                      <div class="col-sm-3" style="margin-top: 9px;">
                          <input class="form-check-input col-sm-3" type="radio" [attr.disabled]="true" formControlName="house_ownership" name="house_ownership" id="gridRadios1" value="Own" > Own
                      </div>
                      <div class="col-sm-3" style="margin-top: 9px;">
                          <input class="form-check-input col-sm-" type="radio" [attr.disabled]="true" formControlName="house_ownership" name="house_ownership" id="gridRadios2" value="Rent"    > Rent
                    
                      </div>
                   </div>
                    <div class="hidden form-group row mb-3">
                      <label for="staticEmail" class="col-sm-2 col-form-label">Internet Coverage</label>
                      <div class="col-sm-3" style="margin-top: 9px;">
                          <input class="form-check-input col-sm-3" type="radio" [attr.disabled]="true" formControlName="internet_coverage" name="internet_coverage" id="gridRadios1" value="Yes"    > Yes
                      </div>
                      <div class="col-sm-3" style="margin-top: 9px;">
                          <input class="form-check-input col-sm-" type="radio" [attr.disabled]="true" formControlName="internet_coverage" name="internet_coverage" id="gridRadios2" value="No"    > No
                    
                      </div>
                   </div>
                   <div class="form-group row mb-3">
                      <label for="staticEmail" class="col-sm-2 col-form-label">Distance To Coverage</label>
                      <div class="col-sm-5">
                          <input type="text" class="form-control" [attr.disabled]="true" formControlName="distance_coverage" id="staticEmail" readonly>
                       </div>
                      
                   </div>
                   <div class="form-group row mb-3"  >
                    <label for="staticname" class="col-sm-2 col-form-label">Distance of School from House (in Kms)</label>
                      <div class="col-sm-5">
                        <input type="text"  class="form-control" [attr.disabled]="true" formControlName="distance_of_school_to_house" id="staticName"  readonly>
                                  
                      </div>
                   </div>
                   <div class="form-group row mb-3"  >
                    <label for="staticname" class="col-sm-2 col-form-label">Mode of Transport</label>
                    <div class="col-sm-5">
                      <select id="inputgender" formControlName="mode_of_transport" class="form-select" [attr.disabled]="true" >
                        <option selected disabled>Select Mode Of Transport</option>
                        <option value="Walking">Walking</option>
                        <option value="Private_Vehicle">Private Vehicle</option>
                        <option value="Auto_Rikshaw">Auto Rickshaw</option>
                        <option value="Public_Transport">Public Transport</option>
                      </select>
                    </div>
                   
                   </div>
                    <div class="form-group row mb-3">
                      <label for="staticEmail" class="col-sm-2 col-form-label">Access to Smart Phone</label>
                      <div class="col-sm-3" style="margin-top: 9px;">
                          <input class="form-check-input col-sm-3" type="radio" [attr.disabled]="true" formControlName="access_to_smart_phone" name="access_smartphone" id="gridRadios1" value="Yes"   > Yes
                      </div>
                      <div class="col-sm-3" style="margin-top: 9px;">
                          <input class="form-check-input col-sm-3" type="radio" [attr.disabled]="true" formControlName="access_to_smart_phone" name="access_smartphone" id="gridRadios2" value="No"   > No
                    
                      </div>
                   </div>
                   <div class="form-group row mb-3">
                      <label for="staticEmail" class="col-sm-2 col-form-label">Access to Internet</label>
                      <div class="col-sm-3" style="margin-top: 9px;">
                          <input class="form-check-input col-sm-3" type="radio" [attr.disabled]="true" formControlName="access_internet" name="access_internet" id="gridRadios1" value="Yes"   > Yes
                      </div>
                      <div class="col-sm-3" style="margin-top: 9px;">
                          <input class="form-check-input col-sm-3" type="radio" [attr.disabled]="true" formControlName="access_internet" name="access_internet" id="gridRadios2" value="No"   > No
                    
                      </div>
                   </div>
                   <figcaption class="blockquote" style="color: #0062cc;">
                      <cite title="Source Title"><b>- Other Information</b></cite>
                   </figcaption> <hr>

                   <div class="form-group row mb-3">
                      <label for="staticEmail" class="col-sm-2 col-form-label">Father's Qualification</label>
                      <div class="col-sm-5">
                          <input type="text" class="form-control" formControlName="fathers_qualification" id="staticEmail" readonly>
                       </div>
                      
                    </div>
                    <div class="form-group row mb-3">
                       <label for="staticEmail" class="col-sm-2 col-form-label">Father's Job</label>
                      <div class="col-sm-5">
                          <input type="text" class="form-control" formControlName="fathers_job" id="staticEmail" readonly>
                       </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label for="staticEmail" class="col-sm-2 col-form-label">Father's Annual Income</label>
                      <div class="col-sm-5">
                          <input type="text" class="form-control" formControlName="fathers_annual_income" id="staticEmail" readonly>
                       </div>
                      
                    </div>
                    <div class="form-group row mb-3">
                       <label for="staticEmail" class="col-sm-2 col-form-label">Mother's Qualification</label>
                      <div class="col-sm-5">
                          <input type="text" class="form-control" formControlName="mothers_qualification" id="staticEmail" readonly>
                       </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label for="staticEmail" class="col-sm-2 col-form-label">Mother's Job</label>
                      <div class="col-sm-5">
                          <input type="text" class="form-control" formControlName="mothers_job" id="staticEmail" readonly>
                       </div>
                      
                    </div>
                    <div class="form-group row mb-3">
                       <label for="staticEmail" class="col-sm-2 col-form-label">Mother's Annual Income</label>
                      <div class="col-sm-5">
                          <input type="text" class="form-control" formControlName="mothers_annual_income" id="staticEmail" readonly>
                       </div>
                    </div>
                     <figcaption class="blockquote" style="color: #0062cc;">
                      <cite title="Source Title"><b>- Academic Information </b></cite>
                   </figcaption> <hr>
                   <div class="form-group row mb-3">
                                
                    <label for="staticname" class="col-sm-2 col-form-label">Board Name</label>
                        <div class="col-sm-5">
                          <select id="inputgender" formControlName="boardname" class="form-select" #b (change)="boardChanged(b.value)" [attr.disabled]="true">
                                <option selected>Select Stream</option>
                                <option value="Goa_Board">Goa Board</option>
                                <option value="other">Other</option>
                          </select>
                        </div>
                    </div>
                        <!-- <div class="form-group row mb-3" *ngIf="b.value == 'Goa_Board'" >
                          <label for="staticname" class="col-sm-2 col-form-label">SSC Exam Year</label>
                            <div class="col-sm-3">
                              <input type="text"  class="form-control" formControlName="exam" id="staticName"  >
                                        
                            </div>
                            <label for="staticname" class="col-sm-1 col-form-label">Seat</label>
                            <div class="col-sm-3">
                              <input type="text"  class="form-control" formControlName="seat" id="staticName"  >
                                        
                            </div>
                            <input type="button" class="btnSubmit btn btn-primary"  value="Submit" />
                        </div> -->
                        <div *ngIf="b.value == 'other'">
                            <div class="form-group row mb-3"  >
                            <label for="staticname" class="col-sm-2 col-form-label">Other Board</label>
                              <div class="col-sm-5">
                                <input type="text"  class="form-control" formControlName="otherboardname" id="staticName" [attr.disabled]="true" >
                                          
                              </div>
                           </div>
                           <div class="form-group row mb-3"  >
                            <label for="staticname" class="col-sm-2 col-form-label">Provisional/ Final Eligibility Number</label>
                              <div class="col-sm-5">
                                <input type="text"  class="form-control" formControlName="eligibilitynumber" id="staticName"  [attr.disabled]="true" >
                                          
                              </div>
                           </div>
                           
                          <div class="form-group row mb-3">
                            <label for="staticEmail" class="col-sm-2 col-form-label">Upload Marksheet</label>
                            <div class="col-sm-5">
                            <a href="{{this.imgstr}}" class="btnSubmit btn btn-primary">view</a>
                            </div>
                            <!-- <input style="color: blue; border: none; font-size: smaller;" class="form-control" formControlName="marksheet" name="marksheet"  type="button" value="view"  > -->
                           
                          </div>
                        </div>
                        <div class="form-group row mb-3" *ngIf="checkstream !='Vocational' else alrdystream">
                      
                        
                          <label for="staticname" class="col-sm-2 col-form-label">Stream</label>
                          <div class="col-sm-5">
                              <select id="stream" formControlName="stream" class="form-select" #t (change)="streamChanged(t.value)" [attr.disabled]="true">
                                  <option selected>Select Stream</option>
                                  <option value="Science">Science</option>
                                  <option value="Commerce">Commerce</option>
                                  <option value="Arts">Arts</option>
                                  <option value="Vocational">Vocational</option>
                                </select>
                          </div>
                     
                      <div class="form-group row mb-3" *ngIf="t.value == 'Vocational'" >
                        <label for="staticname" class="col-sm-2 col-form-label">Course</label>
                                    <div class="col-sm-5">
                                      <input type="text"  class="form-control" formControlName="course" id="staticName"  >
                                      
                                    </div>
                      </div>
                  </div>
                  <ng-template #alrdystream>
                    <div class="form-group row mb-3">
                
                  
                      <label for="staticname" class="col-sm-2 col-form-label">Stream</label>
                      <div class="col-sm-5">
                        <input type="text"  class="form-control" formControlName="stream" id="staticName"  readonly>
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                    <label for="staticname" class="col-sm-2 col-form-label">Course</label>
                                <div class="col-sm-5">
                                  <input type="text"  class="form-control" formControlName="coursename" id="staticName" readonly >
                                  
                                </div>
                 
              </div>
                  </ng-template>
                <div class="form-group row mb-3">
                  <label for="photo" class="col-sm-2 col-form-label">Photo</label>
                  <div class="col-sm-3">
                    
                    <a href="{{this.imgphotostr}}" class="btnSubmit btn btn-primary">view</a>
                        
                  </div>
                  <label for="signature" class="col-sm-2 col-form-label">signature</label>
                  <div class="col-sm-3">
                    
                    <a href="{{this.imgsignaturestr}}" class="btnSubmit btn btn-primary">view</a>
                        
                  </div>
                </div>
                <div *ngIf="othercourse =='Other' || othercourse == undefined; else othersubject">
                  <div class="form-group row mb-3">
                    <label for="subject_one" class="col-sm-2 col-form-label">Subject 1</label>
                    <div class="col-sm-5">
                      
                          <select id="subject_one" formControlName="subject_one"  class="form-select" name="subject_one"  [attr.disabled]="true" >
                            <option selected>Select </option>
                            <option *ngFor="let sub of subject" [value]="sub.subjectname" >{{sub.subjectname}}</option>
                            <!-- <option [value]="sub.position">Marathi</option> -->
                          </select>
                          
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <label for="subject_two" class="col-sm-2 col-form-label">Subject 2</label>
                    <div class="col-sm-5">
                        <select id="subject_two" formControlName="subject_two" class="form-select" [attr.disabled]="true" >
                            <option selected>Select </option>
                            <option *ngFor="let sub1 of subject1" [value]="sub1.subjectname">{{sub1.subjectname}}</option>
                            <!-- <option value="Marathi">Marathi</option> -->
                          </select>
                          
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <label for="subject_three" class="col-sm-2 col-form-label">Subject 3</label>
                    <div class="col-sm-5">
                        <select id="subject_three" formControlName="subject_three" class="form-select" [attr.disabled]="true" >
                            <option selected>Select </option>
                            <option *ngFor="let sub2 of subject2" [value]="sub2.subjectname">{{sub2.subjectname}}</option>
                            <!-- <option value="Marathi">Soc Sci</option> -->
                          </select>
                          
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <label for="subject_four" class="col-sm-2 col-form-label">Subject 4</label>
                    <div class="col-sm-5">
                        <select id="subject_four" formControlName="subject_four" class="form-select" [attr.disabled]="true"  >
                            <option selected>Select </option>
                            <option *ngFor="let sub3 of subject3" [value]="sub3.subjectname">{{sub3.subjectname}}</option>
                            <!-- <option value="Marathi">Marathi</option> -->
                          </select>
                          
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <label for="subject_five" class="col-sm-2 col-form-label">Subject 5</label>
                    <div class="col-sm-5">
                        <select id="subject_five" formControlName="subject_five" class="form-select" [attr.disabled]="true"  >
                            <option selected>Select </option>
                            <option *ngFor="let sub4 of subject4" [value]="sub4.subjectname">{{sub4.subjectname}}</option>
                            <!-- <option value="Marathi">Marathi</option> -->
                          </select>
                          
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <label for="subject_six" class="col-sm-2 col-form-label">Subject 6</label>
                    <div class="col-sm-5">
                        <select id="subject_six" formControlName="subject_six" class="form-select" [attr.disabled]="true"  >
                            <option selected value ="select">Select </option>
                            <option *ngFor="let sub5 of subject5" [value]="sub5.subjectname">{{sub5.subjectname}}</option>
                            <!-- <option value="Marathi">Marathi</option> -->
                          </select>
                         
                    </div>
                  </div>
                </div>
                <ng-template #othersubject>

                  <div class="form-group row mb-3">
                    <label for="subject_one" class="col-sm-2 col-form-label">Subject 1<span class="star">*</span></label>
                    <div class="col-sm-5">
                        <select id="subject_one" formControlName="subject_one"  class="form-select" [(ngModel)]="course1" [attr.disabled]="true" >
                            <!-- <option hidden value="" disabled selected>Select Subject</option> -->
                            <option  [value]="course1" >{{course1}}</option>
                            <!-- <option [value]="sub.position">Marathi</option> -->
                          </select>
                           
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <label for="subject_two" class="col-sm-2 col-form-label">Subject 2<span class="star">*</span></label>
                    <div class="col-sm-5">
                        <select id="subject_two" formControlName="subject_two" class="form-select" [(ngModel)]="course2" [attr.disabled]="true" >
                            <!-- <option hidden value="" disabled selected>Select Subject</option> -->
                            <option [value]="course2" >{{course2}}</option>
                            <!-- <option value="Marathi">Marathi</option> -->
                          </select>
                         
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <label for="subject_three" class="col-sm-2 col-form-label">Subject 3<span class="star">*</span></label>
                    <div class="col-sm-5">
                        <select id="subject_three" formControlName="subject_three" class="form-select" [attr.disabled]="true" >
                            <option hidden value="" disabled selected>Select Subject</option>
                            <option  [value]="course3">{{course3}}</option>
                            <!-- <option value="Marathi">Soc Sci</option> -->
                          </select>
                          
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <label for="subject_four" class="col-sm-2 col-form-label">Subject 4<span class="star">*</span></label>
                    <div class="col-sm-5">
                        <select id="subject_four" formControlName="subject_four" class="form-select" [attr.disabled]="true" >
                            <option hidden value="" disabled selected>Select Subject</option>
                            <option  [value]="course4">{{course4}}</option>
                            <!-- <option value="Marathi">Marathi</option> -->
                          </select>
                          
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <label for="subject_five" class="col-sm-2 col-form-label">Subject 5<span class="star">*</span></label>
                    <div class="col-sm-5">
                        <select id="subject_five" formControlName="subject_five" class="form-select" [attr.disabled]="true" >
                            <option hidden value="" disabled selected>Select Subject</option>
                            <option  [value]="course5">{{course5}}</option>
                            <!-- <option value="Marathi">Marathi</option> -->
                          </select>
                          
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <label for="subject_six" class="col-sm-2 col-form-label">Subject 6<span class="star">*</span></label>
                    <div class="col-sm-5">
                        <select id="subject_six" formControlName="subject_six" class="form-select" [attr.disabled]="true" >
                          <option hidden value="" disabled selected>Select Subject</option>
                          <option  [value]="course6">{{course6}}</option>
                            <!-- <option value="Marathi">Marathi</option> -->
                          </select>
                         
                    </div>
                  </div>
                  

                </ng-template>
                  
                <div class="form-group row mb-3">
                  <label for="subject_seven" class="col-sm-2 col-form-label">Subject 7</label>
                  <div class="col-sm-5">
                      <select id="subject_seven" formControlName="subject_seven" class="form-select" [attr.disabled]="true"  >
                          <option selected value ="select">Select </option>
                          <option *ngFor="let sub6 of subject6" [value]="sub6.subjectname">{{sub6.subjectname}}</option>
                          <!-- <option value="Marathi">Marathi</option> -->
                        </select>
                       
                  </div>
                </div>
                <div class="form-group row mb-3">
                  <label for="subject_eight" class="col-sm-2 col-form-label">Subject 8</label>
                  <div class="col-sm-5">
                      <select id="subject_eight" formControlName="subject_eight" class="form-select" [attr.disabled]="true"  >
                          <option selected value ="select">Select </option>
                          <option *ngFor="let sub7 of subject7" [value]="sub7.subjectname">{{sub7.subjectname}}</option>
                          <!-- <option value="Marathi">Marathi</option> -->
                        </select>
                       
                  </div>
              </div>
              <div class="form-group row mb-3">
                <label for="subject_nine" class="col-sm-2 col-form-label">Subject 9</label>
                <div class="col-sm-5">
                    <select id="subject_nine" formControlName="subject_nine" class="form-select" [attr.disabled]="true"  >
                        <option selected value ="select">Select </option>
                        <option *ngFor="let sub8 of subject8" [value]="sub8.subjectname">{{sub8.subjectname}}</option>
                        <!-- <option value="Marathi">Marathi</option> -->
                      </select>
                     
                </div>
            </div>
            <div class="form-group row mb-3">
              <label for="subject_ten" class="col-sm-2 col-form-label">Subject 10</label>
              <div class="col-sm-5">
                  <select id="subject_ten" formControlName="subject_ten" class="form-select" [attr.disabled]="true"  >
                      <option selected value ="select">Select </option>
                      <option *ngFor="let sub9 of subject9" [value]="sub9.subjectname">{{sub9.subjectname}}</option>
                      <!-- <option value="Marathi">Marathi</option> -->
                    </select>
                   
              </div>
          </div>
          <div class="form-group row mb-3">
            <label for="subject_eleven" class="col-sm-2 col-form-label">Subject 11</label>
            <div class="col-sm-5">
                <select id="subject_eleven" formControlName="subject_eleven" class="form-select" [attr.disabled]="true"  >
                    <option selected value ="select">Select </option>
                    <option *ngFor="let sub10 of subject10" [value]="sub10.subjectname">{{sub10.subjectname}}</option>                      <!-- <option value="Marathi">Marathi</option> -->
                  </select>
                 
            </div>
        </div>
                    <!-- <figcaption class="blockquote" style="color: #0062cc;">
                      <cite title="Source Title"><b>- Upload Documents </b></cite>
                   </figcaption> <hr>
                   <div class="form-group row mb-3">
                    <label for="staticEmail" class="col-sm-2 col-form-label">Photo</label>
                   <div class="col-sm-5">
                       <input type="file" accept="image/png, image/jpeg" class="form-control" id="photo"  >
                       
                      <input style="color: blue; border: none; font-size: smaller;"  class="form-control"  formControlName="photo" name="photo"  type="text" value="" >
                   </div>
                    
                 </div>
                 <div class="form-group row mb-3">
                  <label for="staticEmail" class="col-sm-2 col-form-label">Signature</label>
                 <div class="col-sm-5">
                     <input type="file" accept="image/png, image/jpeg" class="form-control"  id="signature"  >
                      
                    <input style="color: blue; border: none; font-size: smaller;" class="form-control" formControlName="signature" name="signature"  type="text" value="" >
                   
                </div>
                
                 </div> -->
               
                    <figcaption class="blockquote" style="color: #0062cc;">
                      <cite title="Source Title"><b>-  Security Details</b></cite>
                   </figcaption> <hr>
                   <div class="form-group row mb-3">
                    <label for="staticgender" class="col-sm-2 col-form-label">Security Question</label>
                    <div class="col-sm-5">
                        <select id="inputgender" formControlName="security_ques" class="form-select" [attr.disabled]="true" >
                            <option selected>Select </option>
                            <option value="What is the name of your first school">What is the name of your first school?</option>
                            <option value="What is your neighbour's pet name">What is your neighbour's pet name ?</option>
                            <option value="Which is Your Favourite book">Which is Your Favourite book?</option>
                          </select>
                          
                      
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <label for="staticEmail" class="col-sm-2 col-form-label">Security Answer</label>
                    <div class="col-sm-5">
                        <input type="text" formControlName="security_ans" class="form-control" id="security_ans" readonly>
                       
                     </div>
                  </div>
                   
               </div>      
           </form> 
      </div>
      </div>
      <div class="row mb-3"></div>
  </div>