import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavbarService } from '../default/navbar/navbar.service';
import { SchoolserviceService } from '../schoolservice.service';
@Component({
  selector: 'app-payment-landing',
  templateUrl: './payment-landing.component.html',
  styleUrls: ['./payment-landing.component.scss']
})
export class PaymentLandingComponent implements OnInit {

  getdata!: any[];
  rzp1:any;
  order_id: any;
  payment_id: any;
  schoolid: any;
  result: any;
  signature: any;
  amount: any;
  studentid: any;
  loading: boolean =false;
  

  // getstudents: any;
  constructor(public nav:NavbarService,private _routeParams: ActivatedRoute,  public service:SchoolserviceService) { }
 
  ngOnInit(): void {
    
    this.schoolid = localStorage.getItem('schoolid');
    this.nav.hide();
    this.nav.dhide();
    this.nav.adhide();
    this.nav.asdshow();
    this.nav.studhide();
    this.nav.XIstudhide();
    this.nav.aduserhide();
    
  }
  

  checkTicked:boolean=true;
  funChecked(e:any){
    if(e.target.checked){
      this.checkTicked=false;
    }
    else{
      this.checkTicked=true;
    }
  }
  Pay(){

    this.studentid = "";
    this.amount = 100;
    let genpara ={
     "reciept": this.order_id = "recp"+Math.floor(Math.random() * (999999 - 100000)) + 100000+"_"+this.schoolid,
     "amount":this.amount
    }
    let seq = this.service.post('School/generateOrderid',genpara);
    seq.subscribe((res:any)=>{
        this.result = res['Data'];
        alert(this.result);
        //console.log(this.order_id);
        const options:any  = {
          "key": "rzp_test_oDw757e5auoleh", 
          "amount": this.amount, 
          "currency": "INR",
          "name": "GBSHSE",
          "description": "Test Transaction",
          "image": "https://example.com/your_logo",
          "order_id":  this.result, 
          "theme": {
              "color": "blue"
          }
      };
      options.handler = ((response: any, error: any) => {
        options.response = response.razorpay_payment_id;
        // //console.log(response);
        // //console.log(options.response);
        this.payment_id = response.razorpay_payment_id;
        
            if( this.payment_id){
              let params={
                "orderid" : response.razorpay_order_id,
                "paymentid":response.razorpay_payment_id
              }
              let seq1 = this.service.post('School/verifyRazorpaysig',params);
              seq1.subscribe((res:any)=>{
                  this.signature = res['Data'];
                  // alert(this.signature);
                  if(this.signature == response.razorpay_signature){
                    let para ={
                      "studentid": this.studentid,
                      "schoolid":this.schoolid,
                      "payment_id":this.payment_id,
                      "payment_status":"completely paid"
                     }
                       let seq = this.service.post('School/updatePaymentStatus',para);
                       seq.subscribe((res:any)=>{
                       this.result = res['Msg'];
                        alert(this.result);
          
                       },err=>{
                        alert("Error");
                     
                      })
                     }
                },err=>{
                  alert("Error");
                     
                })
          
          
        }
        // call your backend api to verify payment signature & capture transaction
      });
        this.rzp1 = new this.nav.nativeWindow.Razorpay(options);
        this.rzp1.open();
    },err=>{
      alert("Error");
         
    })  
  }
}
