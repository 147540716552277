import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SchoolserviceService } from '../schoolservice.service';
import { DatePipe } from '@angular/common';
import { NavbarService } from '../default/navbar/navbar.service';

@Component({
  selector: 'app-exceptional-dates-for-school',
  templateUrl: './exceptional-dates-for-school.component.html',
  styleUrls: ['./exceptional-dates-for-school.component.scss']
})
export class ExceptionalDatesForSchoolComponent implements OnInit {
  expDateResetform: FormGroup;
  submitted = false;
  expDate!: String;
  schoolName:string="";
  scode:string="";
  schoolMob:String="";
  schoolAdd:String="";
  newExpDate:String="";
  
  role_type: any;
  exceptionaldate: any;
  constructor(public formbuilder:FormBuilder,public router:Router, public service:SchoolserviceService,public datepipe: DatePipe,public nav:NavbarService) { 
    this.expDateResetform = formbuilder.group({      
      schoolCode:['',Validators.required],
      schoolName:[''],
      schoolMob:[''],
      schoolAdd:[''],
      newExpDate:['', Validators.required]
    })
  }

  ngOnInit(): void {
    this.role_type =  localStorage.getItem('role_type');
    if(this.role_type == 'boardadmin')
    {
      this.nav.hide();
      this.nav.dhide();
      this.nav.adshow();
      this.nav.aduserhide();
      this.nav.asdhide();
      this.nav.studhide();
      this.nav.XIstudhide();
    }
    else
    {
      this.nav.hide();
      this.nav.dhide();
      this.nav.adhide();
      this.nav.adusershow();
      this.nav.asdhide();
      this.nav.studhide();
      this.nav.XIstudhide();
    }
    // this.getexceptionaldate();
  }
  get personal() { return this.expDateResetform.controls; }

  onsubmit(){
    this.submitted = true;
    if (this.expDateResetform.invalid) {      
      alert("Fill All Deatils");
      return;
    }
    this.setExpDateForSchool();     
  }
  //reset form
  resetExpDateResetform(){
  this.expDateResetform.reset();
  }

  setExpDateForSchool(){
    let expDate: {exceptionlastdate:any,scode:any} = {
      exceptionlastdate:this.datepipe.transform(this.expDateResetform.value.newExpDate, 'dd/MM/yyyy'),
      scode:this.expDateResetform.value.schoolCode
    }    
    this.service.post('/BoardApi/configureExceptionLastdate',expDate).subscribe((res: any) => {
      //console.log(res); 
      if(res.Data=="Exception date updated" && res.status=="Sucess"){
        alert(res.Data+" For School "+this.schoolName);
        window.location.reload();
      }
      else{
        alert(res.Data);
        this.submitted = false;
      }          
      }); 
  }
  //get school details from school id entered
  getSchoolDetails(){
    
    let schoolId: {scode:any } = {
      scode:this.expDateResetform.value.schoolCode
    }
    this.service.post('/BoardApi/getSchooldetailsByScode',schoolId).subscribe((allDetails: any) => {
      
      if(allDetails.status=='Success'){ 
        //console.log(allDetails);
      this.schoolName=allDetails['Data'][0].schoolname ;
      this.schoolMob=allDetails['Data'][0].mobileno1;
      this.schoolAdd=allDetails['Data'][0].locality+" "+allDetails['Data'][0].schooladdress;
      this.getexceptionaldate();
      }
      else{        
        this.schoolName="";
        this.scode="";
        this.schoolMob="";
        this.schoolAdd="";
        alert(allDetails.Data);  
        this.submitted = false;        
      }
  }); 
  }

  getexceptionaldate()
  {
    let schoolId: {schoolid:any } = {
      schoolid:this.expDateResetform.value.schoolCode
    }

    this.service.post('/School/viewexceptionlastdateforSchools',schoolId).subscribe((res: any) => {
      
      this.exceptionaldate = res['Data'];
        //console.log(this.exceptionaldate)
  }); 

  }
  
}
// district: "Pune"
// emailid: ""
// landlineno: ""
// landmark: ""
// locality: "Kothrud"
// management_type: "Privately Held"
// mobileno1: "9988551122"
// mobileno2: ""
// pincode: "411038"
// schooladdress: "Pune"
// schoolid: "1"
// schoolname: "MIT"
// state: "Maharashtra"
// status: "Logged out"
// street: "MIT road"
// taluka: "Bardez"
// town: "Pune"