import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { allNoti } from 'src/app/conf-notification/allNotiRemMsg';
import { SchoolserviceService } from 'src/app/schoolservice.service';
import { NavbarService } from './navbar.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  @ViewChild('content') content: any;
  studID:any;
  userData: any;
  application_status: any;
  schoolid: any;
  studstatus:any;
  modalOption: NgbModalOptions = {};
  AllNoticArray!:allNoti[];
  target_audience!:String;
  
  constructor(public router:Router, public service:SchoolserviceService,public nav: NavbarService,private modalService: NgbModal) { 
    // this.getstudentform();
  }

  ngOnInit(): void {

    this.router.onSameUrlNavigation = 'reload';  
    // this.nav;
    this.studID = localStorage.getItem('studentid');
    this.schoolid = localStorage.getItem('schoolid');
    this.studstatus = localStorage.getItem('status');

    //console.log(this.studstatus);
  // if(this.studID)
  // {
  //   this.getstudentform();
  // }
  this.getstudentform();

  // let user:{studentid:any}={
  //   studentid:this.studID
  // }
  // let seq = this.service.post('/StudentApi9/getstudentForm',user);
  //  seq.subscribe((res:any) => {
  //   this.userData= res['Data'];
  //   this.application_status = this.userData[0].application_status;
  //   //console.log(this.application_status);
  //   // window.location.reload();
  //  });
    
  }


  getstudentform()
  {
    let user:{studentid:any}={
      studentid:this.studID
    }
    let seq = this.service.post('/StudentApi9/getstudentForm',user);
     seq.subscribe((res:any) => {
      this.userData= res['Data'];
      this.application_status = this.userData[0].application_status;
      //console.log(this.application_status);
      // window.location.reload();\
      // alert(this.studID + "=="+this.studstatus + "-----"+this.application_status);
    });
    
    
  }
  showAllMessages(){
    let noparam: {} = {
    }
    this.service.post('/BoardApi/viewMessages',noparam).subscribe((notiSchool: any) => {
      if(notiSchool['Data']==""){
        this.target_audience="No Messages to show";
        this.AllNoticArray=[];
      }
      else{
      //console.log(notiSchool); 
      this.AllNoticArray=notiSchool['Data']
      //console.log(this.AllNoticArray);   
      this.target_audience="All Messages";
      }
      })
  }
  shownotificationcandidate()
  {
    let noparam:{}={}
    this.service.post('/BoardApi/viewnotificationsforCandidate',noparam).subscribe((notiSchool: any) => {
      if(notiSchool['Data']==""){
        this.target_audience="No Notification to show";
        this.AllNoticArray=[];
      }
      else{
      //console.log(notiSchool); 
      this.AllNoticArray=notiSchool['Data']
      //console.log(this.AllNoticArray);   
      this.target_audience="Notification For Students" ;
      }
    })
  }
showallnotifications()
{
  let noparam:{}={}
  this.service.post('/BoardApi/viewnotificationsforSchool',noparam).subscribe((notiSchool: any) => {
    if(notiSchool['Data']==""){
      this.target_audience="No Notification to show";     
      this.AllNoticArray=[];     
    }
    else{
    //console.log(notiSchool); 
    this.AllNoticArray=notiSchool['Data']
    //console.log(this.AllNoticArray);
    this.target_audience="Notification For Schools";
    }
    })
}

  logout(){
    let c=confirm("Are You Sure to log off?");
    if(c==true){
      window.localStorage.clear();
      // window.location.reload();
      this.router.navigate(['/home']);
     }
  }

  boardlogout(){
    //console.log("Hit!")
    window.localStorage.clear();
    // window.location.reload();
    this.router.navigate(['/boardlogin']);
  }
}
