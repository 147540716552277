import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SchoolserviceService } from '../schoolservice.service';
import { DatePipe } from '@angular/common';
import { NavbarService } from '../default/navbar/navbar.service';
import { Table } from 'primeng/table';
import { PrimeNGConfig } from 'primeng/api';
import { Schools } from './schools';


@Component({
  selector: 'app-view-school-regi-details',
  templateUrl: './view-school-regi-details.component.html',
  styleUrls: ['./view-school-regi-details.component.scss'],
  providers: [DatePipe]
})
export class ViewSchoolRegiDetailsComponent implements OnInit {

  loading: boolean = true;
  schooldetails!: Schools[];

  selectedSchooldetails!: Schools[];
  @ViewChild('dt')
  table!: Table;
  role_type: any;


  constructor(public formbuilder:FormBuilder,public router:Router, public service:SchoolserviceService,public datepipe: DatePipe,public nav:NavbarService,private primengConfig: PrimeNGConfig) {

    
  }

  ngOnInit(): void {

    this.role_type =  localStorage.getItem('role_type');
    if(this.role_type == 'boardadmin')
    {
      this.nav.hide();
      this.nav.dhide();
      this.nav.adshow();
      this.nav.aduserhide();
      this.nav.asdhide();
      this.nav.studhide();
      this.nav.XIstudhide();
    }
    else
    {
      this.nav.hide();
      this.nav.dhide();
      this.nav.adhide();
      this.nav.adusershow();
      this.nav.asdhide();
      this.nav.studhide();
      this.nav.XIstudhide();
    }

// primng table
this.primengConfig.ripple = true;

// this.service.getschoolsLarge().then((schooldetails:any) => {
//   this.schooldetails = schooldetails;
//   this.loading = false;
// });

    this.getAllSchoolData();

    

  }
  
  getEventValue($event:any) :string {
  return $event.target.value;
  }
  
  getAllSchoolData(){
    let schoolId: {} = {
      
    }
    
    this.service.post('/BoardApi/viewAllRegisterandPendingStatus',schoolId).subscribe((allSchool: any) => {
        //console.log(allSchool);
       this.schooldetails=allSchool.Data;
       this.loading = false;
    })
    
  }
}
