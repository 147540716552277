import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { NavbarService } from '../default/navbar/navbar.service';
import { DataTableDirective } from 'angular-datatables';
import { Table } from 'primeng/table';
import { PrimeNGConfig } from 'primeng/api';
import { Candidate } from './candidate';
import { SchoolserviceService } from '../schoolservice.service';
import * as XLSX from 'xlsx';
import { Router } from '@angular/router';
@Component({
  selector: 'app-candidates-details',
  templateUrl: './candidates-details.component.html',
  styleUrls: ['./candidates-details.component.scss']
})
export class CandidatesDetailsComponent implements OnInit {

 // for table data
 students!: Candidate[];

 selectedCandidates!: Candidate[];
 schoolwisestudents: any;
 loading: boolean = true;
 // students:any;
 @ViewChild('dt')
 table!: Table;

 fileName= 'ExcelSheet.xlsx';
  schoolid: any;
  studentid: string | undefined;
  resetpswd: any;
  standard: any;
//select deselect on checkbox
checkAllRow(){
  
  
}
//on radio button select dropdown update
studentStatusList=['Select','Promoted','Studying','Detained','Left'];
registrationStatusList=['Select','New','Pending','Submitted','Finalized','On hold','Approved','Rejected'];
paymentStatusList=['Select','Completly Paid','Not paid'];
dropdownList: string[] = [];
btnUpdate:boolean=true;
updateList(e:any){
  
  if(e.target.value=='StudentStatus'){
    this.dropdownList =this.studentStatusList;
  }
  if(e.target.value=='RegistrationStatus'){
    this.dropdownList =this.registrationStatusList;
  }
  if(e.target.value=='PaymentStatus'){
    this.dropdownList =this.paymentStatusList;
  }
}
enableBtnUpdate(e:any){ 
  if(e.target.value=="Select"){
    this.btnUpdate=true;
   }
  else{
  this.btnUpdate=false;
  }
}
//json data and functions for datatable

title = 'Candidate Details';

constructor(public service:SchoolserviceService,public nav:NavbarService,public router:Router, private primengConfig: PrimeNGConfig) { }


ngOnInit() {
  this.schoolid = localStorage.getItem('schoolid');
  this.nav.hide();
  this.nav.dhide();
  this.nav.adhide();
  this.nav.asdshow();
  this.nav.studhide();
this.nav.XIstudhide();
this.nav.aduserhide();

  // primng table

//   this.service.getCustomersLarge().then((candidates:any) => {
//     this.candidates = candidates;
//     this.loading = false;
// });
this.getallcandidates();

  this.primengConfig.ripple = true;
this.standard = localStorage.getItem('Standard');
}

getEventValue($event:any) :string {
  return $event.target.value;
}

getidEventValue($event:any) :string {
  return $event.target.value;
}
getusernameEventValue($event:any) :string {
  return $event.target.value;
}

getnameEventValue($event:any) :string {
  return $event.target.value;
}
getmiddleNameEventValue($event:any) :string {
  return $event.target.value;
}

getlastNameEventValue($event:any) :string {
  return $event.target.value;
}

getdivisionEventValue($event:any) :string {
  return $event.target.value;
}
getstreamEventValue($event:any) :string {
  return $event.target.value;
}
getenrollmentyearEventValue($event:any) :string {
  return $event.target.value;
}
getcourseEventValue($event:any) :string {
  return $event.target.value;
}
getgenderEventValue($event:any) :string {
  return $event.target.value;
}
getresigstatusEventValue($event:any) :string {
  return $event.target.value;
}

getpaymentstatusEventValue($event:any) :string {
  return $event.target.value;
}
getpaymentIDEventValue($event:any) :string {
  return $event.target.value;
}

getallcandidates()
{
  let schoolinfo :{schoolid:any}
  ={
    schoolid:this.schoolid
  }

  this.service.post('/School/viewAllStudents',schoolinfo).subscribe((candidates:any) => {
    
    // if(candidates=="Sucess"){
      this.students = candidates.Data;
      this.loading = false;
      // //console.log(this.students);

    // }
    // else{
    //   alert("No Students Found");
    //   this.router.navigate(['/schooldashboard']);
    // }
});



}



// Export to excel file

exportexcel():void 
{
   /* table id is passed over here */   
   let element = document.getElementById('excel-table'); 
 
  
   const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

   /* generate workbook and add the worksheet */
   const wb: XLSX.WorkBook = XLSX.utils.book_new();
   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

   /* save to file */
   XLSX.writeFile(wb, this.fileName);

  //  let stud = {
  //    "status" :"Payment Settled"
    
  //  }
  //  //console.log(stud);
   
  //  this.service.updateamstatus(stud).subscribe((res:any)=>{
  //   //console.log(res);
  //   this.getstatusdata = res;
  //   this.loading = false;
  //   if(res){
  //     let Message = res['Message'];
  //     // alert(Message);
  //     // Swal.fire("claim Submitted Successfully")
  //     Swal.fire(Message);
  //    this.getreports();
  //    location.reload();

  //    // this.modalservice.close();
  //   //  this._Router.navigate(['/new-claim']);
  //   }
  //   else{
  //     let Message = res['Msg'];
  //     Swal.fire(Message)
  //    // this.modalservice.close();
  //   }
  // });
  
}

resetpassword(clicked_id: any)
{
    if (confirm("Are you sure? Do You Want Reset Password"))
    {
        //do your process of delete using angular js.
        // alert(clicked_id)
        let rest:{studentid:any}={
          studentid:clicked_id
        }
        //console.log(rest);
        this.service.post('/School/resetCandidatesPassword', rest).subscribe((res:any)=>{
          this.resetpswd = res['Data'];
          // alert('success');
          if(res['status'] == 'Success')
          {
              let msg = res['Msg'];

              alert('Your Password is 123456');
              
          }
          else
          {
            let mssg = res['Msg'];
            alert(mssg)
          }
        
        })

    }
    // else
    // {
    //   alert('cancel')
    // }
}

deleteCandidate(clicked_id: any)
{
    if (confirm("Are you sure? Do You Want Delete this Candidate"))
    {
        //do your process of delete using angular js.
        // alert(clicked_id)
        let rest:{studentid:any}={
          studentid:clicked_id
        }
        //console.log(rest);
        this.service.post('/School/deleteCandidate', rest).subscribe((res:any)=>{
          this.resetpswd = res['Data'];
          // alert('success');
          if(res['status'] == 'Success')
          {
              let msg = res['Msg'];

              alert('Candidate Deleted');
              window.location.reload();
          }
          else
          {
            let mssg = res['Msg'];
            alert(mssg);
            window.location.reload();
          }
        
        })

    }
    // else
    // {
    //   alert('cancel')
    // }
}

}