import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SchoolserviceService } from '../schoolservice.service';
import { DatePipe } from '@angular/common';
import { NavbarService } from '../default/navbar/navbar.service';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss'],
  providers: [DatePipe]
})
export class ResetpasswordComponent implements OnInit {

  boarduserresetform: FormGroup;
  submitted = false;
  bUserName!: String;
  allBoardUsersFullname: any[]=[];
  allBoardUsersName: any[]=[];
  allBoardUsers:any[]=[];
  currUsername!:string;
  role_type: any;
  constructor(public formbuilder:FormBuilder,public router:Router, public service:SchoolserviceService,public datepipe: DatePipe,public nav:NavbarService) {

    this.boarduserresetform = formbuilder.group({
      
      username:['',Validators.required],
      //password:['', Validators.required],
      new_password:['', Validators.required]

    })
   }

  ngOnInit(): void {

    this.role_type =  localStorage.getItem('role_type');
    if(this.role_type == 'boardadmin')
    {
      this.nav.hide();
      this.nav.dhide();
      this.nav.adshow();
      this.nav.aduserhide();
      this.nav.asdhide();
      this.nav.studhide();
      this.nav.XIstudhide();
    }
    else
    {
      this.nav.hide();
      this.nav.dhide();
      this.nav.adhide();
      this.nav.adusershow();
      this.nav.asdhide();
      this.nav.studhide();
      this.nav.XIstudhide();
    }
    this.allBoardUsersFullname[0]="";
    this.getBoardUserName();
  }

  get personal() { return this.boarduserresetform.controls; }

  onsubmit(){

    this.submitted = true;
    if (this.boarduserresetform.invalid) {
      
      alert("Fill All Deatils");
      return;

    }
    this.resetBoardUserPass();
     
  }
  resetBoardUserPass(){
    this.getUserName();//check username of selected fullm]name
    let userDetails: {username:any, newpassword:any} = {
      username:this.currUsername,      
      newpassword:this.boarduserresetform.value.new_password
    //  oldpassword:123456,
    }
    //console.log(userDetails);
    this.service.post('/BoardApi/resetPasswordBoarduser',userDetails).subscribe((passReset: any) => {
      //console.log(passReset['status']);
      let resetPass=passReset['status'];
      if(resetPass=='Success'){
        //console.log("Password Replaced");
        alert("Password is replaced for User: "+this.boarduserresetform.value.username);
      }
      }); 
  }
  getBoardUserName(){
    let schoolId: { } = {
       
    }
    this.service.post('/BoardApi/getAllBoarduser',schoolId).subscribe((allBoardUsers: any) => {
      //console.log(allBoardUsers.Data['fullname']);
      this.allBoardUsers=allBoardUsers.Data;
      this.allBoardUsersFullname=[];
      this.allBoardUsersFullname[0]="";
      for(let i=1,j=0;j<allBoardUsers.Data.length;i++,j++){        
       this.allBoardUsersFullname.push(allBoardUsers.Data[j]['fullname']);       
      }     
    
  }); 
  }
  getUserName(){
    for(let i=0;i<this.allBoardUsers.length;i++){
      if(this.allBoardUsers[i].fullname==this.boarduserresetform.value.username){
        this.currUsername=this.allBoardUsers[i]['username'];
      }
    }
  }

}
