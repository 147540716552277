import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SchoolserviceService } from '../schoolservice.service';
import { DatePipe } from '@angular/common';
import { NavbarService } from '../default/navbar/navbar.service';
import{allBoardUser} from'./allBoardUser';
@Component({
  selector: 'app-viewboardlist',
  templateUrl: './viewboardlist.component.html',
  styleUrls: ['./viewboardlist.component.scss'],
  providers: [DatePipe]
})
export class ViewboardlistComponent implements OnInit {
  allBoardUsers!:allBoardUser[];
  isStatusActive!:String;
  userStatus!:string;
 active:boolean=true;
 deactive:boolean=false;
  role_type: any;
  constructor(public formbuilder:FormBuilder,public router:Router, public service:SchoolserviceService,public datepipe: DatePipe,public nav:NavbarService) {

    
   }

  ngOnInit(): void {

    this.role_type =  localStorage.getItem('role_type');
    if(this.role_type == 'boardadmin')
    {
      this.nav.hide();
      this.nav.dhide();
      this.nav.adshow();
      this.nav.aduserhide();
      this.nav.asdhide();
      this.nav.studhide();
      this.nav.XIstudhide();
    }
    else
    {
      this.nav.hide();
      this.nav.dhide();
      this.nav.adhide();
      this.nav.adusershow();
      this.nav.asdhide();
      this.nav.studhide();
      this.nav.XIstudhide();
    }


    this.getAllBoardUserList();

  }
  getAllBoardUserList(){
    
      let schoolId: { } = {
       
      }
      
      this.service.post('/BoardApi/getAllBoarduser',schoolId).subscribe((boardUsers: any) => {
          //console.log(boardUsers.Data);
         this.allBoardUsers=boardUsers.Data; 
        //  this.isStatusActive="badge badge-danger";
        //  this.isStatusActive="badge badge-success";
      })
  }
  changeUserStatus(e:any){
    //alert(e.checked);
    if(e.checked==true){
      this.userStatus="active";
    }else{
      this.userStatus="deactive";
    }
    
      let empid: {id:any,status:any} = {
       id:e.value,
       status:this.userStatus
      }           
      this.service.post('/BoardApi/changeBoarduserStatus',empid).subscribe((boardUsers: any) => {
        this.getAllBoardUserList();
        
      })
    
  }

}
