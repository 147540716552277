import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NavbarService } from '../default/navbar/navbar.service';
import { SchoolserviceService } from '../schoolservice.service';
@Component({
  selector: 'app-studlogin',
  templateUrl: './studlogin.component.html',
  styleUrls: ['./studlogin.component.scss']
})
export class StudloginComponent implements OnInit {

  showDiv = {
    previous : true,
    current : false,
     next : false,
     sscrslt: false,
     test:false
  }
  loginform: FormGroup;
  studentloginform: FormGroup;

  captcha: any;
  alphaNums = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
   emptyArr:any = [];
   refreshArr :any = [];
  loginuser: any;
  isloggin: any;
  stud_id: any;
  username: any;
  std_enroll_id: any;
  enrolldata: any;
  application_status: any;
  loginschool: any;
  schoolid: any;
  scode: any;
  enrollmnt:any;
  enrollment: any;
  standard: any;
  alldates: any;
  allmessages: any;
  messages: any[]=[];
  schoolname: any;
  dateall: any;
  alldates11: any;
  alldates9: any;
  alldates11school: any;
  alldates9school: any;
  msg: any;
  studsubmitted = false;
  schlsubmitted = false;
  enrollmntid: any;
  constructor(public formbuilder:FormBuilder,public router:Router,public datepipe: DatePipe, public service:SchoolserviceService,public nav:NavbarService) {
    this.loginform = this.formbuilder.group({
      username :['',Validators.required],
      password:['',Validators.required],
      captcha_text:['',Validators.required]
    })
    this.studentloginform = this.formbuilder.group({
      username :['',Validators.required],
      password:['',Validators.required],
      captcha_text:['',Validators.required]
    })
   }
   get personal1() { return this.loginform.controls; }
   get personal2() { return this.studentloginform.controls; }
  ngOnInit(): void {

    this.nav.show();
    this.nav.dhide();//studentdash
    this.nav.adhide();//boardadmindash
    this.nav.asdhide(); //schooladmin
    this.nav.studhide();
    this.nav.XIstudhide();
    this.nav.aduserhide();
    // This loop generates a random string of 7 characters using alphaNums
    // Further this string is displayed as a CAPTCHA
    for (let i = 1; i <= 5; i++) {
    this.emptyArr.push(this.alphaNums[Math.floor(Math.random() * this.alphaNums.length)]);
    }
     this.captcha = this.emptyArr.join('');

     //console.log(this.captcha);

     this.service.post('/School/viewlastdateforAll',{}).subscribe((res:any)=>{
       this.alldates = res['Data'];
      //  this.alldates11 = this.alldates[0].lastdate;
       this.alldates9 = this.datepipe.transform(this.alldates[2].lastdate, 'dd/MM/yyyy');
       this.alldates11school = this.datepipe.transform(this.alldates[0].lastdate, 'dd/MM/yyyy');
       this.alldates9school = this.datepipe.transform(this.alldates[1].lastdate, 'dd/MM/yyyy');
       this.alldates11 = this.datepipe.transform(this.alldates[3].lastdate, 'dd/MM/yyyy');
      // //console.log(this.dateall)
     });

     this.getmessages();

  }


// This event listener is stimulated whenever the user press the "Refresh" button
  refresh()
  {
    // form.captcha_text = "";
    // let refreshArr = [];
    for (let j = 1; j <= 5; j++) {
      
    this.refreshArr.push(this.alphaNums[Math.floor(Math.random() * this.alphaNums.length)]);
    // this.emptyArr.splice(0,this.emptyArr.length);
    
    }

    this.captcha = this.refreshArr.join('');
    this.refreshArr.splice(0,this.refreshArr.length)
    // //console.log(this.captcha);


  }

  activeTab = '';
  toggleDisplayDiv(activeTab:any) {
    
    // this.isShowDiv = !this.isShowDiv;
    this.showDiv.previous = !this.showDiv.previous;
    this.showDiv.current = false;
    this.showDiv.next = false;
    this.showDiv.sscrslt = false
    // this._compiler.clearCache();
    this.activeTab = activeTab;
  }

  sscResult(activeTab: any){
    // this.router.navigate(['/sscstudents']);
    this.showDiv.next = !this.showDiv.next;
    this.showDiv.previous = false;
    this.showDiv.current = false;
    this.showDiv.sscrslt = false
    // this._compiler.clearCache();
    this.activeTab = activeTab;
  }

  OnLogin(form: { username: any; password: any; captcha_text:any; }){
    this.schlsubmitted = true;
    let login_para ={
      "username":form.username,
      "password":form.password
     }

     if (form.captcha_text == this.captcha)
     {
      // alert('sucess ')
      let seq = this.service.post('/StudentApi9/studentlogin',login_para);
      seq.subscribe((res:any) =>{
        // //console.log(res);
        // const abc = ['usename', 'password', 'date'];
        this.loginuser = res['Data'];
        this.application_status = res['Status'][0].submission_status;
        //console.log(this.application_status);
        this.schoolid = res['Status'][0].schoolid;
        // const user = this.loginuser[0];
        this.username = this.loginuser[0].username;
        this.isloggin = this.loginuser[0].status;
        this.stud_id = this.loginuser[0].studentid;

        let enroll :{studentid:any}={
          studentid:this.stud_id
        }
        let seq =this.service.post('/StudentApi9/enrollmenetid',enroll);
        seq.subscribe((res:any) =>{
          this.enrolldata = res['Data'];
          this.std_enroll_id = this.enrolldata;
         //console.log(this.enrolldata);
            if(this.isloggin == "New")
            {
              alert('Kindly Change your password to proceed to complete your Application'
            );
              this.router.navigate(['/ontimechangepassword']);
            }
            else
            {
              // alert('Logged In Successfully');
              // this.router.navigate(['/landing']);
              if(this.application_status != 'Submitted' && this.std_enroll_id == '9th')
              {
                // this.router.onSameUrlNavigation = 'reload';
                this.router.navigate(['/studentchklanding']);

              }
              else if((this.application_status == 'Submitted' || this.application_status == 'Approved')  && this.std_enroll_id == '9th')
              {
                // this.router.onSameUrlNavigation = 'reload'; 
                this.router.navigate(['/studentchklanding']);
                // this.router.navigate(['/landing']);

              }
              else if((this.application_status != 'Submitted' || this.application_status != 'Approved')  && this.std_enroll_id == '11th'){
                // this.router.onSameUrlNavigation = 'reload'; 
                this.router.navigate(['/elevenstudents']);
                // this.router.navigate(['/landing']);
              }
              else
              {
                // this.router.onSameUrlNavigation = 'reload'; 
                this.router.navigate(['/elevenstudents']);
                // this.router.navigate(['/landing']);
              }
            }

            
      localStorage.setItem('studentid',this.stud_id);
      localStorage.setItem('username',this.username);
      localStorage.setItem('submission_status',this.application_status);
      localStorage.setItem('status',this.isloggin);
      localStorage.setItem('schoolid',this.schoolid);
        });

        // //console.log(this.username);
       

      // sessionStorage.setItem('submission_status',this.application_status);
     

        // localStorage.setItem('password',this.password);
        // localStorage.setItem('user_id',this.user_id);
        // this.router.navigate(['/landing'])
        // .then(() => {
        //   window.location.reload();
        // });
      },err=>{
        alert("Invalid Username/Password!");
           
      })

      } 
      else {
        
        alert('check captcha');

      }

    //  //console.log(login_para);

    //  alert('You Have Change Your Password');
    //  this.router.navigate(['/changepassword']);
  }

  onSchoolLogin(form: { username: any; password: any; captcha_text:any; }){
    this.studsubmitted = true;
    let login_para ={
      "username":form.username,
      "password":form.password
     }
    //  //console.log(form.captcha_text);
     if(form.captcha_text == this.captcha)
     {
       let seq = this.service.post('/School/schoolLogin',login_para);
       seq.subscribe((res:any)=>{

        this.loginschool = res['Data'];
        this.schoolid  = this.loginschool[0].schoolid;
        this.schoolname = this.loginschool[0].schoolname;
        this.standard  = res['Standard'];
        this.scode  = res['scode'];
        this.username = res['username'];
        this.enrollmnt  = res['enrollment'][0].enrollmentyear;
        this.enrollmntid  = res['enrollment'][0].enrollmentid;
        this.enrollment =res['enrollment'];
        //console.log(this.enrollmnt);
        if(res['status'] == 'Success')
        {
          // alert('Logged In Successfully');
          this.router.onSameUrlNavigation = 'reload'; 
          this.router.navigate(['/schooldashboard']);
         

        }
        
        localStorage.setItem('schoolid',this.schoolid);
        localStorage.setItem('Standard',this.standard);
        localStorage.setItem('scode',this.scode);
        localStorage.setItem('enrollmentyear',this.enrollmnt);
        localStorage.setItem('enrollmentid',this.enrollmntid);
        localStorage.setItem('enrollment',this.enrollment);
        localStorage.setItem('schoolname',this.schoolname);
        localStorage.setItem('username', this.username);
        
       },err=>{
        alert("Invalid Username/Password!");
           
      })
      
     }
     else {
        
      alert('check captcha');

    }
  }

  // for dynamic messages
  getmessages()
  {

    this.service.post('/BoardApi/viewMessages',{}).subscribe((res:any)=>{
      this.msg = res.Data;
      this.allmessages = res.Data[0].message;
      // this.messages =[];
      // for (let i = 0; i < this.allmessages.length; i++) {
        
      //   this.messages.push(this.allmessages[i].message)
        
      // }
      // //console.log(this.allmessages);
    })
  }


}
