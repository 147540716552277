import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpEvent, HttpParams } from '@angular/common/http';
import { Candidate } from './candidates-details/candidate';
import { Candidate_payment } from './candidates-payment/candidates-payment';
import { candidates_reports } from './school-stud-report/candidatesReports';


@Injectable({
  providedIn: 'root'
})
export class SchoolserviceService {

      //  url: string = 'http://localhost/gbshse0911/index.php';
      // url: string = 'https://results.gbshsegoa.net/gbshse0911_REST/index.php';
      //  url: string = 'http://edpltest.in/gbshse911/index.php';
      url: string = ' https://service1.gbshse.in/gbshse0911_REST/index.php'; //s3 working
      
      constructor(private httpClient: HttpClient) { 
        
      }

      // temprary code for get data in candidate-details
      getCustomersLarge() {
        return this.httpClient.get<any>('/assets/studnets.json')
            .toPromise()
            .then(res => <Candidate[]>res.data)
            .then(data => { return data; });
    }

    getschoolsLarge() {
      return this.httpClient.get<any>('assets/studnets.json')
          .toPromise()
          .then(res => <Candidate[]>res.schooldata)
          .then(schooldata => { return schooldata; });
  }

  // candidate payment data
  getcandidatepaymentLarge() {
    return this.httpClient.get<any>('assets/studnets.json')
        .toPromise()
        .then(res => <Candidate_payment[]>res.candidatepaymentdata)
        .then(candidatepaymentdata => { return candidatepaymentdata; });
}

// candidate Report data
getcandidateReportLarge() {
  return this.httpClient.get<any>('assets/studnets.json')
    .toPromise()
    .then(res => <candidates_reports[]>res.candidateReportData)
    .then(candidateReportData => { return candidateReportData; });
}

      get(endpoint: string, params?: any, reqOpts?: any) {
        if (!reqOpts) {
          reqOpts = {
            params: new HttpParams()
          };
        }
        // Support easy query params for GET requests
        if (params) {
          reqOpts.params = new HttpParams();
          for (let k in params) {
            reqOpts.params = reqOpts.params.set(k, params[k]);
          }
        }
        return this.httpClient.get(this.url + '/' + endpoint, reqOpts);
      }
      post(endpoint: string, body: any,params?: any, reqOpts?: any) {
        if (!reqOpts) {
          reqOpts = {
            params: new HttpParams(),
          };
        }
        // Support easy query params for GET requests
        if (params) {
          reqOpts.params = new HttpParams();
          for (let k in params) {
            reqOpts.params = reqOpts.params.set(k, params[k]);
          }
        }
       // //console.log("Body :"+JSON.stringify(body));
         return this.httpClient.post(this.url + endpoint, body, reqOpts);
      
     }
     put(endpoint: string, body: any, reqOpts?: any) {
      return this.httpClient.put(this.url + '/' + endpoint, body, reqOpts);
     }
     
     delete(endpoint: string, reqOpts?: any) {
      return this.httpClient.delete(this.url + '/' + endpoint, reqOpts);
     }
     
     patch(endpoint: string, body: any, reqOpts?: any) {
      return this.httpClient.patch(this.url + '/' + endpoint, body, reqOpts);
     }

    // 
     uploadFile(data: FormData)
     {
        //console.log(data)
        let uploadURL = this.url+'/StudentApi9/upload';

        return this.httpClient.post<any>(uploadURL, data);
     }
     upload11thstudentFile(data: FormData)
     {
        //console.log(data)
        let uploadURL = this.url+'/Upload_function/upload';

        return this.httpClient.post<any>(uploadURL, data);
     }

    //  Excel Upload 
     Excelimport(params:any){
      //http://edpltest.xyz/api/index.php/Excel_import/import
      return this.httpClient.post(this.url+'/School/import',params)
      
      }
     
}
