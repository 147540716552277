import { Component} from '@angular/core';
import { Router } from '@angular/router';
import { ChartType } from 'angular-google-charts';
import { NavbarService } from '../default/navbar/navbar.service';
import { SchoolserviceService } from '../schoolservice.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent{
  last_date_9thstudent_regi!:any;
  last_date_9thschool_submit!:any;
  last_date_11thstudent_regi!:any;
  last_date_11thschool_submit!:any;
  registration9_data!: any[];   
  registration11_data!:any[]; 
  maleFemale_data!:any[];
  paidunpaid_data!:any[];
  studentstaus_data!:any[];
  schoolid: any;
  standard: any;
  lastdate_9th: any;
  lastdate_11th: any;
  excepnldate:any;
  regi9StatusGhraphShow:boolean=false;
  regi11StatusGhraphShow:boolean=false;
  payStatusGhraphShow:boolean=false;
  genStatusGhraphShow:boolean=false;
  studStatusGhraphShow:boolean=false;
  lastdatestud_11th: any;
  lastdatestud_9th: any;
  constructor(public nav:NavbarService,public router:Router,public datepipe:DatePipe, public service:SchoolserviceService) { }

  ngOnInit(): void {
    
    this.schoolid = localStorage.getItem('schoolid');
    this.standard = localStorage.getItem('Standard');
    this.nav.hide();
    this.nav.dhide();
    this.nav.adhide();
    this.nav.asdshow();
    this.nav.studhide();
    this.nav.XIstudhide();
    this.nav.aduserhide();
    //console.log("Std: "+this.standard);

    this.getalldashboardinfo();
   
  }

 
   //9th registration data
  getalldashboardinfo()
  {
    let schoolinfo :{schoolid:any}={
      schoolid:this.schoolid
    }
    //console.log(schoolinfo);
    this.service.post('/School/dashboardData', schoolinfo).subscribe((res: any) => {
       //console.log("====");
       //console.log(res);
      if(res.status=="Sucess"){
         //console.log("Sucess");
      //9th registration data
      if(res.Submitted9==0 && res.Pending9==0 && res.Rejected9==0 && res.Approved9==0){
        this.registration9_data = [
          ['Submitted', ""],
          ['New', ""],
          ['Pending', ""],
          ['Rejected',""],
          ['Approved',""]
        ];
        this.regi9StatusGhraphShow=true;
      }
      else{
       this.registration9_data = [
         ['Submitted', res.Submitted9],
         ['New', res.New9],
         ['Pending', res.Pending9],
         ['Rejected', res.Rejected9],
         ['Approved', res.Approved9]
       ];
      // //console.log(this.registration9_data);
      this.regi9StatusGhraphShow=false;
      }
       //11th registration data

       if(res.Submitted11==0 && res.Pending11==0 && res.Rejected11==0 && res.Approved11==0){
        this.registration11_data = [
          ['Submitted', ""],
          ['New', ""],
          ['Pending', ""],
          ['Rejected',""],
          ['Approved',""]
        ];
        this.regi11StatusGhraphShow=true;
       }
       else{
       this.registration11_data = [
        ['Submitted', res.Submitted11],
        ['New', res.New11],
        ['Pending', res.Pending11],
        ['Rejected', res.Rejected11],
        ['Approved', res.Approved11]
       ];
       this.regi11StatusGhraphShow=false;
       
      }
      //console.log(this.registration11_data);
      //male female data
      if(res.male==0 && res.female==0){
        this.maleFemale_data = [
          ['Boys',""],
          ['Girls',""]
        ];
        this.genStatusGhraphShow=true;
      }
      else{              
       this.maleFemale_data = [
         ['Boys', res.male],
         ['Girls', res.female]
       ];
       this.genStatusGhraphShow=false;
      }
      //  paid unpaid data
      if(res.Paid ==0 && res.Unpaid==0){
        this.paidunpaid_data = [
          ['Paid', ""],
          ['Unpaid',"" ]
        ];
        this.payStatusGhraphShow=true;
      }
      else{
      this.paidunpaid_data = [
        ['Paid', res.Paid],
        ['Unpaid',res.Unpaid ]
      ];
      this.payStatusGhraphShow=false;
      }
      //console.log("student status");
      if(res.Promoted== 0 && res.Detained== 0 && res.Left==0){
        this.studentstaus_data = [
          ['Promoted', ""],
          ['Detained', ""],
          ['Left', ""]
       ];
        //console.log(this.studentstaus_data);
        this.studStatusGhraphShow=true;
      }
      else{
        //console.log(this.studentstaus_data);
        this.studentstaus_data = [
          ['Promoted', res.Promoted],
          ['Detained', res.Detained],
          ['Left', res.Left]
      ];
      this.studStatusGhraphShow=false;
      }
      }
      else{
         // //console.log("Fail");
         this.registration9_data = [
          ['Submitted', ""],
          ['New', ""],
          ['Pending', ""],
          ['Rejected',""],
          ['Approved',""]
        ];
        this.regi9StatusGhraphShow=true;
    
    this.registration11_data = [
          ['Submitted', ""],
          ['New', ""],
          ['Pending', ""],
          ['Rejected',""],
          ['Approved',""]
        ];
        this.regi11StatusGhraphShow=true;
    
    this.maleFemale_data = [
          ['Boys',""],
          ['Girls',""]
        ];
        this.genStatusGhraphShow=true;
    
    this.paidunpaid_data = [
          ['Paid', ""],
          ['Unpaid',"" ]
        ];
        this.payStatusGhraphShow=true;
    
    this.studentstaus_data = [
          ['Promoted', ""],
          ['Detained', ""],
          ['Left', ""]
      ];
        //console.log(this.studentstaus_data);
        this.studStatusGhraphShow=true;
      }

  })
  this.service.post('/School/viewlastdateforCandidates',schoolinfo).subscribe((res: any)=>{
    //last dates for 11th candidates
     this.last_date_11thstudent_regi = res['Data'][1].lastdate
    //last dates for 9th students
    this.last_date_9thstudent_regi = res['Data'][0].lastdate

    this.lastdatestud_9th = this.datepipe.transform(this.last_date_9thstudent_regi, 'dd/MM/yyyy');
      //last dates for 9th school        

      this.lastdatestud_11th = this.datepipe.transform(this.last_date_11thstudent_regi, 'dd/MM/yyyy');

    })
   //checkExpDateForSchool   
   this.service.post('/School/viewexceptionlastdateforSchools',schoolinfo).subscribe((res:any)=>{
    this.excepnldate = res['Data'];
    //console.log("exp last date"); 
    //console.log(this.excepnldate); 

  if(this.excepnldate=="" || this.excepnldate== undefined){
    //console.log("in if exp date is not found");    
      this.service.post('/School/viewlastdateforSchools',schoolinfo).subscribe((res: any)=>{
      //last dates for 11th school        
      this.last_date_9thschool_submit = res['Data'][1].lastdate;
      this.lastdate_9th = this.datepipe.transform(this.last_date_9thschool_submit, 'dd/MM/yyyy');
      //console.log(this.lastdate_9th);
      //last dates for 9th school       
      this.last_date_11thschool_submit = res['Data'][0].lastdate;
      this.lastdate_11th = this.datepipe.transform(this.last_date_11thschool_submit, 'dd/MM/yyyy');
      })
  }
  else{
      //console.log("in else exp date found");  
     //last dates for 11th school        
      this.lastdate_9th = this.excepnldate;
      // this.lastdate_9th = this.datepipe.transform(this.last_date_9thschool_submit, 'dd/MM/yyyy');
      //last dates for 9th school        
      this.lastdate_11th = this.excepnldate;
      // this.lastdate_11th = this.datepipe.transform(this.last_date_11thschool_submit, 'dd/MM/yyyy');
    }
    //console.log(this.last_date_9thschool_submit+"  "+this.last_date_11thschool_submit);

  });
  }
  
  
  registration9_title = '9th Student Registration';
  registration9_type = ChartType.PieChart;
  registration9_options = { 
    colors: ['#8D9B6A','#8F5B34','#8A9EA7','#DAB692','#913ccd'], 
    is3D: true,
    legend: {position: 'right',alignment:'center', textStyle: {color: 'black', fontSize: 12}},
    pieSliceText:'value',
  };
  registration9_width = 500;
  registration9_height = 140;
  // registration9_data = [
  //     ['Registered', 400],
  //     ['Pending', 1200]
  //  ];
 
  // Student Status
  studentstatus_title = 'Student Status';
  studentstaus_type = ChartType.PieChart;
  // studentstaus_data = [
  //     ['Promoted', 60.0],
  //     ['Detained', 40.0],
  //     ['Left', 10.0]
  //  ];
  student_options ={
    colors: ['#f15f74', '#913ccd','#2ca8c2'], 
    is3D: true,
     legend: {position: 'right',alignment:'center', textStyle: {color: 'black', fontSize: 16}},
     pieSliceText:'value',
  }
  studentstatus_width = 500;
  studentstatus_height = 140;

   //male-female data
  maleFemale_title = 'Male/Female Percentage';
  maleFemale_type = ChartType.PieChart;
  // maleFemale_data = [
  //     ['Boys', 60.0],
  //     ['Girls', 40.0]
  //  ];
   maleFemale_options = { 
     colors: ['#2ca8c2', '#98cb4a'],
     is3D: true,
     legend: {position: 'right',alignment:'center', textStyle: {color: 'black', fontSize: 16}},
     pieSliceText:'value',
   };
   maleFemale_width = 500;
   maleFemale_height = 140;

   //11th students registration
  registration11_title = '11th Student Registration';
  registration11_type = ChartType.PieChart;
  // registration11_data = [
  //     ['Registered', 250.0],
  //     ['Pending', 65.0]
  //  ];
   registration11_options = { 
    colors: ['#FFC94B','#C1867B','#F17A7E','#4A6163','#f3a572'], 
     is3D: true,
     legend: {position: 'right',alignment:'center', textStyle: {color: 'black', fontSize: 12}}       ,
     pieSliceText:'value',
   };
   registration11_width = 500;
   registration11_height = 140;

    //11th students registration
  payment_title = 'Payment Status';
  payment_type = ChartType.PieChart;
  // registration11_data = [
  //     ['Registered', 250.0],
  //     ['Pending', 65.0]
  //  ];
   payment_options = { 
     colors: ['#BC85A3', '#9799BA'], 
     is3D: true,
     legend: {position: 'right',alignment:'center', textStyle: {color: 'black', fontSize: 16}}       ,
     pieSliceText:'value',
   };
   payment_width = 500;
   payment_height = 140;
}
