import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NavbarService } from '../default/navbar/navbar.service';
import { SchoolserviceService } from '../schoolservice.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-candidate-profile',
  templateUrl:'./candidate-profile.component.html',
  styleUrls: ['./candidate-profile.component.scss'],
  providers: [DatePipe]
})
export class CandidateProfileComponent implements OnInit {

  studentprofileform: FormGroup;
  // searchform: FormGroup;
  captcha: any;
  alphaNums = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  emptyArr:any = [];
  refreshArr :any = [];
  loginuser: any;
  submitted = false;
  validated_subjects=false;
  studID:any;
  userData: any;
  photo_upload_success:boolean=false;
  signature_upload_success: boolean = false;
  date!: Date;
  uploadResponse: any;
  isdisable: boolean = false;
  reviewArray!: any[];

  subject:any;
  subject1:any;
  subject2:any;
  subject3:any;
  subject4:any;
  subject5:any;
  subject6:any;
  // subjects
  selectedsubject1:any;
  selectedsubject2:any;
  selectedsubject3:any;
  selectedsubject4:any;
  selectedsubject5:any;
  selectedsubject6:any;
  studschool: any;
  subjectmap: any;
  streamvalue: any;
  boardvalue: any;
  boardinfo: any;
  Stname: any;
  subj_array: any;
  subjectlist: any;
  examyear :any;
  chkseat:any;
  msg: any;
  alphmsg:any;
  photoinfo: any;
  signinfo: any;
  last_date_11thstudent_regi: any;
  last_date_9thstudent_regi: any;
  schoolid: any;
  marksinfo: any;
  latest_date: any;
  dob: any;
  checkstream: any;
  status: any;
  msgs: any;
  boardstatus: any;
  aadharmsg: any;
  conmsgs: any;
  lastdate: any;
  lastdate9th: any;
  subject7: any;
  subject8: any;
  subject10: any;
  subject9: any;
  course1 : any;
  course2 : any;
  coursesall:any;
  coursecode: any;
  coursename: any;
  coursesubj: any;
  subjcode: any;
  course3: any;
  course4: any;
  course6: any;
  course5: any;
  subject11: any;
  subject12: any;
  schoolmsg:any;
  distmsg:any;
  othercourse: any;
  char:boolean = true;
  board:boolean = false;
  pinmsg: any;
  mobmsg: any;
  alltalukas: any;
  constructor(public formbuilder:FormBuilder,public router:Router, public service:SchoolserviceService,public datepipe: DatePipe,public nav:NavbarService) {
    // this.searchform =formbuilder.group({
    //   board:['',Validators.required],
    //   exam:['',Validators.required],
    //   seat:['',Validators.required],
    // });
    
    this.studentprofileform = formbuilder.group({

      stream:[''],
      course:[''],
      boardname:['',Validators.required],
      exam:[''],
      seat:[''],
      rollno:[''],
      division:[''],
      stname:[''],
      ftname:[''],
      mtname:[''],
      class:[''],
      dob:['',Validators.required],
      gender:['',Validators.required],
      email:['',[Validators.required,Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      mobile_no:['',[Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      alt_mobile_no:[''],
      aadhar:['',[Validators.required,Validators.pattern("^[0-9]{12}$")]],
      caste:['',Validators.required],
      street:['',Validators.required],
      locality:[''],
      city:['',Validators.required],
      state:['',Validators.required],
      taluka:['',Validators.required],
      district:['',Validators.required],
      landmark:[''],
      pincode:['',[Validators.required,Validators.pattern("^[0-9]*$")]],
      house_ownership:[''], 
      internet_coverage:[''],
      distance_coverage:[''],
      access_to_smart_phone:[''],
      access_internet:[''],
      distance_of_school_to_house:[''],
      mode_of_transport:[''],
      otherboardname:[''],
      eligibilitynumber:[''],
      marksheet:[''],
      security_ques:['',Validators.required],
      security_ans:['',Validators.required],
      fathers_qualification:[''],
      fathers_job:[''],
      fathers_annual_income:[''],
      mothers_qualification:[''],
      mothers_job:[''],
      mothers_annual_income:[''],
      photo:['',Validators.required],
      signature:['',Validators.required],
      subject_one:['',Validators.required],
      subject_two:['',Validators.required],
      subject_three:['',Validators.required],
      subject_four:['',Validators.required],
      subject_five:['',Validators.required],
      subject_six:['',Validators.required],
      subject_seven:[''],
      subject_eight:['',Validators.required],
      subject_nine:['',Validators.required],
      subject_ten:['',Validators.required],
      subject_eleven:['',Validators.required],
      captcha_text:['',Validators.required]

      })
   }

   ngOnInit(): void {

    
    this.status = localStorage.getItem("submission_status");
    this.studID = localStorage.getItem('studentid');
    this.studentprofileform.controls.class.setValue('11th');
    // console.log(this.status);
    // if(this.status != "New" && this.status != "Pending")
    // {
    //   this.router.onSameUrlNavigation = 'reload';
    //   this.router.navigate(['/viewcandidateprofile']);
    // }
 
  
    this.nav.XIstudshow();
    this.nav.hide();
    this.nav.dhide();//
    this.nav.adhide();//boardadmindash
    this.nav.asdhide(); //schooladmin
    this.nav.studhide();
    this.nav.aduserhide();
    

    // this.schoolid = localStorage.getItem('schoolid');
    console.log(this.studID);
  if(this.studID)
  {
    this.getcourses();
    this.getstudentform();
    this.getstudentclassForm();
    this.getselectedsubject();
   
  }
    // This loop generates a random string of 7 characters using alphaNums
    // Further this string is displayed as a CAPTCHA
    for (let i = 1; i <= 5; i++) {
    this.emptyArr.push(this.alphaNums[Math.floor(Math.random() * this.alphaNums.length)]);
    }
     this.captcha = this.emptyArr.join('');

     console.log(this.captcha);

     this.getsubjects();

     this.date=new Date();
     this.latest_date = this.datepipe.transform(this.date, 'MM/dd/yyyy');
    console.log(this.latest_date);

    
    let schoolinfo :{schoolid:any}={
      schoolid:this.schoolid
    }
    this.service.post('/School/viewlastdateforCandidates',schoolinfo).subscribe((res: any)=>{

      //last dates for 11th candidates
       this.last_date_11thstudent_regi = res['Data'][1].lastdate;
       this.lastdate = this.datepipe.transform(this.last_date_11thstudent_regi, 'dd/MM/yyyy');
      //last dates for 9th students
      this.last_date_9thstudent_regi = res['Data'][0].lastdate;
      this.lastdate9th = this.datepipe.transform(this.last_date_9thstudent_regi, 'MM/dd/yyyy');
      // console.log(this.last_date_11thstudent_regi)
      
      // console.log(this.lastdate)
      var latest_day = new Date(this.date).getDate();
      var lastdate_day = new Date(this.last_date_11thstudent_regi).getDate();
      var latest_month = new Date(this.date).getMonth()+1;
      var lastdate_month = new Date(this.last_date_11thstudent_regi).getMonth()+1;

      // console.log('latest_day --- ' + latest_day +'lastdate_day ---- '+lastdate_day + 'latest_month --- '+
      // latest_month + 'lastdate_month --- ' + lastdate_month);
      if(latest_month >= lastdate_month )
      if(lastdate_month >= latest_month)
      if( latest_day > lastdate_day)      
      // if( this.latest_date > this.lastdate)
      {
        this.isdisable= true;
        alert('Form Submission Date Is Lapsed');
      }
      else
      {
        this.isdisable= false;
      }
      
      });
      this.studentprofileform.controls.gender.disable();

      // get list of Taluka
      this. getalltalukas();
  }

  // for validation
  get personal() { return this.studentprofileform.controls; }
// This event listener is stimulated whenever the user press the "Refresh" button
  refresh()
  {
    // form.captcha_text = "";
    // let refreshArr = [];
    for (let j = 1; j <= 5; j++) {
      
    this.refreshArr.push(this.alphaNums[Math.floor(Math.random() * this.alphaNums.length)]);
    // this.emptyArr.splice(0,this.emptyArr.length);
    
    }

    this.captcha = this.refreshArr.join('');
    this.refreshArr.splice(0,this.refreshArr.length)
    // console.log(this.captcha);


  }

  // select subjects
  selected(event:any)
  {
    this.selectedsubject1 = event.target.value;
    // alert(this.selectedLevel)
  }
  selectedsubjtwo(event:any)
  {
    this.selectedsubject2 = event.target.value;
    // alert(this.selectedLevel)
  }
  selectedsubjthree(event:any)
  {
    this.selectedsubject3 = event.target.value;
    // this.subj_array.push(this.selectedsubject3);
    // if(this.selectedsubject3 == this.selectedsubject2)
    // {
    //     alert("Please Select Another Subject For Subject Set 3")
    // }
    // alert(this.selectedLevel)
  }
  selectedsubjfour(event:any)
  {
    this.selectedsubject4 = event.target.value;
    // if(this.selectedsubject4 == this.selectedsubject3 || this.selectedsubject4 == this.selectedsubject2)
    // {
    //     alert("Please Select Another Subject For Subject Set 4")
    // }
  }
  selectedsubjfive(event:any)
  {
    this.selectedsubject5 = event.target.value;
    // alert(this.selectedLevel)
    // if(this.selectedsubject5 == this.selectedsubject4 || 
    //    this.selectedsubject5 == this.selectedsubject3||
    //    this.selectedsubject5 == this.selectedsubject2)
    // {
    //     alert("Please Select Another Subject For Subject Set 5")
    // }
  }
  selectedsubjsix(event:any)
  {
    this.selectedsubject6 = event.target.value;
    // alert(this.selectedLevel)
    // if(this.selectedsubject6 == this.selectedsubject5 ||
    //   this.selectedsubject4 == this.selectedsubject6  || 
    //   this.selectedsubject6 == this.selectedsubject3 ||
    //    this.selectedsubject6 == this.selectedsubject2)
    // {
    //     alert("Please Select Another Subject For Subject Set 6")
    // }
  }
  // for validattion
  allowmobileno(e: { which: any; keyCode: any; }) {		
		const charCode = e.which ? e.which : e.keyCode;
		
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			this.mobmsg = '<span class="mobmsg">OOPs! Only Numbers are allow</span>';
		}
    else
    {
      this.mobmsg = '<span class="mobmsg"></span>';
    }
  }
  // allowNumericDigitsOnlyOnKeyUp(e: { which: any; keyCode: any; }) {		
	// 	const charCode = e.which ? e.which : e.keyCode;
		
	// 	if (charCode > 31 && (charCode < 48 || charCode > 57)) {
	// 		this.pinmsg = '<span class="pinmsg">OOPs! Only Numbers are allow</span>';
	// 	}
  //   else
  //   {
  //     this.pinmsg = '<span class="pinmsg"></span>';
  //   }
  // }

  allowNumericDigitsOnlyOnKeyUp(e: { which: any; keyCode: any; }) {		
    const charCode = e.which ? e.which : e.keyCode;
    
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      this.msg = '<span class="pinmsg">OOPs! Only Numbers are allow</span>';
      return false;
    }
    else
    {
      this.msg = '<span class="pinmsg"></span>';
      return true;
    }
  }
  
  allowNumeric(e: { which: any; keyCode: any; }) {		
		const charCode = e.which ? e.which : e.keyCode;
	

		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			this.conmsgs = '<span class="msg">OOPs! Only Numbers are allow</span>';
		}
    else
    {
      this.conmsgs = '<span class="msg"></span>';
    }
  }

  allowNumericforaadhar(e: { which: any; keyCode: any; }) {		
		const charCode = e.which ? e.which : e.keyCode;
		
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			this.aadharmsg = '<span class="aadharmsg">OOPs! Only Numbers are allow</span>';
      return false;
		}
    else
    {
      this.aadharmsg = '<span class="aadharmsg"></span>';
      return true;
    }
  }

  allowNumericDfordistance(e: { which: any; keyCode: any; }) {		
		const charCode = e.which ? e.which : e.keyCode;
		
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      
			this.distmsg = '<span class="distmsg">OOPs! Only Numbers are allow</span>';
      return false;
		}
    else
    {
      this.distmsg = '<span class="distmsg"></span>';
      return true;
    }
  }

  allowNumericDigitschool(e: { which: any; keyCode: any; }) {		
		const charCode = e.which ? e.which : e.keyCode;
		
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      
			this.schoolmsg = '<span class="schoolmsg">OOPs! Only Numbers are allow</span>';
      return false;
       
		}
    else
    {
      this.schoolmsg = '<span class="schoolmsg"></span>';
      return true;
    }
  }
  keyPressAlphanumeric(event: { keyCode: number; preventDefault: () => void; }) {

    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z]/.test(inp)) {
      // return true;
      this.alphmsg = '<span class="msg"></span>';
    } else {
      event.preventDefault();
      // return false;
     
      this.alphmsg = '<span class="msg">OOPs! Only Alphabetic are allow</span>';
    }
  }
  // select stream
  streamChanged(value: any)
  {
    this.streamvalue = value;
    console.log(this.streamvalue);
  }

  // select board

  boardChanged(value: any)
  {
    this.boardvalue = value;
    // console.log(this.boardvalue);
    if(this.studentprofileform.controls.boardname.value == 'other'){
      this.studentprofileform.controls['otherboardname'].setValidators(Validators.required);                   
      this.studentprofileform.controls['eligibilitynumber'].setValidators(Validators.required);                   
      this.studentprofileform.controls['marksheet'].setValidators(Validators.required);                   
      this.studentprofileform.controls['exam'].clearValidators();
      this.studentprofileform.controls['exam'].updateValueAndValidity();
      this.studentprofileform.controls['exam'].reset();

      this.studentprofileform.controls['seat'].clearValidators();
      this.studentprofileform.controls['seat'].updateValueAndValidity();
      this.studentprofileform.controls['seat'].reset();
      }
      else {              
        this.studentprofileform.controls['seat'].setValidators(Validators.required);
        this.studentprofileform.controls['exam'].setValidators(Validators.required);
        this.studentprofileform.controls['otherboardname'].clearValidators();
        this.studentprofileform.controls['otherboardname'].updateValueAndValidity();
        this.studentprofileform.controls['otherboardname'].reset();
        this.studentprofileform.controls['eligibilitynumber'].clearValidators();
        this.studentprofileform.controls['eligibilitynumber'].updateValueAndValidity();
        this.studentprofileform.controls['eligibilitynumber'].reset();
        this.studentprofileform.controls['marksheet'].clearValidators();
        this.studentprofileform.controls['marksheet'].updateValueAndValidity();
        this.studentprofileform.controls['marksheet'].reset();
      }
    
    
  }

  // upload photo and signature and marksheet
  onmarksheetSelect(event:any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      // this.studentprofileform.controls.photo.setValue(file);

      const fsize = file.size;
      console.log(fsize);
                const photofile = Math.round((fsize / 1024));
                // The size of the file.
                if (photofile >= 2048) {
                    alert(
                      "File too Big, please select a file less than 2mb");
                } else {
                  this.studentprofileform.controls.marksheet.setValue(file);
                }

    }
  }
  onPhotoSelect(event:any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      // this.studentprofileform.controls.photo.setValue(file);

      const fsize = file.size;
      console.log(fsize);
                const photofile = Math.round((fsize / 1024));
                // The size of the file.
                if (photofile >= 2048) {
                    alert(
                      "File too Big, please select a file less than 2mb");
                } else {
                  this.studentprofileform.controls.photo.setValue(file);
                }

    }
  }

  onsignatureSelect(event:any)
  { 
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      // this.studentprofileform.controls.signature.setValue(file);

      const fsize = file.size;
      // console.log(fsize);
                const signfile = Math.round((fsize / 1024));
                // The size of the file.
                if (signfile >= 2048) {
                    alert(
                      "File too Big, please select a file less than 4mb");
                }  else {
                  this.studentprofileform.controls.signature.setValue(file);
                }
    }

  }


  // onPhotoSubmit() {
  //   // this.savedata();
  //   const formData = new FormData();
  
  //   this.getstudentform();
  //   // this.getstudentclassForm();
  //   this.getselectedsubject();
  // }

  onsavedata(){
    let seql = this.service.post('/StudentApi11/emailcheck', {email:this.studentprofileform.controls.email.value,standard:"11th"});
    seql
        .subscribe((res:any) => {
          if(res['status'] == 'success'){
            if(res['Data'][0]['studentid'] != this.studID){
              alert('This email is already exists')
            }
            else{
              this.savedata();
            }
          }
          else{
            this.savedata();
          }
            //  this.submitted=true;
        }, err => {
            alert("Error");
        }); 
  }

  // save and submit candidate information
  savedata(){

      //  for subject

      let subject : {  subject_one:any,subject_two:any,subject_three:any,subject_four:any,
        subject_five:any,subject_six:any,subject_seven:any,subject_eight:any,
        subject_nine:any,subject_ten:any,subject_eleven:any} =
       {
           
            subject_one:this.studentprofileform.controls.subject_one.value,
            subject_two:this.studentprofileform.controls.subject_two.value,
            subject_three:this.studentprofileform.controls.subject_three.value,
            subject_four:this.studentprofileform.controls.subject_four.value,
            subject_five:this.studentprofileform.controls.subject_five.value,
            subject_six:this.studentprofileform.controls.subject_six.value,
            subject_seven:this.studentprofileform.controls.subject_seven.value,
            subject_eight:this.studentprofileform.controls.subject_eight.value,
            subject_nine:this.studentprofileform.controls.subject_nine.value,
            subject_ten:this.studentprofileform.controls.subject_ten.value,
            subject_eleven:this.studentprofileform.controls.subject_eleven.value
       }
       this.subj_array=[];
       this.subjectlist = [subject.subject_one,subject.subject_two,subject.subject_three,subject.subject_four,subject.subject_five,subject.subject_six,
      subject.subject_seven,subject.subject_eight,subject.subject_nine,subject.subject_ten,subject.subject_eleven];
  
       let sorted_arr = this.subjectlist.slice().sort();
       for (let i = 0; i < sorted_arr.length - 1; i++) {
        if (sorted_arr[i + 1] == sorted_arr[i]) {
          this.subj_array.push(sorted_arr[i]);//push nonunique subject to array
        }

      }

      if(this.subj_array.length == 0)
      {
        
         let user: {studentid:any,stname:any,ftname:any,mtname:any,dob:any,gender:any,email:any,mobile_no:any,alt_mobile_no:any,
            aadhar:any,caste:any,street:any,locality:any,city:any,state:any,taluka:any,district:any,landmark:any,pincode:any,
            house_ownership:any,internet_coverage:any,distance_coverage:any,access_to_smart_phone:any,access_internet:any,
            namemismatchflag:any,
            security_ques:any,security_ans:any,fathers_qualification:any,fathers_job:any,
            fathers_annual_income:any,mothers_qualification:any,mothers_job:any,mothers_annual_income:any,
            distance_of_school_to_house:any,mode_of_transport:any,boardname:any,otherboardname:any,eligibilitynumber:any
            // photo:any,
            // signature:any,
           
            application_status:any,
            seat:any,
            exam:any
          }  = {
                studentid:this.studID,
                stname:this.studentprofileform.controls.stname.value,
                ftname:this.studentprofileform.controls.ftname.value,
                mtname:this.studentprofileform.controls.mtname.value,
                dob:this.studentprofileform.controls.dob.value,
                gender:this.studentprofileform.controls.gender.value,
                email:this.studentprofileform.controls.email.value,
                mobile_no:this.studentprofileform.controls.mobile_no.value,
                alt_mobile_no:this.studentprofileform.controls.alt_mobile_no.value,
                aadhar:this.studentprofileform.controls.aadhar.value,
                caste:this.studentprofileform.controls.caste.value,
                street:this.studentprofileform.controls.street.value,
                locality:this.studentprofileform.controls.locality.value,
                city:this.studentprofileform.controls.city.value,
                state:this.studentprofileform.controls.state.value,
                taluka:this.studentprofileform.controls.taluka.value,
                district:this.studentprofileform.controls.district.value,
                landmark:this.studentprofileform.controls.landmark.value,
                pincode:this.studentprofileform.controls.pincode.value,
                house_ownership:this.studentprofileform.controls.house_ownership.value,
                internet_coverage:this.studentprofileform.controls.internet_coverage.value,
                distance_coverage:this.studentprofileform.controls.distance_coverage.value,
                access_to_smart_phone:this.studentprofileform.controls.access_to_smart_phone.value,
                access_internet:this.studentprofileform.controls.access_internet.value,
                distance_of_school_to_house:this.studentprofileform.controls.distance_of_school_to_house.value,
                mode_of_transport:this.studentprofileform.controls.mode_of_transport.value,
                // password:this.studentprofileform.controls.password.value,
                // c_password:this.studentprofileform.controls.c_password.value,
                security_ques:this.studentprofileform.controls.security_ques.value,
                security_ans:this.studentprofileform.controls.security_ans.value,
                fathers_qualification:this.studentprofileform.controls.fathers_qualification.value,
                fathers_job:this.studentprofileform.controls.fathers_job.value,
                fathers_annual_income:this.studentprofileform.controls.fathers_annual_income.value,
                mothers_qualification:this.studentprofileform.controls.mothers_qualification.value,
                mothers_job:this.studentprofileform.controls.mothers_job.value,
                mothers_annual_income:this.studentprofileform.controls.mothers_annual_income.value,
                boardname:this.studentprofileform.controls.boardname.value,
                otherboardname:this.studentprofileform.controls.otherboardname.value,
                eligibilitynumber:this.studentprofileform.controls.eligibilitynumber.value,
                seat:this.studentprofileform.controls.seat.value,
                exam:this.studentprofileform.controls.exam.value,
                // photo:this.studentprofileform.controls.photo.value,
                // signature:this.studentprofileform.controls.signature.value,
                namemismatchflag:"Yes",
                application_status:"Pending"
                
           }
console.log(user);
//  alert("Information Saved");
  

let boarddata :{seat:any,exam:any}={
seat :this.studentprofileform.controls.seat.value,
exam:this.studentprofileform.controls.exam.value
}
// alert(boarddata.seat);
if(boarddata.seat == '' || boarddata.seat==null )
{
  let subject : { studentid:any,stream:any,course:any, subject_one:any,subject_two:any,subject_three:any,subject_four:any,
    subject_five:any,subject_six:any,subject_seven:any,subject_eight:any,
    subject_nine:any,subject_ten:any,subject_eleven:any,submission_status:any} =
   {
        studentid:this.studID,
        stream:this.studentprofileform.controls.stream.value,
        course:this.studentprofileform.controls.course.value,
        subject_one:this.studentprofileform.controls.subject_one.value,
        subject_two:this.studentprofileform.controls.subject_two.value,
        subject_three:this.studentprofileform.controls.subject_three.value,
        subject_four:this.studentprofileform.controls.subject_four.value,
        subject_five:this.studentprofileform.controls.subject_five.value,
        subject_six:this.studentprofileform.controls.subject_six.value,
        subject_seven:this.studentprofileform.controls.subject_seven.value,
        subject_eight:this.studentprofileform.controls.subject_eight.value,
        subject_nine:this.studentprofileform.controls.subject_nine.value,
        subject_ten:this.studentprofileform.controls.subject_ten.value,
        subject_eleven:this.studentprofileform.controls.subject_eleven.value,
        submission_status:"Pending"
   }
    // alert(subject);
      let seql = this.service.post('/StudentApi11/savesubject', subject);
      seql
          .subscribe(res => {
              console.log(res);
              this.validated_subjects=true;
              //  this.submitted=true;
          }, err => {
              alert("Error");
          }); 
  let stud: {studentid:any,stname:any,ftname:any,mtname:any,dob:any,gender:any,email:any,mobile_no:any,alt_mobile_no:any,aadhar:any,
   caste:any, street:any,locality:any,city:any,state:any,taluka:any,district:any,landmark:any,pincode:any,
    house_ownership:any,internet_coverage:any,distance_coverage:any,access_to_smart_phone:any,access_internet:any,
    boardname:any,otherboardname:any,eligibilitynumber:any
    security_ques:any,security_ans:any,fathers_qualification:any,fathers_job:any,
    fathers_annual_income:any,mothers_qualification:any,mothers_job:any,mothers_annual_income:any,
    distance_of_school_to_house:any,mode_of_transport:any,
    namemismatchflag:any,
    application_status:any,
    seat:any,
    exam:any
    
  }
    
   = {
        studentid:this.studID,
        stname:this.studentprofileform.controls.stname.value,
        ftname:this.studentprofileform.controls.ftname.value,
        mtname:this.studentprofileform.controls.mtname.value,
        dob:this.studentprofileform.controls.dob.value,
        gender:this.studentprofileform.controls.gender.value,
        email:this.studentprofileform.controls.email.value,
        mobile_no:this.studentprofileform.controls.mobile_no.value,
        alt_mobile_no:this.studentprofileform.controls.alt_mobile_no.value,
        aadhar:this.studentprofileform.controls.aadhar.value,
        caste:this.studentprofileform.controls.caste.value,
        street:this.studentprofileform.controls.street.value,
        locality:this.studentprofileform.controls.locality.value,
        city:this.studentprofileform.controls.city.value,
        state:this.studentprofileform.controls.state.value,
        taluka:this.studentprofileform.controls.taluka.value,
        district:this.studentprofileform.controls.district.value,
        landmark:this.studentprofileform.controls.landmark.value,
        pincode:this.studentprofileform.controls.pincode.value,
        house_ownership:this.studentprofileform.controls.house_ownership.value,
        internet_coverage:this.studentprofileform.controls.internet_coverage.value,
        distance_coverage:this.studentprofileform.controls.distance_coverage.value,
        access_to_smart_phone:this.studentprofileform.controls.access_to_smart_phone.value,
        access_internet:this.studentprofileform.controls.access_internet.value,
        distance_of_school_to_house:this.studentprofileform.controls.distance_of_school_to_house.value,
        mode_of_transport:this.studentprofileform.controls.mode_of_transport.value,
        // password:this.studentprofileform.controls.password.value,
        // c_password:this.studentprofileform.controls.c_password.value,
        security_ques:this.studentprofileform.controls.security_ques.value,
        security_ans:this.studentprofileform.controls.security_ans.value,
        fathers_qualification:this.studentprofileform.controls.fathers_qualification.value,
        fathers_job:this.studentprofileform.controls.fathers_job.value,
        fathers_annual_income:this.studentprofileform.controls.fathers_annual_income.value,
        mothers_qualification:this.studentprofileform.controls.mothers_qualification.value,
        mothers_job:this.studentprofileform.controls.mothers_job.value,
        mothers_annual_income:this.studentprofileform.controls.mothers_annual_income.value,
        boardname:this.studentprofileform.controls.boardname.value,
        otherboardname:this.studentprofileform.controls.otherboardname.value,
        eligibilitynumber:this.studentprofileform.controls.eligibilitynumber.value,
        namemismatchflag:"No",        
        application_status:"Pending",
        seat:this.studentprofileform.controls.seat.value,
        exam:this.studentprofileform.controls.exam.value,
                // is_loggin:'1',
       
   }
              // this.isdisable= true;
  
 let seq = this.service.post('/StudentApi11/savecandidatedata', stud);
 seq
     .subscribe(res => {
         console.log(res);
         alert("Information Saved");
         window.location.reload();
     }, err => {
         alert("Error");
     });  
}
else
{
  // alert('else');
  let boardseq = this.service.post('/StudentApi11/searchsscrecord', boarddata);
boardseq.subscribe((res:any)=>{
  this.boardinfo = res['Data'];
  this.boardstatus = res['status'];
  console.log(this.boardinfo);
  if(this.boardstatus == 'false')
  {
    alert('You Are not goa board student');
    // return;
    window.location.reload();
  }
  else
  {
   this.Stname = this.boardinfo[0].Stname;
  console.log(user.stname);
  if(this.Stname !== user.stname)
  { 
    if(confirm('Enter Name and on certificate name are not matched.Do You Want to Continue Submit Information'))
    {
      // alert('ok');
      let subject : { studentid:any,stream:any,course:any, subject_one:any,subject_two:any,subject_three:any,subject_four:any,
        subject_five:any,subject_six:any,subject_seven:any,subject_eight:any,
        subject_nine:any,subject_ten:any,subject_eleven:any,submission_status:any} =
       {
            studentid:this.studID,
            stream:this.studentprofileform.controls.stream.value,
            course:this.studentprofileform.controls.course.value,
            subject_one:this.studentprofileform.controls.subject_one.value,
            subject_two:this.studentprofileform.controls.subject_two.value,
            subject_three:this.studentprofileform.controls.subject_three.value,
            subject_four:this.studentprofileform.controls.subject_four.value,
            subject_five:this.studentprofileform.controls.subject_five.value,
            subject_six:this.studentprofileform.controls.subject_six.value,
            subject_seven:this.studentprofileform.controls.subject_seven.value,
            subject_eight:this.studentprofileform.controls.subject_eight.value,
            subject_nine:this.studentprofileform.controls.subject_nine.value,
            subject_ten:this.studentprofileform.controls.subject_ten.value,
            subject_eleven:this.studentprofileform.controls.subject_eleven.value,
            submission_status:"Pending"
       }
        // alert(subject);
     let seql = this.service.post('/StudentApi11/savesubject', subject);
     seql
         .subscribe(res => {
             console.log(res);
             this.validated_subjects=true;
            //  this.submitted=true;
         }, err => {
             alert("Error");
         }); 
      
      // this.isdisable= true;
      
     let seq = this.service.post('/StudentApi11/savecandidatedata', user);
     seq
         .subscribe(res => {
             console.log(res);
             alert("Information Saved");
             window.location.reload();
         }, err => {
             alert("Error");
         });  

      
    }
    else
    {
      // alert('not ok');

      let stud: {studentid:any,stname:any,ftname:any,mtname:any,dob:any,gender:any,email:any,mobile_no:any,alt_mobile_no:any,aadhar:any,
       caste:any, street:any,locality:any,city:any,state:any,taluka:any,district:any,landmark:any,pincode:any,
        house_ownership:any,internet_coverage:any,distance_coverage:any,access_to_smart_phone:any,access_internet:any,
        boardname:any,otherboardname:any,eligibilitynumber:any
        security_ques:any,security_ans:any,fathers_qualification:any,fathers_job:any,
        fathers_annual_income:any,mothers_qualification:any,mothers_job:any,mothers_annual_income:any,
        distance_of_school_to_house:any,mode_of_transport:any,
        namemismatchflag:any,
        application_status:any,
        seat:any,
        exam:any
        
      }
        
       = {
            studentid:this.studID,
            stname:this.studentprofileform.controls.stname.value,
            ftname:this.studentprofileform.controls.ftname.value,
            mtname:this.studentprofileform.controls.mtname.value,
            dob:this.studentprofileform.controls.dob.value,
            gender:this.studentprofileform.controls.gender.value,
            email:this.studentprofileform.controls.email.value,
            mobile_no:this.studentprofileform.controls.mobile_no.value,
            alt_mobile_no:this.studentprofileform.controls.alt_mobile_no.value,
            aadhar:this.studentprofileform.controls.aadhar.value,
            caste:this.studentprofileform.controls.caste.value,
            street:this.studentprofileform.controls.street.value,
            locality:this.studentprofileform.controls.locality.value,
            city:this.studentprofileform.controls.city.value,
            state:this.studentprofileform.controls.state.value,
            taluka:this.studentprofileform.controls.taluka.value,
            district:this.studentprofileform.controls.district.value,
            landmark:this.studentprofileform.controls.landmark.value,
            pincode:this.studentprofileform.controls.pincode.value,
            house_ownership:this.studentprofileform.controls.house_ownership.value,
            internet_coverage:this.studentprofileform.controls.internet_coverage.value,
            distance_coverage:this.studentprofileform.controls.distance_coverage.value,
            access_to_smart_phone:this.studentprofileform.controls.access_to_smart_phone.value,
            access_internet:this.studentprofileform.controls.access_internet.value,
            distance_of_school_to_house:this.studentprofileform.controls.distance_of_school_to_house.value,
            mode_of_transport:this.studentprofileform.controls.mode_of_transport.value,
            // password:this.studentprofileform.controls.password.value,
            // c_password:this.studentprofileform.controls.c_password.value,
            security_ques:this.studentprofileform.controls.security_ques.value,
            security_ans:this.studentprofileform.controls.security_ans.value,
            fathers_qualification:this.studentprofileform.controls.fathers_qualification.value,
            fathers_job:this.studentprofileform.controls.fathers_job.value,
            fathers_annual_income:this.studentprofileform.controls.fathers_annual_income.value,
            mothers_qualification:this.studentprofileform.controls.mothers_qualification.value,
            mothers_job:this.studentprofileform.controls.mothers_job.value,
            mothers_annual_income:this.studentprofileform.controls.mothers_annual_income.value,
            boardname:this.studentprofileform.controls.boardname.value,
            otherboardname:this.studentprofileform.controls.otherboardname.value,
            eligibilitynumber:this.studentprofileform.controls.eligibilitynumber.value,
            namemismatchflag:"No",
            
            application_status:"Pending",
            seat:this.studentprofileform.controls.seat.value,
            exam:this.studentprofileform.controls.exam.value
            // is_loggin:'1',
           
       }
                  // this.isdisable= true;
      
     let seq = this.service.post('/StudentApi11/savecandidatedata', stud);
     seq
         .subscribe(res => {
             console.log(res);
             alert("Information Saved");
             window.location.reload();
         }, err => {
             alert("Error");
         });  

         let subject : { studentid:any,stream:any,course:any, subject_one:any,subject_two:any,subject_three:any,subject_four:any,
          subject_five:any,subject_six:any,subject_seven:any,subject_eight:any,
          subject_nine:any,subject_ten:any,subject_eleven:any,submission_status:any} =
         {
              studentid:this.studID,
              stream:this.studentprofileform.controls.stream.value,
              course:this.studentprofileform.controls.course.value,
              subject_one:this.studentprofileform.controls.subject_one.value,
              subject_two:this.studentprofileform.controls.subject_two.value,
              subject_three:this.studentprofileform.controls.subject_three.value,
              subject_four:this.studentprofileform.controls.subject_four.value,
              subject_five:this.studentprofileform.controls.subject_five.value,
              subject_six:this.studentprofileform.controls.subject_six.value,
              subject_seven:this.studentprofileform.controls.subject_seven.value,
              subject_eight:this.studentprofileform.controls.subject_eight.value,
              subject_nine:this.studentprofileform.controls.subject_nine.value,
              subject_ten:this.studentprofileform.controls.subject_ten.value,
              subject_eleven:this.studentprofileform.controls.subject_eleven.value,
              submission_status:"Pending"
         }
          // alert(subject);
       let seql = this.service.post('/StudentApi11/savesubject', subject);
       seql
           .subscribe(res => {
               console.log(res);
               this.validated_subjects=true;
              //  this.submitted=true;
           }, err => {
               alert("Error");
           }); 

    }

   
  }
  
}
 

});
}

// this.isdisable= true;
//  let seq = this.service.post('/StudentApi11/submitstudentform', user);
//  seq
//      .subscribe(res => {
//          console.log(res);
//          alert("Form Submited");
//          this.router.navigate(['/candidateprofile']);
// }, err       => {
//          alert("Error");
//      });  

           //  upload documents with marksheet
  const formData = new FormData();

  formData.append('photo', this.studentprofileform.controls.photo.value);
  formData.append('id', this.studID);
  this.photo_upload_success=true;
  this.service.upload11thstudentFile(formData).subscribe(
    (res:any) => {
     this.uploadResponse = res;
        console.log(res);
        // this.getstudentform();
    },
    (err: any) => {  
      console.log(err);
    }
  );

  formData.append('signature', this.studentprofileform.controls.signature.value);
  formData.append('id', this.studID);
  this.signature_upload_success=true;
  this.service.upload11thstudentFile(formData).subscribe(
    (res:any) => {
     // this.uploadResponse = res;
        console.log(res);
        // this.getstudentform();
    },
    (err: any) => {  
      console.log(err);
    }
  );

  
   formData.append('marksheet', this.studentprofileform.controls.marksheet.value);
   formData.append('id', this.studID);
   this.signature_upload_success=true;
   this.service.upload11thstudentFile(formData).subscribe(
     (res:any) => {
      // this.uploadResponse = res;
         console.log(res);
         // this.getstudentform();
     },
     (err: any) => {  
       console.log(err);
     }
   );


           

      }
      // else if(this.subj_array == ',,,,,,,,,' || this.subj_array == ',,,,,,,')
      else if(this.subj_array == '' || this.subj_array == '')
      {
        
        let user: {studentid:any,stname:any,ftname:any,mtname:any,dob:any,gender:any,email:any,mobile_no:any,alt_mobile_no:any,
          aadhar:any,caste:any,street:any,locality:any,city:any,state:any,taluka:any,district:any,landmark:any,pincode:any,
          house_ownership:any,internet_coverage:any,distance_coverage:any,access_to_smart_phone:any,access_internet:any,
          namemismatchflag:any,
          security_ques:any,security_ans:any,fathers_qualification:any,fathers_job:any,
          fathers_annual_income:any,mothers_qualification:any,mothers_job:any,mothers_annual_income:any,
          distance_of_school_to_house:any,mode_of_transport:any,boardname:any,otherboardname:any,eligibilitynumber:any
          // photo:any,
          // signature:any,
         
          application_status:any,
          seat:any,
          exam:any
          
        }  = {
              studentid:this.studID,
              stname:this.studentprofileform.controls.stname.value,
              ftname:this.studentprofileform.controls.ftname.value,
              mtname:this.studentprofileform.controls.mtname.value,
              dob:this.studentprofileform.controls.dob.value,
              gender:this.studentprofileform.controls.gender.value,
              email:this.studentprofileform.controls.email.value,
              mobile_no:this.studentprofileform.controls.mobile_no.value,
              alt_mobile_no:this.studentprofileform.controls.alt_mobile_no.value,
              aadhar:this.studentprofileform.controls.aadhar.value,
              caste:this.studentprofileform.controls.caste.value,
              street:this.studentprofileform.controls.street.value,
              locality:this.studentprofileform.controls.locality.value,
              city:this.studentprofileform.controls.city.value,
              state:this.studentprofileform.controls.state.value,
              taluka:this.studentprofileform.controls.taluka.value,
              district:this.studentprofileform.controls.district.value,
              landmark:this.studentprofileform.controls.landmark.value,
              pincode:this.studentprofileform.controls.pincode.value,
              house_ownership:this.studentprofileform.controls.house_ownership.value,
              internet_coverage:this.studentprofileform.controls.internet_coverage.value,
              distance_coverage:this.studentprofileform.controls.distance_coverage.value,
              access_to_smart_phone:this.studentprofileform.controls.access_to_smart_phone.value,
              access_internet:this.studentprofileform.controls.access_internet.value,
              distance_of_school_to_house:this.studentprofileform.controls.distance_of_school_to_house.value,
              mode_of_transport:this.studentprofileform.controls.mode_of_transport.value,
              // password:this.studentprofileform.controls.password.value,
              // c_password:this.studentprofileform.controls.c_password.value,
              security_ques:this.studentprofileform.controls.security_ques.value,
              security_ans:this.studentprofileform.controls.security_ans.value,
              fathers_qualification:this.studentprofileform.controls.fathers_qualification.value,
              fathers_job:this.studentprofileform.controls.fathers_job.value,
              fathers_annual_income:this.studentprofileform.controls.fathers_annual_income.value,
              mothers_qualification:this.studentprofileform.controls.mothers_qualification.value,
              mothers_job:this.studentprofileform.controls.mothers_job.value,
              mothers_annual_income:this.studentprofileform.controls.mothers_annual_income.value,
              boardname:this.studentprofileform.controls.boardname.value,
              otherboardname:this.studentprofileform.controls.otherboardname.value,
              eligibilitynumber:this.studentprofileform.controls.eligibilitynumber.value,
              // photo:this.studentprofileform.controls.photo.value,
              // signature:this.studentprofileform.controls.signature.value,
              namemismatchflag:"Yes",
              application_status:"Pending",
              seat:this.studentprofileform.controls.seat.value,
              exam:this.studentprofileform.controls.exam.value
              
         }
console.log(user);
//  alert("Information Saved");

let boarddata :{seat:any,exam:any}={
seat :this.studentprofileform.controls.seat.value,
exam:this.studentprofileform.controls.exam.value
}
if(boarddata.seat == '' || boarddata.seat==null )
{
  let stud: {studentid:any,stname:any,ftname:any,mtname:any,dob:any,gender:any,email:any,mobile_no:any,alt_mobile_no:any,aadhar:any,
    caste:any,street:any,locality:any,city:any,state:any,taluka:any,district:any,landmark:any,pincode:any,
    house_ownership:any,internet_coverage:any,distance_coverage:any,access_to_smart_phone:any,access_internet:any,
    boardname:any,otherboardname:any,eligibilitynumber:any
    security_ques:any,security_ans:any,fathers_qualification:any,fathers_job:any,
    fathers_annual_income:any,mothers_qualification:any,mothers_job:any,mothers_annual_income:any,
    distance_of_school_to_house:any,mode_of_transport:any,
    namemismatchflag:any,
    application_status:any,
    seat:any,
    exam:any
    
  }
    
   = {
        studentid:this.studID,
        stname:this.studentprofileform.controls.stname.value,
        ftname:this.studentprofileform.controls.ftname.value,
        mtname:this.studentprofileform.controls.mtname.value,
        dob:this.studentprofileform.controls.dob.value,
        gender:this.studentprofileform.controls.gender.value,
        email:this.studentprofileform.controls.email.value,
        mobile_no:this.studentprofileform.controls.mobile_no.value,
        alt_mobile_no:this.studentprofileform.controls.alt_mobile_no.value,
        aadhar:this.studentprofileform.controls.aadhar.value,
        caste:this.studentprofileform.controls.caste.value,
        street:this.studentprofileform.controls.street.value,
        locality:this.studentprofileform.controls.locality.value,
        city:this.studentprofileform.controls.city.value,
        state:this.studentprofileform.controls.state.value,
        taluka:this.studentprofileform.controls.taluka.value,
        district:this.studentprofileform.controls.district.value,
        landmark:this.studentprofileform.controls.landmark.value,
        pincode:this.studentprofileform.controls.pincode.value,
        house_ownership:this.studentprofileform.controls.house_ownership.value,
        internet_coverage:this.studentprofileform.controls.internet_coverage.value,
        distance_coverage:this.studentprofileform.controls.distance_coverage.value,
        access_to_smart_phone:this.studentprofileform.controls.access_to_smart_phone.value,
        access_internet:this.studentprofileform.controls.access_internet.value,
        distance_of_school_to_house:this.studentprofileform.controls.distance_of_school_to_house.value,
        mode_of_transport:this.studentprofileform.controls.mode_of_transport.value,
        // password:this.studentprofileform.controls.password.value,
        // c_password:this.studentprofileform.controls.c_password.value,
        security_ques:this.studentprofileform.controls.security_ques.value,
        security_ans:this.studentprofileform.controls.security_ans.value,
        fathers_qualification:this.studentprofileform.controls.fathers_qualification.value,
        fathers_job:this.studentprofileform.controls.fathers_job.value,
        fathers_annual_income:this.studentprofileform.controls.fathers_annual_income.value,
        mothers_qualification:this.studentprofileform.controls.mothers_qualification.value,
        mothers_job:this.studentprofileform.controls.mothers_job.value,
        mothers_annual_income:this.studentprofileform.controls.mothers_annual_income.value,
        boardname:this.studentprofileform.controls.boardname.value,
        otherboardname:this.studentprofileform.controls.otherboardname.value,
        eligibilitynumber:this.studentprofileform.controls.eligibilitynumber.value,
        namemismatchflag:"No",
        
        application_status:"Pending",
        seat:this.studentprofileform.controls.seat.value,
        exam:this.studentprofileform.controls.exam.value,
        // is_loggin:'1',
       
   }
              // this.isdisable= true;
  
 let seq = this.service.post('/StudentApi11/savecandidatedata', stud);
 seq
     .subscribe(res => {
         console.log(res);
         alert("Information Saved");
         window.location.reload();
     }, err => {
         alert("Error");
     });  

     let subject : { studentid:any,stream:any,course:any, subject_one:any,subject_two:any,subject_three:any,subject_four:any,
      subject_five:any,subject_six:any,subject_seven:any,subject_eight:any,
      subject_nine:any,subject_ten:any,subject_eleven:any,submission_status:any} =
     {
          studentid:this.studID,
          stream:this.studentprofileform.controls.stream.value,
          course:this.studentprofileform.controls.course.value,
          subject_one:this.studentprofileform.controls.subject_one.value,
          subject_two:this.studentprofileform.controls.subject_two.value,
          subject_three:this.studentprofileform.controls.subject_three.value,
          subject_four:this.studentprofileform.controls.subject_four.value,
          subject_five:this.studentprofileform.controls.subject_five.value,
          subject_six:this.studentprofileform.controls.subject_six.value,
          subject_seven:this.studentprofileform.controls.subject_seven.value,
          subject_eight:this.studentprofileform.controls.subject_eight.value,
          subject_nine:this.studentprofileform.controls.subject_nine.value,
          subject_ten:this.studentprofileform.controls.subject_ten.value,
          subject_eleven:this.studentprofileform.controls.subject_eleven.value,
          submission_status:"Pending"
     }
      // alert(subject);
   let seql = this.service.post('/StudentApi11/savesubject', subject);
   seql
       .subscribe(res => {
           console.log(res);
           this.validated_subjects=true;
          //  this.submitted=true;
       }, err => {
           alert("Error");
       }); 
}
else
{
  let boardseq = this.service.post('/StudentApi11/searchsscrecord', boarddata);
  boardseq.subscribe((res:any)=>{
  this.boardinfo = res['Data'];
  console.log(this.boardinfo);
  if(this.boardinfo == undefined)
  {
      alert('Worng Information Provided..!');
      window.location.reload();
  }
  else
  {
    this.Stname = this.boardinfo[0].Stname;
    console.log(user.stname);
  
    if(this.Stname !== user.stname)
    { 
      if(confirm('Enter Name and on certificate name are not matched.Do You Want to Continue Submit Information'))
      {
        // alert('ok');
    
        
        this.isdisable= true;
        
       let seq = this.service.post('/StudentApi11/savecandidatedata', user);
       seq
           .subscribe(res => {
               console.log(res);
               alert("Information Saved");
               window.location.reload();
           }, err => {
               alert("Error");
           });  
    
        
      }
      else
      {
        // alert('not ok');
        let stud: {studentid:any,stname:any,ftname:any,mtname:any,dob:any,gender:any,email:any,mobile_no:any,alt_mobile_no:any,aadhar:any,
          street:any,locality:any,city:any,state:any,taluka:any,district:any,landmark:any,pincode:any,
          house_ownership:any,internet_coverage:any,distance_coverage:any,access_to_smart_phone:any,access_internet:any,
          boardname:any,otherboardname:any,eligibilitynumber:any
          security_ques:any,security_ans:any,fathers_qualification:any,fathers_job:any,
          fathers_annual_income:any,mothers_qualification:any,mothers_job:any,mothers_annual_income:any,
          distance_of_school_to_house:any,mode_of_transport:any,
          namemismatchflag:any,
          application_status:any,
          seat:any,
          exam:any          
        }
          
         = {
              studentid:this.studID,
              stname:this.studentprofileform.controls.stname.value,
              ftname:this.studentprofileform.controls.ftname.value,
              mtname:this.studentprofileform.controls.mtname.value,
              dob:this.studentprofileform.controls.dob.value,
              gender:this.studentprofileform.controls.gender.value,
              email:this.studentprofileform.controls.email.value,
              mobile_no:this.studentprofileform.controls.mobile_no.value,
              alt_mobile_no:this.studentprofileform.controls.alt_mobile_no.value,
              aadhar:this.studentprofileform.controls.aadhar.value,
              street:this.studentprofileform.controls.street.value,
              locality:this.studentprofileform.controls.locality.value,
              city:this.studentprofileform.controls.city.value,
              state:this.studentprofileform.controls.state.value,
              taluka:this.studentprofileform.controls.taluka.value,
              district:this.studentprofileform.controls.district.value,
              landmark:this.studentprofileform.controls.landmark.value,
              pincode:this.studentprofileform.controls.pincode.value,
              house_ownership:this.studentprofileform.controls.house_ownership.value,
              internet_coverage:this.studentprofileform.controls.internet_coverage.value,
              distance_coverage:this.studentprofileform.controls.distance_coverage.value,
              access_to_smart_phone:this.studentprofileform.controls.access_to_smart_phone.value,
              access_internet:this.studentprofileform.controls.access_internet.value,
              distance_of_school_to_house:this.studentprofileform.controls.distance_of_school_to_house.value,
              mode_of_transport:this.studentprofileform.controls.mode_of_transport.value,
              // password:this.studentprofileform.controls.password.value,
              // c_password:this.studentprofileform.controls.c_password.value,
              security_ques:this.studentprofileform.controls.security_ques.value,
              security_ans:this.studentprofileform.controls.security_ans.value,
              fathers_qualification:this.studentprofileform.controls.fathers_qualification.value,
              fathers_job:this.studentprofileform.controls.fathers_job.value,
              fathers_annual_income:this.studentprofileform.controls.fathers_annual_income.value,
              mothers_qualification:this.studentprofileform.controls.mothers_qualification.value,
              mothers_job:this.studentprofileform.controls.mothers_job.value,
              mothers_annual_income:this.studentprofileform.controls.mothers_annual_income.value,
              boardname:this.studentprofileform.controls.boardname.value,
              otherboardname:this.studentprofileform.controls.otherboardname.value,
              eligibilitynumber:this.studentprofileform.controls.eligibilitynumber.value,
              namemismatchflag:"No",
              
              application_status:"Pending",
              seat:this.studentprofileform.controls.seat.value,
              exam:this.studentprofileform.controls.exam.value
              // is_loggin:'1',
             
         }
                    // this.isdisable= true;
        
       let seq = this.service.post('/StudentApi11/savecandidatedata', stud);
       seq
           .subscribe(res => {
               console.log(res);
               alert("Information Saved");
               window.location.reload();
           }, err => {
               alert("Error");
           });  
           let subject : { studentid:any,stream:any,course:any, subject_one:any,subject_two:any,subject_three:any,subject_four:any,
            subject_five:any,subject_six:any,subject_seven:any,subject_eight:any,
            subject_nine:any,subject_ten:any,subject_eleven:any,submission_status:any} =
           {
                studentid:this.studID,
                stream:this.studentprofileform.controls.stream.value,
                course:this.studentprofileform.controls.course.value,
                subject_one:this.studentprofileform.controls.subject_one.value,
                subject_two:this.studentprofileform.controls.subject_two.value,
                subject_three:this.studentprofileform.controls.subject_three.value,
                subject_four:this.studentprofileform.controls.subject_four.value,
                subject_five:this.studentprofileform.controls.subject_five.value,
                subject_six:this.studentprofileform.controls.subject_six.value,
                subject_seven:this.studentprofileform.controls.subject_seven.value,
                subject_eight:this.studentprofileform.controls.subject_eight.value,
                subject_nine:this.studentprofileform.controls.subject_nine.value,
                subject_ten:this.studentprofileform.controls.subject_ten.value,
                subject_eleven:this.studentprofileform.controls.subject_eleven.value,
                submission_status:"Pending"
           }
            // alert(subject);
         let seql = this.service.post('/StudentApi11/savesubject', subject);
         seql
             .subscribe(res => {
                 console.log(res);
                 this.validated_subjects=true;
                //  this.submitted=true;
             }, err => {
                 alert("Error");
             }); 
  
    
      }
    
     
    }

  }
  
  
  
  });
  
  // this.isdisable= true;
  //  let seq = this.service.post('/StudentApi11/submitstudentform', user);
  //  seq
  //      .subscribe(res => {
  //          console.log(res);
  //          alert("Form Submited");
  //          this.router.navigate(['/candidateprofile']);
  // }, err       => {
  //          alert("Error");
  //      });  
  
           //  upload documents with marksheet
  const formData = new FormData();
  
  formData.append('photo', this.studentprofileform.controls.photo.value);
  formData.append('id', this.studID);
  this.photo_upload_success=true;
  this.service.upload11thstudentFile(formData).subscribe(
    (res:any) => {
     this.uploadResponse = res;
        console.log(res);
        // this.getstudentform();
    },
    (err: any) => {  
      console.log(err);
    }
  );
  
  formData.append('signature', this.studentprofileform.controls.signature.value);
  formData.append('id', this.studID);
  this.signature_upload_success=true;
  this.service.upload11thstudentFile(formData).subscribe(
    (res:any) => {
     // this.uploadResponse = res;
        console.log(res);
        // this.getstudentform();
    },
    (err: any) => {  
      console.log(err);
    }
  );
  
  
   formData.append('marksheet', this.studentprofileform.controls.marksheet.value);
   formData.append('id', this.studID);
   this.signature_upload_success=true;
   this.service.upload11thstudentFile(formData).subscribe(
     (res:any) => {
      // this.uploadResponse = res;
         console.log(res);
         // this.getstudentform();
     },
     (err: any) => {  
       console.log(err);
     }
   );
}

      }
      else
      {
        alert(this.subj_array+ " has been selected multiple times from provided options. Please correct the selection and click Save")
        this.validated_subjects=false;
      }
      

   
    }
    onstudentprofilesubmit(){
      let seql = this.service.post('/StudentApi11/emailcheck', {email:this.studentprofileform.controls.email.value,standard:"11th"});
      seql
          .subscribe((res:any) => {
            console.log(this.studID)
            if(res['status'] == 'success'){
              if(res['Data'][0]['studentid'] != this.studID){
                alert('This email is already exists')
              }
              else{
                this.studentprofilesubmit();
              }
            }
            else{
              this.studentprofilesubmit();
            }
              //  this.submitted=true;
          }, err => {
              alert("Error");
          }); 
    }
    studentprofilesubmit()
  {
      
    // var chk=this.isDOBvalid();
    // if(chk==false){
    //     alert("Your Date Of Birth  is not as per criteria, \nFor 11th, age should be greater than 14 years, \n Please Contact School Admin, and correct your Date Of Birth.");
    //     return;
    // }
    this.submitted = true;
   

        if (this.studentprofileform.invalid)
         {
          alert("Fill All Details");
          return;    
        }
          // for subject
             
                let subject : {  subject_one:any,subject_two:any,subject_three:any,subject_four:any,
                  subject_five:any,subject_six:any,subject_seven:any,subject_eight:any,
                  subject_nine:any,subject_ten:any,subject_eleven:any} =
                 {
                     
                      subject_one:this.studentprofileform.controls.subject_one.value,
                      subject_two:this.studentprofileform.controls.subject_two.value,
                      subject_three:this.studentprofileform.controls.subject_three.value,
                      subject_four:this.studentprofileform.controls.subject_four.value,
                      subject_five:this.studentprofileform.controls.subject_five.value,
                      subject_six:this.studentprofileform.controls.subject_six.value,
                      subject_seven:this.studentprofileform.controls.subject_seven.value,
                      subject_eight:this.studentprofileform.controls.subject_eight.value,
                      subject_nine:this.studentprofileform.controls.subject_nine.value,
                      subject_ten:this.studentprofileform.controls.subject_ten.value,
                      subject_eleven:this.studentprofileform.controls.subject_eleven.value
                 }
                 this.subj_array=[];
                 this.subjectlist = [subject.subject_one,subject.subject_two,subject.subject_three,subject.subject_four,subject.subject_five,subject.subject_six,
                subject.subject_seven,subject.subject_eight,subject.subject_nine,subject.subject_ten,subject.subject_eleven];
            
                 let sorted_arr = this.subjectlist.slice().sort();
                 for (let i = 0; i < sorted_arr.length - 1; i++) {
                  if (sorted_arr[i + 1] == sorted_arr[i]) {
                    this.subj_array.push(sorted_arr[i]);//push nonunique subject to array
                  }
    
                }
    
                if(this.subj_array.length == 0)
                {
                  
    
        let user: {studentid:any,stname:any,ftname:any,mtname:any,dob:any,gender:any,email:any,mobile_no:any,alt_mobile_no:any,
          aadhar:any,caste:any,street:any,locality:any,city:any,state:any,taluka:any,district:any,landmark:any,pincode:any,
          house_ownership:any,internet_coverage:any,distance_coverage:any,access_to_smart_phone:any,access_internet:any,
          boardname:any,otherboardname:any,eligibilitynumber:any,
          security_ques:any,security_ans:any,fathers_qualification:any,fathers_job:any,
          fathers_annual_income:any,mothers_qualification:any,mothers_job:any,mothers_annual_income:any,
          distance_of_school_to_house:any,mode_of_transport:any,
          namemismatchflag:any,
          application_status:any,date:any,
          seat:any,
          exam:any
          
        }
          
         = {
              studentid:this.studID,
              stname:this.studentprofileform.controls.stname.value,
              ftname:this.studentprofileform.controls.ftname.value,
              mtname:this.studentprofileform.controls.mtname.value,
              dob:this.studentprofileform.controls.dob.value,
              gender:this.studentprofileform.controls.gender.value,
              email:this.studentprofileform.controls.email.value,
              mobile_no:this.studentprofileform.controls.mobile_no.value,
              alt_mobile_no:this.studentprofileform.controls.alt_mobile_no.value,
              aadhar:this.studentprofileform.controls.aadhar.value,
              caste:this.studentprofileform.controls.caste.value,
              street:this.studentprofileform.controls.street.value,
              locality:this.studentprofileform.controls.locality.value,
              city:this.studentprofileform.controls.city.value,
              state:this.studentprofileform.controls.state.value,
              taluka:this.studentprofileform.controls.taluka.value,
              district:this.studentprofileform.controls.district.value,
              landmark:this.studentprofileform.controls.landmark.value,
              pincode:this.studentprofileform.controls.pincode.value,
              house_ownership:this.studentprofileform.controls.house_ownership.value,
              internet_coverage:this.studentprofileform.controls.internet_coverage.value,
              distance_coverage:this.studentprofileform.controls.distance_coverage.value,
              access_to_smart_phone:this.studentprofileform.controls.access_to_smart_phone.value,
              access_internet:this.studentprofileform.controls.access_internet.value,
              distance_of_school_to_house:this.studentprofileform.controls.distance_of_school_to_house.value,
              mode_of_transport:this.studentprofileform.controls.mode_of_transport.value,
              // password:this.studentprofileform.controls.password.value,
              // c_password:this.studentprofileform.controls.c_password.value,
              security_ques:this.studentprofileform.controls.security_ques.value,
              security_ans:this.studentprofileform.controls.security_ans.value,
              fathers_qualification:this.studentprofileform.controls.fathers_qualification.value,
              fathers_job:this.studentprofileform.controls.fathers_job.value,
              fathers_annual_income:this.studentprofileform.controls.fathers_annual_income.value,
              mothers_qualification:this.studentprofileform.controls.mothers_qualification.value,
              mothers_job:this.studentprofileform.controls.mothers_job.value,
              mothers_annual_income:this.studentprofileform.controls.mothers_annual_income.value,
              boardname:this.studentprofileform.controls.boardname.value,
              otherboardname:this.studentprofileform.controls.otherboardname.value,
              eligibilitynumber:this.studentprofileform.controls.eligibilitynumber.value,
              namemismatchflag:"Yes",
              
              application_status:"Submitted",
              // is_loggin:'1',
              date: this.latest_date,
              seat:this.studentprofileform.controls.seat.value,
              exam:this.studentprofileform.controls.exam.value,
         }
    
         console.log(user);
        //  alert("Information Saved");
    
        let boarddata :{seat:any,exam:any}={
          seat :this.studentprofileform.controls.seat.value,
          exam:this.studentprofileform.controls.exam.value
        }
        console.log(boarddata);
        if(boarddata.seat == '' || boarddata.seat==null )
        {
          // alert('seat no not enter');
          let stud: {studentid:any,stname:any,ftname:any,mtname:any,dob:any,gender:any,email:any,mobile_no:any,alt_mobile_no:any,aadhar:any,
            caste:any,street:any,locality:any,city:any,state:any,taluka:any,district:any,landmark:any,pincode:any,
            house_ownership:any,internet_coverage:any,distance_coverage:any,access_to_smart_phone:any,access_internet:any,
            boardname:any,otherboardname:any,eligibilitynumber:any
            security_ques:any,security_ans:any,fathers_qualification:any,fathers_job:any,
            fathers_annual_income:any,mothers_qualification:any,mothers_job:any,mothers_annual_income:any,
            distance_of_school_to_house:any,mode_of_transport:any,
            namemismatchflag:any,
            application_status:any,date:any,
            seat:any,
            exam:any
          }
            
           = {
                studentid:this.studID,
                stname:this.studentprofileform.controls.stname.value,
                ftname:this.studentprofileform.controls.ftname.value,
                mtname:this.studentprofileform.controls.mtname.value,
                dob:this.studentprofileform.controls.dob.value,
                gender:this.studentprofileform.controls.gender.value,
                email:this.studentprofileform.controls.email.value,
                mobile_no:this.studentprofileform.controls.mobile_no.value,
                alt_mobile_no:this.studentprofileform.controls.alt_mobile_no.value,
                aadhar:this.studentprofileform.controls.aadhar.value,
                caste:this.studentprofileform.controls.caste.value,
                street:this.studentprofileform.controls.street.value,
                locality:this.studentprofileform.controls.locality.value,
                city:this.studentprofileform.controls.city.value,
                state:this.studentprofileform.controls.state.value,
                taluka:this.studentprofileform.controls.taluka.value,
                district:this.studentprofileform.controls.district.value,
                landmark:this.studentprofileform.controls.landmark.value,
                pincode:this.studentprofileform.controls.pincode.value,
                house_ownership:this.studentprofileform.controls.house_ownership.value,
                internet_coverage:this.studentprofileform.controls.internet_coverage.value,
                distance_coverage:this.studentprofileform.controls.distance_coverage.value,
                access_to_smart_phone:this.studentprofileform.controls.access_to_smart_phone.value,
                access_internet:this.studentprofileform.controls.access_internet.value,
                distance_of_school_to_house:this.studentprofileform.controls.distance_of_school_to_house.value,
                mode_of_transport:this.studentprofileform.controls.mode_of_transport.value,
                // password:this.studentprofileform.controls.password.value,
                // c_password:this.studentprofileform.controls.c_password.value,
                security_ques:this.studentprofileform.controls.security_ques.value,
                security_ans:this.studentprofileform.controls.security_ans.value,
                fathers_qualification:this.studentprofileform.controls.fathers_qualification.value,
                fathers_job:this.studentprofileform.controls.fathers_job.value,
                fathers_annual_income:this.studentprofileform.controls.fathers_annual_income.value,
                mothers_qualification:this.studentprofileform.controls.mothers_qualification.value,
                mothers_job:this.studentprofileform.controls.mothers_job.value,
                mothers_annual_income:this.studentprofileform.controls.mothers_annual_income.value,
                boardname:this.studentprofileform.controls.boardname.value,
                otherboardname:this.studentprofileform.controls.otherboardname.value,
                eligibilitynumber:this.studentprofileform.controls.eligibilitynumber.value,
                namemismatchflag:"No",
                
                application_status:"Submitted",
                // is_loggin:'1',
                date: this.latest_date,
                seat:this.studentprofileform.controls.seat.value,
                exam:this.studentprofileform.controls.exam.value,
           }
                      this.isdisable= true;
            let seq = this.service.post('/StudentApi11/submitstudentform', stud);
            seq
                .subscribe(res => {
                    console.log(res);
                    alert("Congratulations, your form has been submitted successfully!!");
                  
                    // this.router.onSameUrlNavigation = 'reload'; 
                    this.router.navigate(['/viewcandidateprofile']);
                   
                    // window.location.reload();
            }, err       => {
                    alert("Error");
                }); 


                let subject : { studentid:any,stream:any,course:any, subject_one:any,subject_two:any,subject_three:any,subject_four:any,
                  subject_five:any,subject_six:any,subject_seven:any,subject_eight:any,
                  subject_nine:any,subject_ten:any,subject_eleven:any,submission_status:any} =
                 {
                      studentid:this.studID,
                      stream:this.studentprofileform.controls.stream.value,
                      course:this.studentprofileform.controls.course.value,
                      subject_one:this.studentprofileform.controls.subject_one.value,
                      subject_two:this.studentprofileform.controls.subject_two.value,
                      subject_three:this.studentprofileform.controls.subject_three.value,
                      subject_four:this.studentprofileform.controls.subject_four.value,
                      subject_five:this.studentprofileform.controls.subject_five.value,
                      subject_six:this.studentprofileform.controls.subject_six.value,
                      subject_seven:this.studentprofileform.controls.subject_seven.value,
                      subject_eight:this.studentprofileform.controls.subject_eight.value,
                      subject_nine:this.studentprofileform.controls.subject_nine.value,
                      subject_ten:this.studentprofileform.controls.subject_ten.value,
                      subject_eleven:this.studentprofileform.controls.subject_eleven.value,
                      submission_status:"Submitted"
                 }
                 //  alert("Subject Saved");
               let seql = this.service.post('/StudentApi11/savesubject', subject);
               seql
                   .subscribe(res => {
                       console.log(res);
                       this.validated_subjects=true;
                       this.submitted = true;
                       
                   }, err => {
                       alert("Error");
                   }); 
  
                   

                const formData = new FormData();
    
                formData.append('photo', this.studentprofileform.controls.photo.value);
                formData.append('id', this.studID);
                this.photo_upload_success=true;
                this.service.upload11thstudentFile(formData).subscribe(
                  (res:any) => {
                   this.uploadResponse = res;
                      console.log(res);
                      // this.getstudentform();
                  },
                  (err: any) => {  
                    console.log(err);
                  }
                );
        
                formData.append('signature', this.studentprofileform.controls.signature.value);
                formData.append('id', this.studID);
                this.signature_upload_success=true;
                this.service.upload11thstudentFile(formData).subscribe(
                  (res:any) => {
                   // this.uploadResponse = res;
                      console.log(res);
                      // this.getstudentform();
                  },
                  (err: any) => {  
                    console.log(err);
                  }
                );
        
                
                 formData.append('marksheet', this.studentprofileform.controls.marksheet.value);
                 formData.append('id', this.studID);
                 this.signature_upload_success=true;
                 this.service.upload11thstudentFile(formData).subscribe(
                   (res:any) => {
                    // this.uploadResponse = res;
                       console.log(res);
                       // this.getstudentform();
                   },
                   (err: any) => {  
                     console.log(err);
                   }
                 );
        
        
        }
        else{
          let boardseq = this.service.post('/StudentApi11/searchsscrecord', boarddata);
          boardseq.subscribe((res:any)=>{
              this.boardinfo = res['Data'];
              this.boardstatus = res['status'];
              console.log(this.boardstatus);

              // this.Stname = this.boardinfo[0].Stname;
              // console.log(this.Stname);
              
             if(this.boardstatus == 'false')
             {
               alert('You Are not goa board student');
               window.location.reload();
             }
             else
             {
              this.Stname = this.boardinfo[0].Stname; 
              if(this.Stname !== user.stname)
              { 
                // alert('in if condition');
                if(confirm('Enter Name and on certificate name are not matched.Do You Want to Continue Submit Information'))
                {
                  // alert('ok');
      
                  
                  // this.isdisable= true;
                  let seq = this.service.post('/StudentApi11/submitstudentform', user);
                  seq
                      .subscribe(res => {
                          console.log(res);
                          alert("Congratulations, your form has been submitted successfully!!");
                          // this.router.onSameUrlNavigation = 'reload'; 
                          this.router.navigate(['/viewcandidateprofile']);
                  }, err       => {
                          alert("Error");
                      }); 

                      let subject : { studentid:any,stream:any,course:any, subject_one:any,subject_two:any,subject_three:any,subject_four:any,
                        subject_five:any,subject_six:any,subject_seven:any,subject_eight:any,
                        subject_nine:any,subject_ten:any,subject_eleven:any,submission_status:any} =
                       {
                            studentid:this.studID,
                            stream:this.studentprofileform.controls.stream.value,
                            course:this.studentprofileform.controls.course.value,
                            subject_one:this.studentprofileform.controls.subject_one.value,
                            subject_two:this.studentprofileform.controls.subject_two.value,
                            subject_three:this.studentprofileform.controls.subject_three.value,
                            subject_four:this.studentprofileform.controls.subject_four.value,
                            subject_five:this.studentprofileform.controls.subject_five.value,
                            subject_six:this.studentprofileform.controls.subject_six.value,
                            subject_seven:this.studentprofileform.controls.subject_seven.value,
                            subject_eight:this.studentprofileform.controls.subject_eight.value,
                            subject_nine:this.studentprofileform.controls.subject_nine.value,
                            subject_ten:this.studentprofileform.controls.subject_ten.value,
                            subject_eleven:this.studentprofileform.controls.subject_eleven.value,
                            submission_status:"Submitted"
                       }
                       //  alert("Subject Saved");
                     let seql = this.service.post('/StudentApi11/savesubject', subject);
                     seql
                         .subscribe(res => {
                             console.log(res);
                             this.validated_subjects=true;
                             this.submitted = true;
                             
                         }, err => {
                             alert("Error");
                         }); 
        
                         
                      const formData = new FormData();
    
                      formData.append('photo', this.studentprofileform.controls.photo.value);
                      formData.append('id', this.studID);
                      this.photo_upload_success=true;
                      this.service.upload11thstudentFile(formData).subscribe(
                        (res:any) => {
                        this.uploadResponse = res;
                            console.log(res);
                            // this.getstudentform();
                        },
                        (err: any) => {  
                          console.log(err);
                        }
                      );
              
                      formData.append('signature', this.studentprofileform.controls.signature.value);
                      formData.append('id', this.studID);
                      this.signature_upload_success=true;
                      this.service.upload11thstudentFile(formData).subscribe(
                        (res:any) => {
                        // this.uploadResponse = res;
                            console.log(res);
                            // this.getstudentform();
                        },
                        (err: any) => {  
                          console.log(err);
                        }
                      );
              
                    
              
                  
                }
                else
                {
                  // alert('not ok');
                  let stud: {studentid:any,stname:any,ftname:any,mtname:any,dob:any,gender:any,email:any,mobile_no:any,alt_mobile_no:any,aadhar:any,
                  caste:any, street:any,locality:any,city:any,state:any,taluka:any,district:any,landmark:any,pincode:any,
                    house_ownership:any,internet_coverage:any,distance_coverage:any,access_to_smart_phone:any,access_internet:any,
                    boardname:any,otherboardname:any,eligibilitynumber:any
                    security_ques:any,security_ans:any,fathers_qualification:any,fathers_job:any,
                    fathers_annual_income:any,mothers_qualification:any,mothers_job:any,mothers_annual_income:any,
                    distance_of_school_to_house:any,mode_of_transport:any,
                    namemismatchflag:any,
                    application_status:any,date:any
                    seat:any,
                    exam:any
                  }
                    
                  = {
                        studentid:this.studID,
                        stname:this.studentprofileform.controls.stname.value,
                        ftname:this.studentprofileform.controls.ftname.value,
                        mtname:this.studentprofileform.controls.mtname.value,
                        dob:this.studentprofileform.controls.dob.value,
                        gender:this.studentprofileform.controls.gender.value,
                        email:this.studentprofileform.controls.email.value,
                        mobile_no:this.studentprofileform.controls.mobile_no.value,
                        alt_mobile_no:this.studentprofileform.controls.alt_mobile_no.value,
                        aadhar:this.studentprofileform.controls.aadhar.value,
                        caste:this.studentprofileform.controls.caste.value,
                        street:this.studentprofileform.controls.street.value,
                        locality:this.studentprofileform.controls.locality.value,
                        city:this.studentprofileform.controls.city.value,
                        state:this.studentprofileform.controls.state.value,
                        taluka:this.studentprofileform.controls.taluka.value,
                        district:this.studentprofileform.controls.district.value,
                        landmark:this.studentprofileform.controls.landmark.value,
                        pincode:this.studentprofileform.controls.pincode.value,
                        house_ownership:this.studentprofileform.controls.house_ownership.value,
                        internet_coverage:this.studentprofileform.controls.internet_coverage.value,
                        distance_coverage:this.studentprofileform.controls.distance_coverage.value,
                        access_to_smart_phone:this.studentprofileform.controls.access_to_smart_phone.value,
                        access_internet:this.studentprofileform.controls.access_internet.value,
                        distance_of_school_to_house:this.studentprofileform.controls.distance_of_school_to_house.value,
                        mode_of_transport:this.studentprofileform.controls.mode_of_transport.value,
                        // password:this.studentprofileform.controls.password.value,
                        // c_password:this.studentprofileform.controls.c_password.value,
                        security_ques:this.studentprofileform.controls.security_ques.value,
                        security_ans:this.studentprofileform.controls.security_ans.value,
                        fathers_qualification:this.studentprofileform.controls.fathers_qualification.value,
                        fathers_job:this.studentprofileform.controls.fathers_job.value,
                        fathers_annual_income:this.studentprofileform.controls.fathers_annual_income.value,
                        mothers_qualification:this.studentprofileform.controls.mothers_qualification.value,
                        mothers_job:this.studentprofileform.controls.mothers_job.value,
                        mothers_annual_income:this.studentprofileform.controls.mothers_annual_income.value,
                        boardname:this.studentprofileform.controls.boardname.value,
                        otherboardname:this.studentprofileform.controls.otherboardname.value,
                        eligibilitynumber:this.studentprofileform.controls.eligibilitynumber.value,
                        namemismatchflag:"No",
                        
                        application_status:"Submitted",
                        // is_loggin:'1',
                        date: this.latest_date,
                        seat:this.studentprofileform.controls.seat.value,
                        exam:this.studentprofileform.controls.exam.value,
                  }
                              this.isdisable= true;
                    let seq = this.service.post('/StudentApi11/submitstudentform', stud);
                    seq
                        .subscribe(res => {
                            console.log(res);
                            alert("Congratulations, your form has been submitted successfully!!");
                            // this.router.onSameUrlNavigation = 'reload'; 
                            this.router.navigate(['/viewcandidateprofile']);
                    }, err       => {
                            alert("Error");
                        }); 

                        let subject : { studentid:any,stream:any,course:any, subject_one:any,subject_two:any,subject_three:any,subject_four:any,
                          subject_five:any,subject_six:any,subject_seven:any,subject_eight:any,
                          subject_nine:any,subject_ten:any,subject_eleven:any,submission_status:any} =
                         {
                              studentid:this.studID,
                              stream:this.studentprofileform.controls.stream.value,
                              course:this.studentprofileform.controls.course.value,
                              subject_one:this.studentprofileform.controls.subject_one.value,
                              subject_two:this.studentprofileform.controls.subject_two.value,
                              subject_three:this.studentprofileform.controls.subject_three.value,
                              subject_four:this.studentprofileform.controls.subject_four.value,
                              subject_five:this.studentprofileform.controls.subject_five.value,
                              subject_six:this.studentprofileform.controls.subject_six.value,
                              subject_seven:this.studentprofileform.controls.subject_seven.value,
                              subject_eight:this.studentprofileform.controls.subject_eight.value,
                              subject_nine:this.studentprofileform.controls.subject_nine.value,
                              subject_ten:this.studentprofileform.controls.subject_ten.value,
                              subject_eleven:this.studentprofileform.controls.subject_eleven.value,
                              submission_status:"Submitted"
                         }
                         //  alert("Subject Saved");
                       let seql = this.service.post('/StudentApi11/savesubject', subject);
                       seql
                           .subscribe(res => {
                               console.log(res);
                               this.validated_subjects=true;
                               this.submitted = true;
                               
                           }, err => {
                               alert("Error");
                           }); 
          
                           const formData = new FormData();
    
                           formData.append('photo', this.studentprofileform.controls.photo.value);
                           formData.append('id', this.studID);
                           this.photo_upload_success=true;
                           this.service.upload11thstudentFile(formData).subscribe(
                             (res:any) => {
                             this.uploadResponse = res;
                                 console.log(res);
                                 // this.getstudentform();
                             },
                             (err: any) => {  
                               console.log(err);
                             }
                           );
                   
                           formData.append('signature', this.studentprofileform.controls.signature.value);
                           formData.append('id', this.studID);
                           this.signature_upload_success=true;
                           this.service.upload11thstudentFile(formData).subscribe(
                             (res:any) => {
                             // this.uploadResponse = res;
                                 console.log(res);
                                 // this.getstudentform();
                             },
                             (err: any) => {  
                               console.log(err);
                             }
                           );
                   
                           
                           formData.append('marksheet', this.studentprofileform.controls.marksheet.value);
                           formData.append('id', this.studID);
                           this.signature_upload_success=true;
                           this.service.upload11thstudentFile(formData).subscribe(
                             (res:any) => {
                               // this.uploadResponse = res;
                                 console.log(res);
                                 // this.getstudentform();
                             },
                             (err: any) => {  
                               console.log(err);
                             }
                           );
                      }

                     }
                  
                   }
                });
         
        }
        // this.isdisable= true;
        //  let seq = this.service.post('/StudentApi11/submitstudentform', user);
        //  seq
        //      .subscribe(res => {
        //          console.log(res);
        //          alert("Form Submited");
        //          this.router.navigate(['/candidateprofile']);
        // }, err       => {
        //          alert("Error");
        //      });  
    
                     //  upload documents with marksheet

                     
    
                }
                else{
                  alert(this.subj_array+ " has been selected multiple times from provided options. Please correct the selection and click Save")
                  this.validated_subjects=false;
                }
 
    
  }

  // get all candidate details
  getstudentform()
  {
    let user:{studentid:any}={
      studentid:this.studID
    }
    let seq = this.service.post('/StudentApi11/getstudentForm',user);
     seq.subscribe((res:any) => {
            
             this.userData= res['Data'];
            // console.log(this.userData[0].dob);
            this.dob = this.userData[0].dob;
             this.subjectmap= res['subjectmap'];
            //  console.log(this.userData[0].security_ques);
           
             this.studentprofileform.controls['stname'].setValue(this.userData[0].stname);
             this.studentprofileform.controls['ftname'].setValue(this.userData[0].ftname);
             this.studentprofileform.controls['mtname'].setValue(this.userData[0].mtname);
             this.studentprofileform.controls['dob'].setValue(this.userData[0].dob);
            //  console.log(this.studentprofileform.controls['dob'].setValue(this.userData[0].dob));
             this.studentprofileform.controls['gender'].setValue(this.userData[0].gender);
             this.studentprofileform.controls['email'].setValue(this.userData[0].email);
             this.studentprofileform.controls['mobile_no'].setValue(this.userData[0].mobile_no);
             this.studentprofileform.controls['alt_mobile_no'].setValue(this.userData[0].alt_mobile_no);
             this.studentprofileform.controls['aadhar'].setValue(this.userData[0].aadhar);
             this.studentprofileform.controls['caste'].setValue(this.userData[0].caste);
             this.studentprofileform.controls['street'].setValue(this.userData[0].street);
             this.studentprofileform.controls['locality'].setValue(this.userData[0].locality);
             this.studentprofileform.controls['city'].setValue(this.userData[0].city);
             this.studentprofileform.controls['state'].setValue(this.userData[0].state);
             this.studentprofileform.controls['taluka'].setValue(this.userData[0].taluka);
             this.studentprofileform.controls['district'].setValue(this.userData[0].district);
             this.studentprofileform.controls['landmark'].setValue(this.userData[0].landmark);
             this.studentprofileform.controls['pincode'].setValue(this.userData[0].pincode);
             this.studentprofileform.controls['house_ownership'].setValue(this.userData[0].house_ownership);
             this.studentprofileform.controls['internet_coverage'].setValue(this.userData[0].internet_coverage);
             this.studentprofileform.controls['distance_coverage'].setValue(this.userData[0].distance_coverage);
             this.studentprofileform.controls['access_to_smart_phone'].setValue(this.userData[0].access_to_smart_phone);
             this.studentprofileform.controls['access_internet'].setValue(this.userData[0].access_internet);
             this.studentprofileform.controls['distance_of_school_to_house'].setValue(this.userData[0].distance_of_school_to_house);
             this.studentprofileform.controls['mode_of_transport'].setValue(this.userData[0].mode_of_transport);
             this.studentprofileform.controls['security_ques'].setValue(this.userData[0].security_ques);
             this.studentprofileform.controls['security_ans'].setValue(this.userData[0].security_ans);
             this.studentprofileform.controls['fathers_qualification'].setValue(this.userData[0].fathers_qualification);
             this.studentprofileform.controls['fathers_job'].setValue(this.userData[0].fathers_job);
             this.studentprofileform.controls['fathers_annual_income'].setValue(this.userData[0].fathers_annual_income);
             this.studentprofileform.controls['mothers_qualification'].setValue(this.userData[0].mothers_qualification);
             this.studentprofileform.controls['mothers_job'].setValue(this.userData[0].mothers_job);
             this.studentprofileform.controls['mothers_annual_income'].setValue(this.userData[0].mothers_annual_income);
             this.studentprofileform.controls['boardname'].setValue(this.userData[0].boardname);
             this.studentprofileform.controls['otherboardname'].setValue(this.userData[0].otherboardname);
             this.studentprofileform.controls['eligibilitynumber'].setValue(this.userData[0].eligibilitynumber);
             this.studentprofileform.controls['seat'].setValue(this.userData[0].seat);
             this.studentprofileform.controls['exam'].setValue(this.userData[0].exam);
           
             var photo_upload_path=this.userData[0].photo;
             this.photoinfo = this.userData[0].photo;
            //  photo_upload_path =photo_upload_path.substr(42,photo_upload_path.length);
             this.studentprofileform.controls['photo'].setValue(photo_upload_path); 
             var signature_upload_path=this.userData[0].signature;
             this.signinfo=this.userData[0].signature;
             //  signature_upload_path =signature_upload_path.substr(42,signature_upload_path.length);
             this.studentprofileform.controls['signature'].setValue(signature_upload_path);
             var marksheet_upload_path=this.userData[0].marksheet;
             this.marksinfo=this.userData[0].marksheet;
             //  signature_upload_path =signature_upload_path.substr(42,signature_upload_path.length);
             this.studentprofileform.controls['marksheet'].setValue(marksheet_upload_path);


     });
  }
  // get all selected subject from student

  getstudentclassForm()
  {
    let user:{studentid:any}={
      studentid:this.studID
    }
    let seq = this.service.post('/StudentApi11/getstudentclassForm',user);
     seq.subscribe((res:any) => {
            
            this.studschool= res['studschool'];
             this.studentprofileform.controls['rollno'].setValue(this.studschool[0].rollno);
            this.studentprofileform.controls['division'].setValue(this.studschool[0].division);
            if(this.studschool[0].stream == 'Vocational' || this.studschool[0].stream == 'VOCATIONAL' || this.studschool[0].stream == 'vocational'){
              this.studentprofileform.controls['stream'].setValue('Vocational');
             }
             else
             if(this.studschool[0].stream == 'Science' || this.studschool[0].stream == 'SCIENCE' || this.studschool[0].stream == 'science'){
              this.studentprofileform.controls['stream'].setValue('Science');
             }
             else
             if(this.studschool[0].stream == 'Commerce' || this.studschool[0].stream == 'COMMERCE' || this.studschool[0].stream == 'commerce'){
              this.studentprofileform.controls['stream'].setValue('Commerce');
             }
             else
             if(this.studschool[0].stream == 'Arts' || this.studschool[0].stream == 'ARTS' || this.studschool[0].stream == 'arts'){
              this.studentprofileform.controls['stream'].setValue('Arts');
             }
            this.checkstream =  this.studentprofileform.controls['stream'].value;
           console.log(this.checkstream);
            // this.studentprofileform.controls['course'].setValue(this.studschool[0].course);
            this.coursecode = this.studschool[0].course;
            // this service for coursename
           this.service.post('/StudentApi11/getCoursebycode', {coursecode:this.coursecode}).subscribe((res:any)=>{
             this.coursename = res['Data'];
             console.log(this.coursename)
             this.othercourse = this.coursename[0].subject_name;
             console.log(this.othercourse);
             this.studentprofileform.controls['course'].setValue(this.coursename[0].subject_name);
           });

           this.service.post('/StudentApi11/getSubjectByCoursecode',{coursecode:this.coursecode}).subscribe((res:any) =>{
             this.coursesubj = res['Data'];
             this.subjcode = res['status'];
             this.course1 = this.coursesubj[0].subjectname;
             this.course2 = this.coursesubj[1].subjectname;
             this.course3 = this.coursesubj[2].subjectname;
             this.course4 = this.coursesubj[3].subjectname;
             this.course5 = this.coursesubj[4].subjectname;
             this.course6 = this.coursesubj[5].subjectname;
            //  console.log(this.subjcode);
           })


     });
  }

  
    // get all course

  getselectedsubject()
  {
    let user:{studentid:any}={
      studentid:this.studID
    }
      let seq = this.service.post('/StudentApi11/getstudentselectedsubject',user);
      seq.subscribe((res:any)=>{
        this.subjectmap = res['subjectmap'];
            this.studentprofileform.controls['subject_one'].setValue(this.subjectmap[0].Subject);
             this.studentprofileform.controls['subject_two'].setValue(this.subjectmap[1].Subject);
             this.studentprofileform.controls['subject_three'].setValue(this.subjectmap[2].Subject);
             this.studentprofileform.controls['subject_four'].setValue(this.subjectmap[3].Subject);
             this.studentprofileform.controls['subject_five'].setValue(this.subjectmap[4].Subject);
             this.studentprofileform.controls['subject_six'].setValue(this.subjectmap[5].Subject);
             this.studentprofileform.controls['subject_seven'].setValue(this.subjectmap[6].Subject);
             this.studentprofileform.controls['subject_eight'].setValue(this.subjectmap[7].Subject);
             this.studentprofileform.controls['subject_nine'].setValue(this.subjectmap[8].Subject);
             this.studentprofileform.controls['subject_ten'].setValue(this.subjectmap[9].Subject);
             this.studentprofileform.controls['subject_eleven'].setValue(this.subjectmap[10].Subject);
      })
  }

  // get subjects in dropdown

  getsubjects(){
    let user:{}={
      
    }
    let seq = this.service.post('/StudentApi11/getsubjectlist',user);
    seq.subscribe((res:any) => {
      this.subject = res['Data'];
      this.subject1 =res['Data1'];
      this.subject2 =res['Data2'];
      this.subject3 =res['Data3'];
      this.subject4 =res['Data4'];
      this.subject5 =res['Data5'];
      this.subject6 =res['Data6'];
      this.subject7 =res['Data7'];
      this.subject8 =res['Data8'];
      this.subject9 =res['Data9'];
      this.subject10 =res['Data10'];
      this.subject11 =res['Data11'];
      this.subject12 =res['Data12'];
      // console.log(this.subject);

 });
  }


getcourses()
{
  let corse:{}={

  }
  let seq = this.service.post('/StudentApi11/getallcourses',corse);
  seq.subscribe((res:any) => {
    this.coursesall = res.Data;
    

});
}

isDOBvalid() {
  // alert(dateString);
  var today = new Date();
  var birthDate = new Date(this.studentprofileform.controls.dob.value.split("-").reverse().join("-"));
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
  {
      age--;
  }
      if(age>=14){
        return true;
      }
      else {
        return false;
      }
  }

  getalltalukas(){
      let seq = this.service.post('/BoardApi/getTaluka',{});
      seq.subscribe((res:any)=>{
          if(res.status== "Sucess"){
              this.alltalukas=res.Data;
          }
          else{
            alert("An error occurred while fetching data, Reloading page!!");
            window.location.reload();
          }
      })
  }
  
  getdistrict(taluka:any){
      this.alltalukas.forEach((elem: { taluka_name: any, taluka_district:any }) => {
          if(elem.taluka_name==taluka.value){
            this.studentprofileform.controls['district'].setValue(elem.taluka_district);
          }
      });
  }

}
