
<div class="container-fluied">
  
  <div class="clsUpdateStatus row mt3 ">  
      <div class="col-sm-10 optionWrap1">
        
      </div>       
    <!-- <div class="col-sm-1 optionWrap2">
      <button type="button" class="btn btn-primary btn-sm" (click)="printPage()" ><i class="bi bi-printer-fill"></i> Print</button>
    </div> -->
    <div class="col-sm-1 optionWrap2">
      <button type="button" class="btn btn-primary btn-sm" (click)="SavePDF()" ><i class="bi bi-printer-fill"></i> Download PDF</button>
    </div>
    <!-- <div class="col-sm-1 optionWrap2">
      <button type="button" class="btn btn-primary btn-sm" (click)="exportexcel()" ><i class="bi bi-printer-fill"></i> Download PDF</button>
    </div> -->
    <div class="col-sm-1"></div>
  </div>
</div>


<div class="d-flex flex-row-reverse bd-highlight mb-2">
  <div class="p-2 bd-highlight">
    <select (change)="onTableSizeChange($event)" class="custom-select">
      <option *ngFor="let size of tableSizesArr" [ngValue]="size">
        {{ size }}
      </option>
    </select>
  </div>
</div>
<div class="container-fluied tableBody" >
  <div class="row">
    <div class="col-sm-12 tablescroll" >
      <div id="htmlData" >
        <!-- <h1 style="text-align: center;">Candidate Reports</h1> -->
        <table class="table table-bordered">
          <tr class="headerRow">
            <th class="smallCol">Student Roll No</th>
            <th style="width:250px">Student Full Name<br>Father's Name<br>Mother's Name</th>
            <th>SEX <br>CAT</th>
            <!-- <th>SUB</th> -->
            <th style="width:250px">SUB & Sub Name</th>
            <th>MED</th>
            <th>Year</th>
            <th>D.O.B.<br>Annual Inc<br>Minority</th>
            
        </tr>      
        <tr class="bodyRow" *ngFor="let user of candidate | paginate : {
          itemsPerPage: tableSize,
          currentPage: page,
          totalItems: count
        };">
          <!-- <td class="spltd">
            <p-tableCheckbox [value]="candidate"></p-tableCheckbox>
          </td> -->
          <td class="smallCol">
            <!-- <span class="p-column-title" >Student Roll No</span> -->
              {{user.rollno}}
          </td>
          <td style="width:250px">
              <!-- <span class="p-column-title">Student Full Name<br>Mother's Name<br>Father's Name</span> -->
              - {{user.stname}} <br>
              - {{user.ftname}}<br>
              - {{user.mtname}}<br>
                       
          </td>            
          <td>
              <!-- <span class="p-column-title">SEX <br>CAT</span> -->
              {{user.gender}}<br>    
              <!-- {{candidate.cat}}     -->
          </td>
          <!-- <td>
            <span class="p-column-title">SUB Code</span>
           - {{user.Subject_code_1}}<br> - {{user.Subject_code_2}}<br>
           - {{user.Subject_code_3}}<br> - {{user.Subject_code_4}}<br>
           - {{user.Subject_code_5}}<br> - {{user.Subject_code_6}}<br>
           - {{user.Subject_code_7}}<br> - {{user.Subject_code_8}}<br>
           - {{user.Subject_code_9}}<br> - {{user.Subject_code_10}}<br>
           - {{user.Subject_code_11}}<br>
          </td> -->
          <td style="width:250px;text-align: left;">
              <!-- <span class="p-column-title">Sub Name</span> -->
             {{user.Subject_code_1}} - {{user.Subject_1}}<br> {{user.Subject_code_2}} - {{user.Subject_2}}<br>
             {{user.Subject_code_3}} - {{user.Subject_3}}<br> {{user.Subject_code_4}} - {{user.Subject_4}}<br>
             {{user.Subject_code_5}} - {{user.Subject_5}}<br> {{user.Subject_code_6}} - {{user.Subject_6}}<br>
             {{user.Subject_code_7}} - {{user.Subject_7}}<br> {{user.Subject_code_8}} - {{user.Subject_8}}<br>
             {{user.Subject_code_9}} - {{user.Subject_9}}<br> {{user.Subject_code_10}} - {{user.Subject_10}}<br>
             {{user.Subject_code_11}}  -  {{user.Subject_11}}<br> 
             
          </td>
          <td>
            <!-- <span class="p-column-title">MED</span> -->
            <!-- {{candidate.medium}} -->
            
          </td>
          <td>
            <!-- <span class="p-column-title">Year</span> -->
            <!-- {{candidate.enrollmentyear}} -->
            2021
          </td>
          <td>
            <!-- <span class="p-column-title">D.O.B.<br>Annual Income</span> -->
            {{user.dob}}<br>
            {{user.Annual_Income}}<br>
            <!-- {{candidate.minority}} -->
          </td>
        
      </tr>
        </table>
       
      </div>
      <div class="d-flex justify-content-center">
        <pagination-controls  previousLabel="Prev" nextLabel="Next" (pageChange)="tabSize($event)">
        </pagination-controls>
        <!-- <pagination-controls  previousLabel="All REcord" (pageChange)="Allrecord($event)">
        </pagination-controls> -->
      </div>
    </div>
  </div>
</div>

 <br>
