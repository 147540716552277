<!-- <ng-template #content let-modal>
 
  <div class="modal-body">
    <div class="card">
      <h5 class="card-header cardheader"><i class="bi bi-lock-fill"></i>Change Password
        <button type="button" class="close" aria-label="Close" data-dismiss="modal" (click)='logout()'>
          <span aria-hidden="true">&times;</span>
        </button>
      </h5>
      
      <div class="card-body">
        <form [formGroup]="changpwdform" >
                      
          <div class="form-group row">
            <label for="staticEmail" class="col-sm-4 col-form-label">Current Password</label>
            <div class="form-group col-sm-8 my-2">
              <input type="text" class="form-control" formControlName="password" placeholder="Your Current Password *" value="" />
          </div>
          </div>
          <div class="form-group row">
            <label for="inputPassword" class="col-sm-4 col-form-label">New Password</label>
            <div class="form-group col-sm-8 my-2">
              <input type="password" class="form-control" formControlName="newpassword" placeholder="Your Password *" value="" />
          </div>
          </div>
          <div class="row">
              <label for="inputPassword" class="col-sm-4 col-form-label">Security Pin</label>
              
              <span class="col-sm-6 captcha" id="captcha" style="background-image:url('/assets/img/brand/dot.jpg');background-repeat: no-repeat;width:'180px'">{{captcha}} </span>
              <span class="col-sm-2"> <a (click)="refresh()"> <img src="/assets/img/brand/refresh.png" height="50px" width="50px"></a></span>
             
          </div>
          <div class="form-group row">
                  <label for="inputPassword" class="col-sm-4 col-form-label">Enter Security Pin</label>
                  <div class="form-group col-sm-8 my-2">
                      <input type="text" class="form-control" formControlName="captcha_text" placeholder="Enter Above Captcha" value="" />
                  </div>
                 </div>
              <div class="form-group text-center">
                  <input type="button" class="btnSubmit btn btn-primary" (click)="ONChangepassword(changpwdform.value)" value="Change Password" />
                  
              </div>
              
        </form>
        </div>

        </div>
    
  </div>

</ng-template> -->

 
<div class="container login-container bg-light mb-3">
  <br>
  <div class="row mb-3 justify-content-md-center">
       
      <div class="col-md-8">
          <div class="card">
              <h5 class="card-header cardheader"><i class="bi bi-lock-fill"></i>Change Password</h5>
              <div class="card-body">
                
                  <div class="hsscdiv" >

                    <form [formGroup]="changpwdform" >
                      
                      <div class="form-group row">
                        <label for="staticEmail" class="col-sm-4 col-form-label">Current Password</label>
                        <div class="form-group col-sm-8 my-2">
                          <input type="text" class="form-control" formControlName="password" placeholder="Your Current Password *" value="" />
                      </div>
                      </div>
                      <div class="form-group row">
                        <label for="inputPassword" class="col-sm-4 col-form-label">New Password</label>
                        <div class="form-group col-sm-8 my-2">
                          <input type="password" class="form-control" formControlName="newpassword" placeholder="Your Password *" value="" />
                      </div>
                      </div>
                      <div class="row">
                          <label for="inputPassword" class="col-sm-4 col-form-label">Security Pin</label>
                          
                          <span class="col-sm-6 captcha" id="captcha" style="background-image:url('/assets/img/brand/dot.jpg');background-repeat: no-repeat;width:'157px'">{{captcha}} </span>
                          <span class="col-sm-2 mobref"> <a (click)="refresh()"> <img src="/assets/img/brand/refresh.png" height="50px" width="50px"></a></span>
                         
                      </div>
                      <div class="form-group row">
                              <label for="inputPassword" class="col-sm-4 col-form-label">Enter Security Pin</label>
                              <div class="form-group col-sm-8 my-2">
                                  <input type="text" class="form-control" formControlName="captcha_text" placeholder="Enter Above Captcha" value="" />
                              </div>
                             </div>
                          <div class="form-group text-center">
                              <input type="button " class="col-sm-3 btnSubmit btnSubmit1 btn btn-primary" (click)="ONChangepassword(changpwdform.value)" value="Update" />
                              
                          </div>
                          
                    </form>
                   
                  </div>
                  
                  
              </div>
            </div>
      </div>
     
  </div>

  <div class="row mb-3"></div>
</div>