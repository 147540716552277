import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SchoolserviceService } from '../schoolservice.service';
import { NavbarService } from '../default/navbar/navbar.service';
import { PrimeNGConfig } from 'primeng/api';
import { Table } from 'primeng/table';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import {candidates_reports} from './candidatesReports';
import * as XLSX from 'xlsx';
import  jsPDF from 'jspdf';  
import html2canvas from 'html2canvas';
// import 'jspdf-autotable';
// pdfMake.vfs = pdfFonts.pdfMake.vfs;
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
// import * as htmlToPdfmake from 'html-to-pdfmake';

@Component({
  selector: 'app-school-stud-report',
  templateUrl: './school-stud-report.component.html',
  styleUrls: ['./school-stud-report.component.scss']
})
export class SchoolStudReportComponent implements OnInit {
  candidate!:candidates_reports[];
  // getstudreport!: subjects[];
  
  loading: boolean = true;
  @ViewChild('dt')
  table!: Table;
  
  @ViewChild('htmlData')
  htmlData!: ElementRef;

  schoolid:any;
  enrollmnt: any;
  fileName= 'Report.xlsx';
  alldata: any;
  getalldata: any[]=[];
  getallstname: any[]=[];
  getgender: any[]=[];
  dtOptions: DataTables.Settings = {};
  page = 1;
  count = 0;
  tableSize = 4;
  tableSizesArr = [4, 8, 12,'All'];
  standard: any;

  constructor(public service:SchoolserviceService,public nav:NavbarService,private primengConfig: PrimeNGConfig) { }

  ngOnInit(): void {
    this.schoolid = localStorage.getItem('schoolid');
    this.standard = localStorage.getItem('Standard');
    //console.log(this.standard)
    
    this.nav.hide();
    this.nav.dhide();
    this.nav.adhide();
    this.nav.asdshow();
    this.nav.studhide();
    this.nav.XIstudhide();
    this.nav.aduserhide();
  //   this.service.getcandidateReportLarge().then((candidates:any) => {
  //     this.candidate = candidates;
      
  // });
  this.getreport();
  this.loading = false;
  this.primengConfig.ripple = true;

  this.dtOptions = {
    pagingType: 'full_numbers',
    pageLength: 5,
    processing: true
  };

  }

  tabSize(event:any){
    this.page = event;
    this.getreport();
  
  }  
  
  tableData(event:any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getreport();
   
  }
  onTableSizeChange(event:any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getreport();
  } 
    // getEventValue($event:any) :string {
    //   return $event.target.value;
    // }

getreport()
{
  let schoolinfo:{schoolid:any,standard:any}={
    schoolid: this.schoolid,
    standard:this.standard
  }
  this.service.post('/School/reportDisplay',schoolinfo).subscribe((res:any)=>{
      this.candidate = res.Data;
      // this.getstudreport = candidate.Subjects;
      // //console.log(this.getstudreport);
  })
}

printPage() {
  window.print();
}

// generatepdf(){

//   let schoolinfo:{schoolid:any}={
//     schoolid: this.schoolid
//   }
//   this.service.post('/School/reportDisplay',schoolinfo).subscribe((res:any)=>{
//       this.alldata = res.Data;
//       // this.getstudreport = candidate.Subjects;
//       //console.log(this.alldata);
//       this.getalldata = [];
//       this.getallstname = [];
//       this.getgender = [];
//       this.getallstname = [];
//       this.getallstname = [];
//       this.getallstname = [];
//       for (let i = 0; i < this.alldata.length; i++) {
//         this.getalldata.push(this.alldata[i].rollno);
//         this.getallstname.push(this.alldata[i].stname + this.alldata[i].ftname + this.alldata[i].mtname);
//         this.getgender.push(this.alldata[i].gender);
//       }

     
//       // this.alldata.forEach((element: any) => {
//       //   //console.log(element)
       
//       // });

//       // setRowData(this.alldata.slice(1500,2000));
//       //console.log(this.getalldata)
//       //console.log(this.getallstname)
//       const documentDefinition = {   content: [
//         {
    
          
         
//           table: {
//             // headers are automatically repeated if the table spans over multiple pages
//             // you can declare how many rows should be treated as headers
//             headerRows: 1,
//             widths: [ '*',100,'auto', '*','*','*','*' ],
    
         

//             body: [
              
//               [ 'Student Roll No', 'Student/Mother/Father Name', 'Gender', 'Sub Code','SubName','Year','DOB & Annual Income' ],
//               [ this.getalldata, this.getallstname, this.getgender, 'Value 4','value1', 'Value 2', 'Value 3' ],
//             ]
//           },
          
//           layout: 	{
//             hLineWidth: function (i: any, node: any) {
//             return (i === 0 || i === node.table.body.length) ? 2 : 1;
//           },
//           vLineWidth: function (i: any, node: any)  {
//             return (i === 0 || i === node.table.widths.length) ? 2 : 1;
//           },
//           hLineColor: function (i: any, node: any) {
//             return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
//           },
//           vLineColor: function (i: any, node: any)  {
//             return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
//           } // optional
//         }
//         }
//       ] };
//       pdfMake.createPdf(documentDefinition).open();
//   })



//  }

public SavePDF(): void {  

  let rec = document.getElementById('htmlData') as HTMLElement;
    
  html2canvas(rec).then(canvas => {
      
      let fileWidth = 208;
      let fileHeight = canvas.height * fileWidth / canvas.width;
      
      const FILEURI = canvas.toDataURL('image/png')
      let PDF = new jsPDF('p', 'mm', 'a4');
      let position = 0;
      PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight)
      
      PDF.save('School_report.pdf');
  });      
}  

  exportexcel():void 
{
   /* table id is passed over here */   
   let element = document.getElementById('excel-table'); 
 
  
   const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

   /* generate workbook and add the worksheet */
   const wb: XLSX.WorkBook = XLSX.utils.book_new();
   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

   /* save to file */
   XLSX.writeFile(wb, this.fileName);

  
  
}


}
function foreach(arg0: any) {
  throw new Error('Function not implemented.');
}

